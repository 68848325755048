export default {
  'cdsSettings.title': 'CDS setting',

  'cdsSettings.tabs.order-status-board': 'Order Status Board',
  'cdsSettings.tabs.order-status-board.language': 'Language',
  'cdsSettings.tabs.order-status-board.language.english': 'English',
  'cdsSettings.tabs.order-status-board.language.englishAndChinese': 'English+Chinese(Simplified)',
  'cdsSettings.tabs.order-status-board.language.chinese': 'Chinese(Simplified)',
  'cdsSettings.tabs.order-status-board.screenTemplates': 'Screen Templates',
  'cdsSettings.tabs.order-status-board.screenTemplates.upload': 'Upload',
  'cdsSettings.tabs.order-status-board.screenTemplates.ready': 'Ready',
  'cdsSettings.tabs.order-status-board.screenTemplates.preparing': 'Preparing',
  'cdsSettings.tabs.order-status-board.screenTemplates.preview': 'Preview',
  'cdsSettings.tabs.order-status-board.screenTemplates.tooltip':
    'Support JPG, JPEG, GIF, PNG, limited to 3MB in size, ratio 3:4',
  'cdsSettings.tabs.order-status-board.screenTemplates.tooLargeSize': 'File size needs to be 3M or smaller',
  'cdsSettings.tabs.order-status-board.screenTemplates.wrongFileType': 'File needs to be .jpeg, .gif, .png',
  'cdsSettings.tabs.order-status-board.screenTemplates.uploadImageFailed': 'Handle image failed, please re-operate',
  'cdsSettings.tabs.order-status-board.screenTemplates.templatePreview': 'Template Preview',
  'cdsSettings.tabs.order-status-board.url': 'URL',
  'cdsSettings.tabs.order-status-board.url.tips':
    'Please visit this URL on the order status board to display the ticket number.',
};
