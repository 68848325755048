export default {
  'loyaltySettings.discountRate': 'First Order Rate',
  'loyaltySettings.discountRate.RG': 'First Order Rate - RG',
  'loyaltySettings.discountRate.summary': 'The discount rate a member can enjoy on the first order at your restaurant',
  'loyaltySettings.cashBackRate': 'Cashback Rate',
  'loyaltySettings.cashBackRate.RG': 'Cashback Rate - RG',
  'loyaltySettings.cashBackRate.summary': 'The cashback rate a member can earn from their purchase at your restaurant',
  'loyaltySettings.memberStatus': 'Member Status',
  'loyaltySettings.memberStatus.new': 'New',
  'loyaltySettings.memberStatus.new.summary': 'Members who registered within',
  'loyaltySettings.memberStatus.regular': 'Regular',
  'loyaltySettings.memberStatus.inactive': 'Inactive',
  'loyaltySettings.memberStatus.regular.summary': 'Members who have ordered from your restaurant within',
  'loyaltySettings.memberStatus.inactive.summary':
    'Members who haven’t ordered from your restaurant within this period of time will be defined as ',
  'loyaltySettings.memberRegistrationQRCode': 'Member Registration QR Code',

  'loyaltySettings.poster.cashbackSummary': '{percent}% Cash Back On Every Order',

  'loyaltySettings.prepaidCard': 'Prepaid Card',
  'loyaltySettings.prepaidCard.RG': 'Prepaid Card - RG',
  'loyaltySettings.prepaidCard.addNewPurchaseOption': 'Add Purchase Options',
  'loyaltySettings.prepaidCard.removePurchaseOption': 'Remove',
  'loyaltySettings.prepaidCard.purchaseOption.bonusAmount': 'Bonus Amount',
  'loyaltySettings.prepaidCard.purchaseOption.purchaseAmount': 'Purchase Amount',
  'loyaltySettings.prepaidCard.purchaseOption.purchaseAmount.isRequired': 'Purchase Amount is Required',
  'loyaltySettings.prepaidCard.purchaseOption.bonusAmount.isRequired': 'Bonus Amount is Required',
  'loyaltySettings.prepaidCard.successfullyRemoved': 'Successfully deleted purchase option!',
  'loyaltySettings.prepaidCard.successfullyCreated': 'Successfully created purchase option!',
  'loyaltySettings.prepaidCard.successfullyUpdated': 'Successfully updated purchase option!',
  'loyaltySettings.prepaidCard.successfullyToggleOn': 'Turn on Prepaid Card!',
  'loyaltySettings.prepaidCard.successfullyToggleOff': 'Turn off Prepaid Card!',
  'loyaltySettings.prepaidCard.duplicatedPurchaseOption': 'Duplicated Purchase Options',
  'loyaltySettings.prepaidCard.setupTips': 'purchase options (10 maximum)',
  'loyaltySettings.prepaidCard.payment.required.verifyCode.titls': 'Require verification code',
  'loyaltySettings.prepaidCard.payment.required.verifyCode.option.all': 'On all loyalty credit payment',
  'loyaltySettings.prepaidCard.payment.required.verifyCode.option.exceed': 'If payment exceeds',
  'loyaltySettings.prepaidCard.recharge.restriction': 'recharge restriction',
  'loyaltySettings.prepaidCard.recharge.restriction.immediately': 'Immediately available',
  'loyaltySettings.prepaidCard.recharge.restriction.afterNDay': 'Available after ',
  'loyaltySettings.prepaidCard.recharge.restriction.afterNDay.addon.after': 'days',
  'loyaltySettings.prepaidCard.recharge.restriction.afterNDay.isRequired': 'Number of days is required.',
  'loyaltySettings.prepaidCard.recharge.restriction.afterNDay.min': 'Number of days must greater than 1.',

  'loyaltySettings.prepaidCard.recharge.custom': 'Custom Recharge',
  'loyaltySettings.prepaidCard.recharge.custom.maximum.bonus.rate': 'Maximum Bonus Rate Restriction',

  'loyaltySettings.advertistingSetting': 'Advertising Setting',
  'loyaltySettings.advertistingSetting.enableRelatedMessage': 'Enable Loyalty promotion for QRCode/Tablet Ordering',
  'loyaltySettings.advertistingSetting.enableRelatedMessage.desc':
    'If this option is off,  won’t show loyalty banner on QR ordering, and won’t encourage users to login when submitting non-member price dishes.',

  'loyaltySettings.prepaidCard.payment.required.verifyCode.threshold.isRequired': 'Payment threshold is required',
  'loyaltySettings.prepaidCard.payment.required.verifyCode.threshold.min': 'Payment threshold must greater than 0.',

  'loyalty.memberstatus.new': 'New',
  'loyalty.memberstatus.regular': 'Regular',
  'loyalty.memberstatus.atrisk': 'At-risk',
  'loyalty.memberstatus.lost': 'Lost',

  'loyaltySettings.memberStatus.atrisk.summary':
    'Members who haven’t ordered from your restaurant within this period of time will be defined as Inactive',
  'loyaltySettings.memberStatus.lost.summary':
    'Members who haven’t ordered from your restaurant within this period of time will be defined as Inactive',

  'loyaltySettings.autopilot': 'Autopilot',
  'loyaltySettings.autopilot.desc':
    'Once autopilot scenario marked as paid is enabled, the sent SMS will be included in the CRM charging standard: If the total sent volume is within the subscribed CRM package, only the package fee will be charged; If the total sent volume is over the subscribed CRM package, the part exceeding package will be charged extra $0.02/segments',
  'loyaltySettings.autopilot.tips.01':
    'Once autopilot scenario marked as paid is enabled, the sent SMS will be charged.',
  'loyaltySettings.autopilot.tips.02':
    'SMS message segment is limited to 160 7-bit characters, i.e., 1120 bits, or 140 bytes. When the content length exceeds this limit, the phone carrier will divide the content into multiple message segments and charge fees per segment.',
  'loyaltySettings.autopilot.tips.03':
    'Phone carrier will not send text messages under the following circumstances: contains sensitive words, such as vape, heroin, drugs, cigarettes, etc.; the phone number has been blacklisted by the carrier.',
  'loyaltySettings.autopilot.link.prefix': `Moved to "Campaign->Autopilot" feature, please `,
  'loyaltySettings.autopilot.link.text': ` click here to go >>`,
  'loyaltySettings.advertising.link.prefix': `Advertising setting, How guests enroll, Member registration QR code have been moved to the new page, please`,
  'loyaltySettings.advertising.link.text': `click here to go >>`,
  'loyaltySettings.benefits.link.prefix': `First order rate, Cashback rate have been moved to the new page, please`,
  'loyaltySettings.benefits.link.text': `click here to go >>`,
  'loyaltySettings.advertisingBenefits.link.title': `Advertising & Benefits`,

  'loyaltySettings.autopilot.newregister': 'New Register',
  'loyaltySettings.autopilot.firstpurchase': 'First Purchase',
  'loyaltySettings.autopilot.repurchase': 'Re-purchase',
  'loyaltySettings.autopilot.atrisk': 'At-risk',
  'loyaltySettings.autopilot.lost': 'Lost',
  'loyaltySettings.autopilot.need.pay': 'Paid',
  'loyaltySettings.autopilot.toggle.confirm': `Once this autopilot SMS scenario is enabled, the sent SMS will be included in the CRM charging standard: If the total sent volume is within the subscribed CRM package, only the package fee will be charged; If the total sent volume is over the subscribed CRM package, the part exceeding package will be charged extra $0.02/segments`,

  'loyaltySettings.autopilot.newregister.desc':
    'A reward for signing up means the customer starts off with an incentive. It’s a great way to say hello!',
  'loyaltySettings.autopilot.firstpurchase.desc':
    'Over 60% of new customers don’t come back after their first visit, but an extra reminder can cut that number significantly.',
  'loyaltySettings.autopilot.repurchase.desc':
    'Repeat customers drive 80% of your revenue, but if you send them a reminder, you can increase their visit rates. ',
  'loyaltySettings.autopilot.atrisk.desc':
    'A customer’s likelihood to return drops 50-75% every 30 days. We help you engage at-risk customers before it’s too late',
  'loyaltySettings.autopilot.lost.desc': 'Recapture customers who have been inactive from six months up to a year.',

  'loyaltySettings.how.to.join.membership': 'How guests enroll',
  'loyaltySettings.how.to.join.membership.RG': 'How guests enroll - RG',
  'loyaltySettings.free.join.membership': 'free membership',
  'loyaltySettings.topup.join.membership': 'top up membership',
  'loyaltySettings.annual.join.membership': 'annual fee membership',

  'loyaltySettings.annual.join.membership.fee.isRequired': 'Annual fee is required',
  'loyaltySettings.annual.join.membership.fee.min': 'Annual fee  must greater than 0.',
  'loyaltySettings.annual.join.membership.fee.addon.after': 'per 365 days',

  'loyaltySettings.advertistingSetting.enableRelatedMessage.kiosk': 'Enable Loyalty-related messaging for kiosk',
  'loyaltySettings.advertistingSetting.enableRelatedMessage.kiosk.desc':
    'Advertising messages will be disabled on both Kiosks if this option is off',

  'loyaltySettings.advertistingSetting.enableLogin': 'Enable Loyalty sign in feature for QRCode/Tablet Ordering',
  'loyaltySettings.advertistingSetting.enableLogin.desc':
    'If this option is off, loyalty sign in, registration, and banner will be disabled on both QRCode and Tablet ordering.  This will result in points redemption not being able to log in, and there will be no login guidance when submitting member price dishes.',
  'loyaltySettings.advertistingSetting.enableRegister':
    'Enable Loyalty registration feature for QRCode/Tablet Ordering',
  'loyaltySettings.advertistingSetting.enableRegister.desc':
    'Loyalty registration will be disabled on both QRCode and Tablet ordering if this option is off.',

  //Advertising Benefits
  'loyaltySettings.advertisingBenefits.title': 'Advertising & Benefits',
  'loyaltySettings.advertisingBenefits.tabs.advertising': 'Advertising',
  'loyaltySettings.advertisingBenefits.tabs.benefits': 'Benefits',
  'loyaltySettings.advertisingBenefits.advertising.setting': 'Advertising setting',
  'loyaltySettings.advertisingBenefits.advertising.QRCodeTabletOrdering': 'QRCode/Tablet Ordering',
  'loyaltySettings.advertisingBenefits.advertising.loyaltySign': 'Loyalty Sign',
  'loyaltySettings.advertisingBenefits.advertising.loyaltyRegistration': 'Loyalty Registration',
  'loyaltySettings.advertisingBenefits.advertising.loyaltyPromotion': 'Loyalty Promotion',
  'loyaltySettings.advertisingBenefits.advertising.kiosk': 'Kiosk',
  'loyaltySettings.advertisingBenefits.advertising.loyaltyRelatedMessaging': 'Loyalty-related Messaging',
  'loyaltySettings.advertisingBenefits.advertising.memberRegistrationQR': 'Member Registration QR Code',
  'loyaltySettings.advertisingBenefits.advertising.QRRegistration': 'Loyalty Registration',
  'loyaltySettings.advertisingBenefits.advertising.QRRegistration.desc':
    'After closure, new member registration via membership QR code is no longer supported.',
  'loyaltySettings.advertisingBenefits.benefits.membershipSettings': 'Membership Settings',
  'loyaltySettings.advertisingBenefits.benefits.guests': 'How Guests Enroll',
  'loyaltySettings.advertisingBenefits.benefits.free': 'Free Membership',
  'loyaltySettings.advertisingBenefits.benefits.permanently': 'Valid permanently',
  'loyaltySettings.advertisingBenefits.benefits.356days': 'Valid for 365 days',
  'loyaltySettings.advertisingBenefits.benefits.period': 'Validity period',
  'loyaltySettings.advertisingBenefits.benefits.period.desc':
    'Modifying the validity period only affects new members, and historical members will not be modified.',
  'loyaltySettings.advertisingBenefits.benefits.period.days': 'days',
  'loyaltySettings.advertisingBenefits.benefits.topUp': 'Top-up Membership',
  'loyaltySettings.advertisingBenefits.benefits.annualFee': 'Annual Fee Membership',
  'loyaltySettings.advertisingBenefits.benefits.QRCode': 'Member Registration QR Code',

  'loyaltySettings.advertisingBenefits.benefits.setting': 'Benefits setting',
  'loyaltySettings.advertisingBenefits.benefits.fistOrderRate': 'First Order Rate',
  'loyaltySettings.advertisingBenefits.benefits.fistOrderRate.desc':
    'The discount rate a member can enjoy on the first order at your restaurant',
  'loyaltySettings.advertisingBenefits.benefits.cashback': 'Cashback Rate',
  'loyaltySettings.advertisingBenefits.benefits.cashback.desc':
    'The cashback rate a member can earn from their purchase at your restaurant',
};
