export default {
  'roleDetail.title': 'Role Detail',
  'roleEdit.createRole.title': 'New Role',
  'roleEdit.editRole.title': 'Edit Role',
  'roleEdit.saveRole': 'Save Role',
  'roleEdit.section.generalInformation': 'General Information',
  'roleEdit.section.accessPermissions': 'Access Permissions',
  'roleEdit.section.datePermissions': 'Data Permissions',
  'roleEdit.section.members': 'Employees',
  'roleEdit.field.name': 'Role Name',
  'roleEdit.field.foreign_name': 'Role Foreign Name',
  'roleEdit.field.permission': 'Permission',
  'roleEdit.field.dataPermission': 'Data Permission',
  'roleEdit.field.is_system': 'Is System Role?',
  'roleEdit.field.enforeClockInBeforeAccess': 'Must clock in before accessing POS App',
  'roleEdit.field.enforeCloseAllOrdersClockOut': 'Must close all orders before clocking out',
  'roleEdit.field.enableRoleRetainCash': 'Calculate "House Cash".',
  'roleEdit.field.deductCreditCardTips': 'Card Tips Withholding',
  'roleEdit.field.deductNetSales': 'In-House net sales Withholding',
  'roleEdit.cardTips': 'card tips',
  'roleEdit.netSales': 'In-House net sales',
  'roleEdit.deliveryTipsAreDefaultedToDrivers':
    'Delivery Fees Paid to Drivers as Compensation (Delivery tips are defaulted to drivers)',
  'roleEdit.field.enableRoleRetainCash.description': `
  Turn on this switch, and the "House Cash" field in the Closeout Report will automatically calculate the Cash Owed to the House for you, making it easier to settle cash with employees.
  `,
  'roleEdit.field.enableRoleRetainCash.isDriver.description': `
  Turn on this switch, and the "House Cash" field in the Driver Closeout Report will automatically calculate the Cash Owed to the House for you, making it easier to settle cash with drivers.
  `,
  'roleEdit.houseCash.base': `Tip: Cash Owed To House = Cash Total In Hand - Service Tips`,
  'roleEdit.houseCash.withholdCardTips': 'Card Tips Withholding',
  'roleEdit.houseCash.withholdDineInNetSales': 'In-House net sales Withholding',
  'roleEdit.houseCash.isDriver.base': 'Tip: Cash Owed To House = Cash Total In Hand',
  'roleEdit.houseCash.isDriver.deliceryTips': 'Delivery Tips',
  'roleEdit.houseCash.isDriver.deliceryTipsAnddeliceryFee': '（Delivery Tips + Delivery Fee）',
  'roleEdit.name.errors.empty': 'Please input role name',
  'roleEdit.foreign_name.errors.empty': 'Please Input Role Foreign Name',
  'roleEdit.permission.errors.empty': 'Please select permissions',
  'roleEdit.is_system.errors.empty': 'Please figure out if The role Is system level',
  'roleEdit.create_success': 'Successfully created role',
  'roleEdit.update_success': 'Successfully updated role',
  'roleEdit.table_service.restrict':
    '(This category is only applicable when the restaurant type is opted to “table service“)',
  'roleEdit.search.placeholder': 'Search by name or phone number',
  'roleEdit.allOrders.desc':
    'Without this permission, individuals can only see their own Closed and Authorized orders from the past 24 hours.',
  'roleEdit.manuallyEnterWeight.desc':
    'With this permission, individuals can click on Manually Enter Weight when scale is connected.',
  'roleEdit.driver': 'Full-Time Driver（Delivery Only, No Order Taking）',
  'roleEdit.house_cash.including_delivery': `"House Cash" calculation including delivery orders created by the employee. `,
  'roleEdit.house_cash.tips_include_delivery_tips': `When enabled, "Cash In Hand" includes delivery orders, and tips owed to the employee also include "Delivery tips".`,
  'roleEdit.house_cash.calculation_delivery_fee': `Deduct the delivery fee from the House Cash calculation. `,
  'roleEdit.houseCash.delicery_tips': 'Delivery Tips',
  'roleEdit.houseCash.delicery_fee': 'Delivery Fee',

  'roleEdit.tipOut.title': 'Set a tip out',
  'roleEdit.tipOut.title.desc':
    'Turn on this switch, and  add an "Employee Tip Account" section in the Closeout Report to help calculate the tips owed to employees after the tip out.',
  'roleEdit.tipOut.tipOut': 'Tip out:',
  'roleEdit.tipOut.netSales.desc': 'of net sales (in-house)',
  'roleEdit.tipOut.serviceTips.desc': 'of service tips',
  'roleEdit.tipOut.rule': 'Tip out cannot be empty or zero',
  'roleEdit.tipOut.sections.rule': 'At least one tip out should not be empty or zero',
  'roleEdit.tipOut.netSalesOfSections.desc': 'net sales of sections (in-house)',
  'roleEdit.tipOut.netSalesOfSections.noSections': "you haven't set up any section",
  'roleEdit.tipOut.netSalesOfSections.netSales': 'of net sales',
  'roleEdit.tipOut.wain': 'House cash and Tip Out cannot be enabled at the same time for now',
  'roleEdit.houseCash.withholding': 'Withholding is restricted to certain menu items only.',
  'roleEdit.houseCash.add_restricted': 'Add restricted items',
  'roleEdit.houseCash.add_restricted.title': 'Items',

  'roleEdit.houseCash.add_restricted.table_title_items': 'restricted items',
  'roleEdit.houseCash.add_restricted.table_title_quantity': 'quantity',
  'roleEdit.houseCash.add_restricted.table_title_action': 'Action',
};
