export default {
  'promotionManagement.title': 'Promotions Management',
  'promotionManagement.section.quickAccess': 'Quick Access',
  'promotionManagement.section.allPromotions': 'All Promotions',
  'promotionManagement.edit.title': 'Edit Promotion',
  'promotionManagement.create.title': 'Create Promotion',
  'promotionManagement.duplicate.title': 'Duplicate Promotion',
  'promotionManagement.section.generalInformation': 'General Information',
  'promotionManagement.section.usageRules': 'Usage Rules',
  'promotionManagement.section.conditions': 'Conditions',
  'promotionManagement.section.schedulingAndDuration': 'Scheduling & Duration',
  'promotionManagement.column.number': 'ID Number',
  'promotionManagement.column.type': 'Promotion Type',
  'promotionManagement.column.available_count': 'Available',
  'promotionManagement.column.redeemed_count': 'Redeemed',
  'promotionManagement.column.orders_total': 'Orders Total ($)',
  'promotionManagement.column.orders_count': 'Orders Count',
  'promotionManagement.column.promotions_total': 'Promotion Total ($)',
  'promotionManagement.column.promotion_display_status': 'Status',
  'promotionManagement.column.action': 'Action',
  'promotionManagement.column.created_at': 'Created',
  'promotionManagement.column.updated_at': 'Last Updated',
  'promotionManagement.column.name': 'Promotion Name',
  'promotionManagement.column.foreign_name': 'Foreign Promotion Name',
  'promotionManagement.column.display_name': 'Display Name',
  'promotionManagement.column.display_name.tooltip':
    'Displayed on bills and receipts, as well as kiosk, QR code, and tablets',
  'promotionManagement.column.display_foreign_name': 'Foreign Display Name',
  'promotionManagement.column.description': 'Description',
  'promotionManagement.column.description.tooltip': 'Displayed on kiosk, QR code, and tablets',
  'promotionManagement.column.quantity': 'Total Quantity',
  'promotionManagement.column.quantity.description': 'Total number of promotions available for use',
  'promotionManagement.column.unlimited': 'Unlimited',
  'promotionManagement.column.limit_per_order': 'Limit per Order',
  'promotionManagement.column.limit_per_order.description':
    'Limit how many times 1 promotion can be applied to 1 order.',
  'promotionManagement.column.service_types': 'Service Type(s)',
  'promotionManagement.column.order_sources': 'Order Source(s)',
  'promotionManagement.column.customer_groups': 'Customer Group(s)',
  'promotionManagement.column.customer_groups.description':
    'Loyalty member-only promotions are limited to POS App currently.',
  'promotionManagement.column.promotion_type': 'Promotion Type',
  'promotionManagement.column.purchase_item_quantity': 'Purchase Quantity',
  'promotionManagement.column.offer_item_quantity': 'Offer Quantity',
  'promotionManagement.column.max_offer_quantity': 'Maximum Offer Quantity',
  'promotionManagement.column.miminum_amount': 'Minimum Amount',
  'promotionManagement.column.amount_off': 'Amount Off',
  'promotionManagement.column.items_excluded_from_discount': 'Items excluded from discount',
  'promotionManagement.column.items_excluded_from_discount.description':
    'Items excluded from discount in Menu Management remain valid here, but are not displayed blow',
  'promotionManagement.seleceted.desc': '({count} items selected)',
  'promotionManagement.column.eligible_items_for_promotion': 'Eligible items for promotion',
  'promotionManagement.eligibleItemsForDiscount': 'Eligible Items for discount',
  'promotionManagement.column.eligible_purchase_items': 'Eligible purchase items',
  'promotionManagement.column.eligible_offer_items': 'Eligible offer items',
  'promotionManagement.column.eligible_offer_items.description': 'Discount is applied to the cheapest item',
  'promotionManagement.eligibleOfferItemsIdenticalAsEligiblePurchaseItems':
    'List of eligible purchase & offer items are the same',
  'promotionManagement.canOnlyBuyIdenticalPurchaseAndOfferItems': 'Diner can only buy identical purchase & offer items',
  'promotionManagement.column.start_date': 'Start Date',
  'promotionManagement.column.end_date': 'End Date',
  'promotionManagement.column.days_of_week': 'Days of Week',
  'promotionManagement.column.start_time': 'Start Time',
  'promotionManagement.column.end_time': 'End Time',
  'promotionManagement.rule.required': 'Required field to publish',
  'promotionManagement.amount_off.required': 'Must select $ off or % off & fill in amount',
  'promotionManagement.customer_groups.all': 'All',
  'promotionManagement.customer_groups.loyalty_members': 'Loyalty Members',
  'promotionManagement.customer_groups.non_members': 'Non-Members',
  'promotionManagement.items.required': 'Select at least one item from any menu.',
  'promotionManagement.action.saveAsDraft': 'Save as Draft',
  'promotionManagement.action.publish': 'Publish',
  'promotionManagement.items': 'Items ( {count} / {total} )',
  'promotionManagement.openPriceItemsExcluded': 'Open price items are excluded',
  'promotionManagement.pointedItemsExcludes': 'Pointed items are excluded',
  'promotionManagement.excludedFromDiscount': 'Already excluded via Menu',
  'promotionManagement.autoPauseDesc':
    'The promotional products have been sold out. Increase the activity limit, publish again',

  'promotionManagement.searchByName': 'Promotion name',
  'promotionManagement.error.start_date': 'Start date should be before end date',
  'promotionManagement.error.end_date': 'End date should be after start date',
  'promotionManagement.error.start_time': 'Start time should be before end time',
  'promotionManagement.error.end_time': 'End time should be after start time',
  'promotionManagement.orderSourcesForEligibleItems':
    'Fill out Order Source(s) in order to select eligible promotion items.',
  'promotionManagement.orderSourcesForExcludedItems':
    'Fill out Order Source(s) in order to select items to exclude from promotion.',
  'promotionManagement.saveAsDraftSuccess': 'Successfully saved promotion. Do not forget to publish!',
  'promotionManagement.publishedSuccess': 'Successfully published promotion',
  'promotionManagement.deletePromotion': `
    Are you sure you want to delete “{promotionName}”?\nThis promotion will be deleted immediately. You cannot undo this action.
  `,
  'promotionManagement.displayPromotionsAsTheFirstCategory': 'Create and display Promotions as the first category',
  'promotionManagement.displayPromotionsAsTheFirstCategory.description': `
      <p>
        System will automatically create a category that is participating in dish-level promotions
      </p>
      <p style="margin-bottom: 0;">
        Displayed on kiosk, QR, and tablets
      </p>
    `,
  'promotionManagement.failedToLoadDishes': 'Failed to load dishes',
  'promotionManagement.singlePromotionAtTheSameTime':
    'Please do not set up multiple promotions within the same time period.',
  'promotionManagement.cannotCreatePromotion.title': 'Can not create new promotion',
  'promotionManagement.existConflicts.title': 'There are conflicts in the time period',
};
