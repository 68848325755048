export default {
  'request.connectErrorMessage': 'Connect Error',
  'request.connectErrorDescription': 'Can Not Connect Server',
  'request.error': 'Request Error',
  'request.status.200': 'Successfully Return',
  'request.status.201': 'Create Or Update Successfully',
  'request.status.202': 'You Request has been enter in queue',
  'request.status.204': 'The data was deleted successfully.',
  'request.status.400':
    'There is an error in the request sent, the server did not perform the operation of creating or modifying data',
  'request.status.401': 'The user does not have permission (token, username, password is wrong).',
  'request.status.403': 'The user is authorized, but access is prohibited.',
  'request.status.404': 'The request issued was for a non-existent record, the server did not operate.',
  'request.status.406': 'The requested format is not available.',
  'request.status.410': 'The requested resource is permanently deleted and will no longer be available.',
  'request.status.422': 'When creating an object, a validation error occurred.',
  'request.status.500': 'Server error, please check the server.',
  'request.status.502': 'Gateway error.',
  'request.status.503': 'Service unavailable, server temporarily overloaded or maintained.',
  'request.status.504': 'Gateway timeout.',
};
