export default {
  'filter.field.role': 'Role',
  'filter.field.department': 'Department',
  'filter.field.employee': 'Employee',
  'filter.field.order_source': 'Order Source',
  'filter.field.service_type': 'Service Type',
  'filter.field.view_dimension': 'View Dimension',

  'filter.placeholder.role': 'Filter by Roles',
  'filter.placeholder.departments': 'Filter by Departments',
  'filter.placeholder.employee': 'Filter by Employees',
  'filter.placeholder.order_source': 'Filter by Order Source(s)',
  'filter.placeholder.service_type': 'Filter by Service Type',
};
