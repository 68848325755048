export default {
  'team.title': 'Team Management',
  'team.role': 'Roles',
  'team.member': 'Employees',
  'team.addRole': 'Add Role',
  'team.saveRole': 'Save Role',
  'team.removeStaff': 'Remove Employee',
  'team.addStaff': 'Add Employee',
  'team.addDepartment': 'Add Department',
  'team.roles.numberOfMembers': 'Number Of Members',
  'team.deleteRole.promptContent': 'Sure to delete the role ？',
  'team.deleteRole.success': 'Successfully deleted role',
  'team.deleteMembers.success': 'Successfully deleted member',
  'team.deleteMember.promptContent': 'Sure to delete these selected members ?',
  'team.deleteSingleMember.promptContent': 'Sure to delete this member ?',
  'team.activateMember.promptTitle': 'Activate Member',
  'team.activateMember.promptContent': 'Sure to activate this member ?',
  'team.deactivateMember.promptContent': 'Sure to deactivate This member ?',
  'team.owner.deletionDeclined': 'Restaurant owner cannot be deleted',
  'team.owner.deactivateDeclined': 'Restaurant owner cannot be deactivated',
  'team.addMember.success': 'Successfully added member',
  'team.editMember.success': 'Successfully updated member',
  'team.cannotCreateRoleBeforeActivate': 'Cannot create role before restaurant is activated',
  'team.cannotCreateEmployeeBeforeActivate': 'Cannot create employee before restaurant is activated',
};
