export default {
  'reservationSettings.title': 'Reservation',
  'reservationSettings.section.enable.google.reservation': 'Google reservation',
  'reservationSettings.section.enable.google.reservation.desc': 'Will take effect from 5am to 7am the next day ',
  'reservationSettings.section.capacity': 'Capacity for online reservation',
  'reservationSettings.section.capacity.sub': 'Capacity',
  'reservationSettings.section.capacity.max': 'Max',
  'reservationSettings.section.capacity.min': 'Min',
  'reservationSettings.section.interval': 'Intervals',
  'reservationSettings.section.interval.sub': 'Intervals',
  'reservationSettings.section.interval.unit': 'min',
  'reservationSettings.section.capacity.min.required': 'Capacity(min) is required',
  'reservationSettings.section.capacity.max.required': 'Capacity(max) is required',
  'reservationSettings.section.capacity.range.error': `Capacity(min) can't more than Capacity(max)`,
  'reservationSettings.section.capacity.max.range.error': `Capacity(max) can't more than 20`,
  'reservationSettings.section.capacity.interval.required': `Intervals is required`,
  'reservationSettings.section.capacity.interval.range.error': `Intervals must be greater than or equal {minInterval} min`,

  'reservationSettings.section.tableSetting': 'Table Setting',
  'reservationSettings.section.tableSetting.addNew.button': 'Add New',
  'reservationSettings.section.tableSetting.edit.button': 'Edit',
  'reservationSettings.section.tableSetting.delete.button': 'Delete',
  'reservationSettings.section.tableSetting.table.column.tableName.cn': 'Table Name(cn)',
  'reservationSettings.section.tableSetting.table.column.tableName.en': 'Table Name(en)',
  'reservationSettings.section.tableSetting.table.column.seatingCapacity': 'Seating capacity',
  'reservationSettings.section.tableSetting.table.column.duration': 'Duration(mins)',
  'reservationSettings.section.tableSetting.table.column.action': 'Action',

  'reservationSettings.section.tableSetting.modal.new.title': 'New table',
  'reservationSettings.section.tableSetting.modal.tableName': 'Table Name',
  'reservationSettings.section.tableSetting.modal.tableName.cn': 'cn',
  'reservationSettings.section.tableSetting.modal.tableName.en': 'en',
  'reservationSettings.section.tableSetting.modal.tableName.cn.duplicated.error': `Table Foreign name is duplicated`,
  'reservationSettings.section.tableSetting.modal.tableName.en.duplicated.error': `Table name is duplicated`,
  'reservationSettings.section.tableSetting.modal.seatingCapacity': 'Seating capacity',
  'reservationSettings.section.tableSetting.modal.seatingCapacity.min': 'Min',
  'reservationSettings.section.tableSetting.modal.seatingCapacity.max': 'Max',
  'reservationSettings.section.tableSetting.modal.seatingCapacity.min.required': 'Seating capacity(min) is required',
  'reservationSettings.section.tableSetting.modal.seatingCapacity.max.required': 'Seating capacity(max) is required',
  'reservationSettings.section.tableSetting.modal.tableName.cn.required': 'Table foreign name  is required',
  'reservationSettings.section.tableSetting.modal.tableName.en.required': 'Table name is required',
  'reservationSettings.section.tableSetting.modal.seatingCapacity.range.error': `Seating capacity(min)  can't more than Seating capacity(max)`,
  'reservationSettings.section.tableSetting.modal.seatingCapacity.overlap.error': `Seating capacity is conflicted with others`,

  'reservationSettings.section.tableSetting.modal.Duration': 'Duration',
  'reservationSettings.section.tableSetting.modal.Duration.unit': 'mins',
  'reservationSettings.section.tableSetting.modal.Duration.required': 'Duration is required',

  'reservationSettings.section.tableSetting.modal.edit.title': 'Edit table',
  'reservationSettings.section.tableSetting.delete.title': 'Would you like to DELETE table setting?',

  'reservationSettings.section.tableSetting.delete.comfirm': 'Yes, delete',
  'reservationSettings.section.tableSetting.delete.cancel': 'Cancel',

  'reservationSettings.section.tableSetting.delete.success': 'Delete table setting Successfully',
  'reservationSettings.section.tableSetting.delete.failed': 'Delete table setting Failed',

  'reservationSettings.section.tableSetting.add.success': 'Add table setting Successfully',
  'reservationSettings.section.tableSetting.add.failed': 'Add table setting Failed',

  'reservationSettings.section.tableSetting.edit.success': 'Update table setting Successfully',
  'reservationSettings.section.tableSetting.edit.failed': 'Update table setting Failed',

  'reservationSettings.section.openTimeRegular': 'Open time (Regular)',
  'reservationSettings.section.openTimeRegular.table.column.weeks': 'Date',
  'reservationSettings.section.openTimeRegular.table.column.time': 'Time',
  'reservationSettings.section.openTimeRegular.table.column.tableSource': 'Table type & numbers',
  'reservationSettings.section.openTimeRegular.table.column.action': 'Action',
  'reservationSettings.section.openTimeRegular.addNew.button': 'Add New',
  'reservationSettings.section.openTimeRegular.edit.button': 'Edit',
  'reservationSettings.section.openTimeRegular.delete.button': 'Delete',

  'reservationSettings.section.openTimeRegular.modal.new.title': 'New open time',
  'reservationSettings.section.openTimeRegular.modal.edit.title': 'Edit open time',
  'reservationSettings.section.openTimeRegular.modal.time': 'Time',
  'reservationSettings.section.openTimeRegular.modal.time.error': 'please set time range for open time',
  'reservationSettings.section.openTimeRegular.modal.weeks.error': 'please select week day for open time',
  'reservationSettings.section.openTimeRegular.modal.tables.error': 'please select table for open time',
  'reservationSettings.section.openTimeRegular.modal.overlap.error': 'open time is conflicted with others',

  'reservationSettings.section.openTimeRegular.delete.title': 'Would you like to DELETE open time setting?',

  'reservationSettings.section.openTimeRegular.delete.comfirm': 'Yes, delete',
  'reservationSettings.section.openTimeRegular.delete.cancel': 'Cancel',

  'reservationSettings.section.openTimeRegular.delete.success': 'Delete open time Successfully',
  'reservationSettings.section.openTimeRegular.delete.failed': 'Delete open time Failed',

  'reservationSettings.section.openTimeRegular.add.success': 'Add open time Successfully',
  'reservationSettings.section.openTimeRegular.add.failed': 'Add open time Failed',

  'reservationSettings.section.openTimeRegular.edit.success': 'Update open time Successfully',
  'reservationSettings.section.openTimeRegular.edit.failed': 'Update open time Failed',

  'reservationSettings.section.openTimeSpecial': 'Open time (Special)',
  'reservationSettings.section.openTimeSpecial.table.column.date': 'Date',
  'reservationSettings.section.openTimeSpecial.table.column.time': 'Time',
  'reservationSettings.section.openTimeSpecial.table.column.tableSource': 'Table type & numbers',
  'reservationSettings.section.openTimeSpecial.table.column.action': 'Action',
  'reservationSettings.section.openTimeSpecial.addNew.button': 'Add New',
  'reservationSettings.section.openTimeSpecial.edit.button': 'Edit',
  'reservationSettings.section.openTimeSpecial.delete.button': 'Delete',

  'reservationSettings.section.openTimeSpecial.delete.title': 'Would you like to DELETE open time setting?',
  'reservationSettings.section.openTimeSpecial.delete.comfirm': 'Yes, delete',
  'reservationSettings.section.openTimeSpecial.delete.cancel': 'Cancel',
  'reservationSettings.section.openTimeSpecial.delete.success': 'Delete open time Successfully',
  'reservationSettings.section.openTimeSpecial.delete.failed': 'Delete open time Failed',

  'reservationSettings.section.openTimeSpecial.add.success': 'Add open time Successfully',
  'reservationSettings.section.openTimeSpecial.add.failed': 'Add open time Failed',

  'reservationSettings.section.openTimeSpecial.edit.success': 'Update open time Successfully',
  'reservationSettings.section.openTimeSpecial.edit.failed': 'Update open time Failed',

  'reservationSettings.section.openTimeSpecial.modal.new.title': 'New special open time',
  'reservationSettings.section.openTimeSpecial.modal.edit.title': 'Edit special open time',
  'reservationSettings.section.openTimeSpecial.modal.date': 'Date',
  'reservationSettings.section.openTimeSpecial.modal.time': 'Time',
  'reservationSettings.section.openTimeSpecial.modal.time.error': 'please set time range for open time',
  'reservationSettings.section.openTimeSpecial.modal.date.error': 'please select spec date for open time',
  'reservationSettings.section.openTimeSpecial.modal.tables.error': 'please select table for open time',

  'reservationSettings.section.openTimeSpecial.modal.overlap.error': 'special open time is conflicted with others',

  'reservationSettings.section.closeTime': 'Close time',
  'reservationSettings.section.closeTime.table.column.startDatetime': 'Start',
  'reservationSettings.section.closeTime.table.column.endDateTime': 'End',
  'reservationSettings.section.closeTime.table.column.action': 'Action',
  'reservationSettings.section.closeTime.addNew.button': 'Add New',
  'reservationSettings.section.closeTime.edit.button': 'Edit',
  'reservationSettings.section.closeTime.delete.button': 'Delete',

  'reservationSettings.section.closeTime.delete.title': 'Would you like to DELETE close time setting?',
  'reservationSettings.section.closeTime.delete.comfirm': 'Yes, delete',
  'reservationSettings.section.closeTime.delete.cancel': 'Cancel',
  'reservationSettings.section.closeTime.delete.success': 'Delete close time Successfully',
  'reservationSettings.section.closeTime.delete.failed': 'Delete close time Failed',

  'reservationSettings.section.closeTime.add.success': 'Create new close time Successfully',
  'reservationSettings.section.closeTime.add.failed': 'Create new close time Failed',

  'reservationSettings.section.closeTime.edit.success': 'Update close time Successfully',
  'reservationSettings.section.closeTime.edit.failed': 'Update close time Failed',

  'reservationSettings.section.closeTime.modal.new.title': 'New close time',
  'reservationSettings.section.closeTime.modal.edit.title': 'Edit close time',
  'reservationSettings.section.closeTime.modal.date': 'Date/time',
  'reservationSettings.section.closeTime.modal.date.error': 'please select spec close date/time for rerservation',
  'reservationSettings.section.closeTime.modal.date.overlap': 'The close time is conflicted with others',

  'reservationSettings.section.leadtime': 'Lead Time',
  'reservationSettings.section.leadtime.time': 'Minimum time in advance',
  'reservationSettings.section.leadtime.time.days': 'days',
  'reservationSettings.section.leadtime.time.hours': 'Hr',
  'reservationSettings.section.leadtime.time.minutes': 'min',
  'reservationSettings.section.leadtime.day': 'Days available',
  'reservationSettings.section.leadtime.day.tips': 'Only take effects in Web Reservation & Google Reservation',
  'reservationSettings.section.leadtime.day.days': 'days',
  'reservationSettings.section.leadtime.time.required': 'please entry advanced reservation time',
  'reservationSettings.section.leadtime.day.required': 'please entry available days in advance',
  'reservationSettings.section.leadtime.day.time.error': 'available days must be more than advanced reservation time',
  'reservationSettings.section.leadtime.time.day.error': 'advanced reservation time must be less than available days',

  'reservationSettings.section.notifytime': 'Auto notify time',
  'reservationSettings.section.notifytime.time': 'Reservation Reminder in advance',
  'reservationSettings.section.notifytime.time.days': 'days',
  'reservationSettings.section.notifytime.time.hours': 'Hr',
  'reservationSettings.section.notifytime.time.minutes': 'min',

  'reservationSettings.section.notifytime.time.required': 'please entry auto notify time',

  'reservationSettings.section.cancelNotify': 'Restaurant cancel notification SMS',

  'reservationSettings.section.cancelNotify.field.note': 'Notes',
  'reservationSettings.section.cancelNotify.field.note.foreign': 'Notes - foreign',
  'reservationSettings.section.cancelNotify.field.note.foreign.limit': 'Up to 65 characters',
  'reservationSettings.section.cancelNotify.field.note.limit': 'Up to 150 characters',
  'reservationSettings.section.cancelNotify.field.note.placeholder': 'Please entry the cancel notification',
  'reservationSettings.section.cancelNotify.field.note.foreign.placeholder':
    'Please entry the foreign cancel notification',

  'reservationSettings.section.notes': 'Reservation Notes',

  'reservationSettings.section.notes.field.note': 'Notes',
  'reservationSettings.section.notes.field.note.foreign': 'Notes - foreign',
  'reservationSettings.section.notes.field.note.placeholder': 'Please entry the reservation notes',
  'reservationSettings.section.notes.field.note.foreign.placeholder': 'Please entry the foreign reservation notes',

  'reservationSettings.section.enable.autosendNotification': 'Auto send notify ',
  'reservationSettings.section.enable.autosendNotification.desc': `Auto send text message if it's on and in advance time is touched`,

  'reservationSettings.section.showEstimateDiningTime': 'Show est meal time',
  'reservationSettings.section.enable.showEstimateDiningTime': 'Show est meal time',
  'reservationSettings.section.enable.showEstimateDiningTime.desc': `The est meal time will be displayed on the REZ,& seated costumer card, and tables on the floor plan`,

  'reservationSettings.section.enable.google.reservation.error':
    'Please set the table type, regular open time, and capacity that allows online reservation',
  'reservationSettings.section.enable.google.reservation.error.igot.button': 'Got it',
};
