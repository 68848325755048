export default {
  'member.column.first_name': 'First Name',
  'member.column.last_name': 'Last Name',
  'member.column.role': 'Role',
  'member.column.pin': 'Device Pin',
  'member.column.email': 'Email',
  'member.column.phone_number': 'Phone',
  'member.column.created_at': 'Join Date',
  'member.column.action': 'Action',
  'member.column.status': 'Status',
  'member.column.password': 'Password',
  'member.column.birthday': 'Birthday(Optional)',
  'member.column.role_id': 'Role',
  'member.column.department_id': 'Department',
  'member.column.hourly_rate': 'Hourly Rate',
  'member.column.login_method': 'Login Method',
  'member.column.driver': 'Driver',

  'member.add.title': 'ADD EMPLOYEE',
  'member.edit.title': 'EDIT EMPLOYEE',
  'member.section.basicInfo': 'BASIC INFO',
  'member.section.workInfo': 'WORK INFO',
  'member.section.note': 'NOTE',
  'member.note.empty': 'Nothing left, write something',
  'member.hourly_rate.desc': '{hourly_rate}/hour',

  'member.created_at.desc': 'Joined {created_at}',
  'member.activate.title': 'Activate',

  'memberEdit.pin.error.lengthNotMatch': 'Pin must be exactly 4 numbers',
  'memberEdit.email.error.invalid': 'Email is invalid',
  'memberEdit.password.error.empty': 'Password is required',
  'memberEdit.password.error.notMatch':
    'Password must have at least 8 Characters (at least 1 lowercase letter, at least 1 uppercase letter, at least 1 digital)',
  'memberEdit.detectingCamera': 'Detecting camera...',
  'memberEdit.noDetectCamera': 'No camera found',
  'memberEdit.cameraPermissionDenied': 'You have not allowed this website to use your camera.',
  'memberEdit.invalidQRCode': 'QR code is invalid(limitation: only digital characters, at most 30 characters)',

  'member.loginMethod.device_pin': 'Device Pin',
  'member.loginMethod.scan_pin': 'Scan QR',
  'member.assignOwnerPrompt': 'Sure to assign this employee‘s role to “Restaurant Owner”?',
};
