export default {
  'devices.title': 'Devices',
  'devices.addDevice': 'Add Device',
  'devices.column.name': 'Name',
  'devices.column.modelName': 'Device Model',
  'devices.column.serialNumber': 'Serial Number',
  'devices.column.macAddress': 'MAC Address',
  'devices.column.type': 'Type',
  'devices.column.status': 'Status',
  'devices.column.lastHeartBeatTime': 'Last HeartBeat Time',
  'devices.column.operation': 'Action',
  'devices.column.appVersion': 'App Version',

  'devices.column.name.required': 'Name is Required',
  'devices.column.model_name.required': 'Device Alias is Required',
  'devices.column.serial_number.required': 'SerialNumber is Required',
  'devices.column.type.required': 'Type is Required',

  'devices.addDevice.title': 'Add Device',
  'devices.printerSetting': 'Printer Setting',
  'devices.editDevice.title': 'Edit Device',
  'devices.deleteDevice.promptTitle': 'Device Deletion Prompt',
  'devices.deleteDevice.promptContent': 'Are You Sure to Delete This Device ?',
  'devices.isDeleting': 'Deleting',

  'devices.activateDevice.promptTitle': 'Device Activation Prompt',
  'devices.activateDevice.promptContent': 'Are You Sure to Activate This Deivce ?',
  'devices.deactivateDevice.promptTitle': 'Device Deactivation Prompt',
  'devices.deactivateDevice.promptContent': 'Are You Sure to Deactivate This Deivce ?',
  'devices.forbiddenDeletion':
    'This device was set as the main terminal for delay send or automatically confirm. Please change the terminal before deleting the device.',
};
