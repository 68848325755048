export default {
  'dynamicReateFeeReport.column.card_brand': 'Type',
  'dynamicReateFeeReport.column.rate': 'Rate',
  'dynamicReateFeeReport.column.payment_amount': 'Payment Amount',
  'dynamicReateFeeReport.column.payment_count': 'Payment Count',
  'dynamicReateFeeReport.column.refund_amount': 'Refund Amount',
  'dynamicReateFeeReport.column.refund_count': 'Refund Count',
  'dynamicReateFeeReport.column.fees': 'Fees',
  'dynamicReateFeeReport.column.total': 'Total',
};
