export default {
  'giftCardReport.title': 'Gift Cards',
  'giftCardReport.tabs.restaurantGroup': 'RestaurantGroup',
  'giftCardReport.tabs.restaurant': 'Restaurant',
  'giftCardReport.subTitle': 'Gift cards balance and transaction records',
  'giftCardReport.filter.search': 'Search',
  'giftCardReport.search.placeholder': 'Search card number',
  'giftCardReport.action.clear': 'Clear',

  'giftCardReport.field.cardType': 'Card Type',
  'giftCardReport.field.cardNumber': 'Card Number',
  'giftCardReport.field.issueTime': 'Issue Time',
  'giftCardReport.field.initialBalance': 'Initial Balance',
  'giftCardReport.field.currentBalance': 'Current Balance',
  'giftCardReport.field.totalSpending': 'Total Spending',
  'giftCardReport.field.lastUpdate': 'Last Update',
  'giftCardReport.field.status': 'Status',
  'giftCardReport.field.issuer': 'Issuer',

  'giftCardReport.detail.title': 'Gift Card',

  'giftCardReport.transaction.field.time': 'Time',
  'giftCardReport.transaction.field.type': 'Type',
  'giftCardReport.transaction.field.staff': 'Staff',
  'giftCardReport.transaction.field.purchaseAmount': 'Purchase Amount',
  'giftCardReport.transaction.field.bonusAmount': 'Bonus Amount',
  'giftCardReport.transaction.field.balance': 'Balance',
  'giftCardReport.transaction.field.paymentType': 'Payment Type',
  'giftCardReport.transaction.field.paymentTotal': 'Payment Total',
  'giftCardReport.transaction.field.orderNumber': 'Order #',
  'giftCardReport.giftCard.action.suspend': 'Suspend Card',
  'giftCardReport.giftCard.action.active': 'Active Card',
  'giftCardReport.giftCard.action.delete': 'Delete Card',
  'giftCardReport.giftCard.action.suspend.title': 'Would you like to suspend gift card account?',
  'giftCardReport.giftCard.action.suspend.confirmCotennt': 'Suspended gift card cannot be used towards purchase',
  'giftCardReport.giftCard.action.suspend.comfirm': 'Yes, suspend',
  'giftCardReport.giftCard.action.suspend.cancel': 'Cancel',

  'giftCardReport.giftCard.action.delete.title': 'Would you like to DELETE gift card account?',
  'giftCardReport.giftCard.action.delete.confirmCotennt': 'All records of the gift card will be erased？',
  'giftCardReport.giftCard.action.delete.comfirm': 'Yes, delete',
  'giftCardReport.giftCard.action.delete.cancel': 'Cancel',

  'giftCardReport.giftCard.action.active.title': 'Would you like to reactive gift card account?',
  'giftCardReport.giftCard.action.active.confirmCotennt': '',
  'giftCardReport.giftCard.action.active.comfirm': 'Yes, reactive',
  'giftCardReport.giftCard.action.active.cancel': 'Cancel',

  'giftCardReport.giftCard.suspend.success': 'Suspended gift card Successfully',
  'giftCardReport.giftCard.suspend.failed': 'Suspended gift card failed',

  'giftCardReport.giftCard.active.success': 'Reactive gift card Successfully',
  'giftCardReport.giftCard.active.failed': 'Suspended gift card Failed',

  'giftCardReport.giftCard.delete.success': 'Delete gift card Successfully',
  'giftCardReport.giftCard.delete.failed': 'Reactive gift card Failed',

  'giftCardReport.giftCard.action.confirm': 'Confirm',
  'giftCardReport.giftCard.action.cancel': 'Cancel',

  'giftCardReport.detail.cardsection.cardinfo': 'CARD INFO',
  'giftCardReport.detail.cardsection.note': 'NOTE',
  'giftCardReport.detail.cardsection.transactions': 'TRANSACTIONS',
  'giftCardReport.detail.cardsection.note.action': 'Edit',

  'giftCardReport.topup.field.issueTime': 'Time',
  'giftCardReport.topup.field.cardNumber': 'Card number',
  'giftCardReport.topup.field.transcationType': 'Type',
  'giftCardReport.topup.field.staff': 'Staff',
  'giftCardReport.topup.field.purchaseAmount': 'Purchase',
  'giftCardReport.topup.field.bonusAmount': 'Bonus Amount',
  'giftCardReport.topup.field.currentBalance': 'Balance',
  'giftCardReport.topup.field.paymentType': 'Payment Type',
  'giftCardReport.topup.field.paymentTotalAmount': 'Payment Total',
  'giftCardReport.topup.field.orderNumber': 'Order #',
};
