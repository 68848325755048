export default {
  'payoutFeeReport.title': 'Payout & Fee Report',

  'payoutFeeReport.column.date': 'Order Date',
  'payoutFeeReport.column.type': 'Type',
  'payoutFeeReport.column.payout_range': 'Transaction Date',
  'payoutFeeReport.column.payout_date': 'Payout Date',
  'payoutFeeReport.column.payment_amount': 'Total Transaction',
  'payoutFeeReport.column.others': 'Others',
  'payoutFeeReport.column.others.description':
    'Restaurant Group Gift Card/Loyalty Charges And None Processing Adjustment',
  'payoutFeeReport.column.fees': 'Fees',
  'payoutFeeReport.column.refund': 'Chargesback / Reversals',
  'payoutFeeReport.column.transaction_fee': 'Transaction Fees',
  'payoutFeeReport.column.transaction_fee.description': 'This Fee only works for Fix Rate Model.',
  'payoutFeeReport.column.amount': 'Net Payout',
  'payoutFeeReport.column.state': 'Payout Status',
  'payoutFeeReport.column.note': 'Notes',

  'payoutFeeReport.section.payoutDetail': 'Payout Detail',
  'payoutFeeReport.section.feeDetail': 'Fee Detail',

  'payoutFeeReport.fee.type': 'Type',
  'payoutFeeReport.fee.payout_range': 'Payout Range',
  'payoutFeeReport.fee.chargeDate': 'Date',
  'payoutFeeReport.fee.date': 'Date',
  'payoutFeeReport.fee.itemName': 'Item Name',
  'payoutFeeReport.fee.sumAmount': 'Amount',
  'payoutFeeReport.fee.tax': 'Tax',
  'payoutFeeReport.fee.amount': 'Total Amount',
  'payoutFeeReport.fee.status': 'Status',
  'payoutFeeReport.fee.notes': 'Notes',
  'payoutFeeReport.fee.summary': 'Total',
  'payoutFeeReport.fee.download_link': 'Download',
};
