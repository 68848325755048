export default {
  'menuBuilder.menus': 'Menus',
  'menuBuilder.menuBuilder': 'Menu Builder',
  'menuBuilder.action.sort': 'Sort',
  'menuBuilder.action.updateMultiple': 'Update Multiple',
  'menuBuilder.action.updateMultiple.pricing': 'Pricing',
  'menuBuilder.action.updateMultiple.memberPrice': 'Member Price',
  'menuBuilder.action.updateMultiple.discount': 'Discount',
  'menuBuilder.action.updateMultiple.taxSettings': 'Tax Settings',
  'menuBuilder.action.updateMainImage': 'Upload main image',
  'menuBuilder.action.update3rdImage': 'Upload 3rd image',
  'menuBuilder.action.addExistingDish': 'Add Existing',
  'menuBuilder.action.addNewDish': 'New Dish',
  'menuBuilder.action.dishSorting': 'Dish Sorting',
  'menuBuilder.action.addMore': 'Add More',
  'menuBuilder.action.editDish': 'Edit Dish',
  'menuBuilder.action.setColor': 'Set Color',
  'menuBuilder.action.editMenu': 'Menu Info',
  'menuBuilder.action.addTime': 'Add Time',
  'menuBuilder.action.searchMeals': 'Search Result',
  'menuBuilder.action.showDetail': 'See detail',
  'menuBuilder.action.close': 'Close',
  'menuBuilder.action.dishSort': 'Dish Sorting',
  'menuBuilder.action.showMore': 'Show {count} more',
  'menuBuilder.action.hideMore': 'Hide {count} more',

  'menuBuilder.column.dish': 'Dish',
  'menuBuilder.column.menuAndCategory': 'Menu & Category',
  'menuBuilder.column.display_color': 'Color',
  'menuBuilder.column.price': 'Price',
  'menuBuilder.column.open_price': 'Open Price',
  'menuBuilder.column.point_price': 'Point Price',
  'menuBuilder.column.member_price': 'Member Price',
  'menuBuilder.column.discount_exempt': 'Discount',
  'menuBuilder.column.tax_exempt': 'No-tax',
  'menuBuilder.column.tax_code': 'Tax Code & Rate',
  'menuBuilder.column.tax_rate': 'Rate: ',
  'menuBuilder.column.customization': 'Customization',
  'menuBuilder.column.code': 'Custom Code',
  'menuBuilder.column.name': 'Name',
  'menuBuilder.column.foreign_name': 'Foreign Name',
  'menuBuilder.column.available_time': 'Available Time',
  'menuBuilder.column.specifal_time': 'Special Time',
  'menuBuilder.requiredStartTime': 'Start time is required',
  'menuBuilder.requiredEndTime': 'End time is required',
  'menuBuilder.startTimeShouldBeEarlierThanEndTime': 'Start time should before end time.',
  'menuBuilder.requriedSpecialDate': 'Date of is required',
  'menuBuilder.rowConflicts': 'The time of item {i} conflicts with item {j}',

  'menuBuilder.segment.search': 'Search',
  'menuBuilder.segment.selected': 'Edit Selected Dishes',

  'menuBuilder.allDaysClose': 'All day close',
  'menuBuilder.guideText': 'Select a category within this menu to start organizing dishes',
  'menuBuilder.searchPlaceholder': 'Find an existing dish',
  'menuBuilder.searchEmptyDescription': 'Search and select all the existing dishes you want to offer in this category.',
  'menuBuilder.selectedMealsReachedTheLimit':
    'You can select up to <span style="color: #ce2c54;font-size: 14px;font-family: PingFang SC;">{maxCount} items</span> at a time. Please cancel other selections and try again.',
  'menuBuilder.searchMealsPlaceholder': 'Search items',

  'menuBuilder.section.dishInfo': 'Dish Info',
  'menuBuilder.section.printers': 'Printers',
  'menuBuilder.section.taxCode': 'Tax Code',
  'menuBuilder.selectedCategories': 'Selected categories:',
  'menuBuilder.deleteMealPrompt': 'Sure to delete this dish?',
  'menuBuilder.sortDish.title': 'Set Sort',
  'menuBuilder.sortBy.price': 'Price',
  'menuBuilder.sortBy.alpha': 'Alpha Sorting',
  'menuBuilder.menuHourMode.always': 'Always',
  'menuBuilder.menuHourMode.specific': 'Specific days and time',
  'menuBuilder.searchGuideText': 'Search and select all the existing dishes you want to offer in this category.',
  'menuBuilder.emptyResultText': 'No items match your search',
  'menuBuilder.emptyResultGuide': 'Try updating or clearing your search to find items',
  'menuBuilder.loadMenuFailed': 'Load failed, retry',
  'menuBuilder.noData': 'No data',
  'menuBuilder.customizationPickerDrawerTitle': 'Choose Customizations',
  'menuBuilder.selectAll': 'Select All',
  'menuBuilder.customizationsDescription': `
    The <span style="color: #ce2c54;font-family: SimSun, sans-serif;">*</span> by the customization group indicates it is required that the customer choose at least a customization option when ordering this dish. If you would like to change this, please visit the Customizations page.
  `,
  'menuBuilder.categoriesRequired': 'Please pick at least one category in any menu.',
  'menuBuilder.noMealItems': 'Let’s add new items to this menu category',
  'menuBuilder.pointsCategory': 'Points',
  'menuBuilder.dishAdded': 'Added',
  'menuBuilder.successfullyCreated': 'Successfully created dishes',
  'menuBuilder.successfullyUpdated': 'Successfully updated dishes',
  'menuBuilder.successfullyUpdatedMenu': 'Successfully updated menu info',
  'memuBuilder.taxable': 'Taxable',

  'menuBuilder.emptySearchResult': 'No items matched your search. Please update your search terms to find items.',
  'menuBuilder.tooManySearchResult':
    'Your search for “<span>{searchTerm}</span>” returned more than <span>50</span> items. Please refine your search terms to obtain more specific results.',
  'menuBuilder.emptySearchTerm': 'Please type any search terms to find items.',
  'menuBuilder.noCustomizations': 'No customizations',
  'menuBuilder.searchCustomizations': 'Search items',

  'menuBuilder.channelVisibility': 'Channel Visibility',
  'menuBuilder.channelVisibility.description': 'The menu will display on the channel enabled below.',
  'menuBuilder.channel.qr': 'QR',
  'menuBuilder.channel.kiosk': 'Kiosk',
  'menuBuilder.channel.tablet': 'Tablet',
};
