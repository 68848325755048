export default {
  'loyaltyMember.title': 'Members',
  'loyaltyMember.add.title': 'Add Member',
  'loyaltyMember.edit.title': 'Edit Member',
  'loyaltyMember.required.desc': 'Fields with * are required',

  'loyaltyMember.column.name': 'Name',
  'loyaltyMember.column.phone_number': 'Phone',
  'loyaltyMember.column.member_card_number': 'Card Number',
  'loyaltyMember.column.email': 'Email',
  'loyaltyMember.column.birthday': 'Birthday',
  'loyaltyMember.column.status': 'Status',
  'loyaltyMember.column.expiration_date': 'Expiration Date',
  'loyaltyMember.column.usable_amount': 'Cashback Balance',
  'loyaltyMember.column.cash_back_balance': 'Cashback Balance',
  'loyaltyMember.column.usable_points': 'Points',
  'loyaltyMember.column.points': 'Points',
  'loyaltyMember.column.prepaid_card_balance': 'Prepaid Card',
  'loyaltyMember.column.balance': 'Balance',
  'loyaltyMember.column.total_visits': 'Total Visits',
  'loyaltyMember.column.total_revenue': 'Total Revenue',
  'loyaltyMember.column.last_visit_at': 'Last Visit',
  'loyaltyMember.column.created_at': 'Member Since',
  'loyaltyMember.column.level': 'Level',
  'loyaltyMember.column.lifetime_cash_back': 'Lifetime Cashback',
  'loyaltyMember.column.source': 'Source',
  'loyaltyMember.column.registerFrom': 'Register from',
  'loyaltyMember.unit.balance': 'pts',
  'loyaltyMember.alias.status': 'Status',

  'loyaltyMember.name.placeholder': 'Full Name',
  'loyaltyMember.query.placeholder': 'Search by name or phone number or card number',
  'loyaltyMember.source.placeholder': 'Choose a source',
  'loyaltyMember.query.expiredDateRange': 'Expiration period filter',

  'loyaltyMember.section.basic_info': 'Basic Info',
  'loyaltyMember.section.membership': 'Membership Details',
  'loyaltyMember.section.note': 'Note',
  'loyaltyMember.section.transactions': 'Transactions',

  'loyaltyMember.action.addNote': 'Add Note',
  'loyaltyMember.action.editNote': 'Edit Note',

  'loyaltyMember.name.required': 'Name is required',
  'loyaltyMember.phone_number.required': 'Phone is required',
  'loyaltyMember.source.required': 'Source is required',

  'loyaltyMember.created_at.desc': 'Member since {created_at}',
  'loyaltyMember.updateSuccess': 'Successfully updated loyalty member',
  'loyaltyMember.createSuccess': 'Successfully created loyalty member',

  'loyaltyMemberTransaction.column.created_at': 'Time',
  'loyaltyMemberTransaction.column.transaction_type': 'Type',
  'loyaltyMemberTransaction.column.staff': 'Staff',
  'loyaltyMemberTransaction.column.balance': 'Balance',
  'loyaltyMemberTransaction.column.payment_type': 'Payment Type',
  'loyaltyMemberTransaction.column.total': 'Loyalty Credit Payment Total',
  'loyaltyMemberTransaction.column.order_number': 'Order #',
  'loyaltyMemberTransaction.column.cash_back_issued': 'Cashback Issued',
};
