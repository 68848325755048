import component from './en-US/component';
import globalHeader from './en-US/globalHeader';
import action from './en-US/action';
import team from './en-US/team';
import role from './en-US/role';
import member from './en-US/member';
import user from './en-US/user';
import menuManagement from './en-US/menuManagement';
import status from './en-US/status';
import devices from './en-US/devices';
import request from './en-US/request';
import resetPassword from './en-US/resetPassword';
import discount from './en-US/discount';
import permission from './en-US/permission';
import order from './en-US/order';
import printerMapping from './en-US/printerMapping';
import appConfig from './en-US/appConfig';
import orderDetail from './en-US/orderDetail';
import printer from './en-US/printer';
import accountSettings from './en-US/accountSettings';
import transaction from './en-US/transaction';
import task from './en-US/task';
import settings from './en-US/settings';
import printerTemplate from './en-US/printTemplate';
import activities from './en-US/activities';
import floorPlan from './en-US/floorPlan';
import dishPreference from './en-US/dishPreference';
import salesReport from './en-US/salesReport';
import payoutFeeReport from './en-US/payoutFeeReport';
import adyenMonthlyReport from './en-US/adyenMonthlyReport';
import dynamicRateFeeReport from './en-US/dynamicRateFeeReport';
import adjustment from './en-US/adjustment';
import rgReport from './en-US/rgReport';
import fee from './en-US/fee';
import menu from './en-US/menu';
import customizations from './en-US/customizations';
import password from './en-US/password';
import permissionManagement from './en-US/permissionManagement';
import workforce from './en-US/workforce';
import priceEditor from './en-US/priceEditor';
import dishEdit from './en-US/dishEdit';
import loyalty from './en-US/loyalty';
import loyaltyMember from './en-US/loyaltyMember';
import prepaidTransactions from './en-US/prepaidTransactions';
import loyaltySettings from './en-US/loyaltySettings';
import monthlyStatement from './en-US/monthlyStatement';
import alternativePayments from './en-US/alternativePayments';
import cashStatement from './en-US/cashStatement';
import timeRange from './en-US/timeRange';
import userActivity from './en-US/userActivity';
import payout from './en-US/payout';
import quickNotes from './en-US/quickNotes';
import categories from './en-US/categories';
import tipSettings from './en-US/tipSettings';
import serviceSettings from './en-US/serviceSettings';
import printoutSettings from './en-US/printoutSettings';
import smartOrderingSettings from './en-US/smartOrderingSettings';
import waitlistSettings from './en-US/waitlistSettings';
import reservationSettings from './en-US/reservationSettings';
import department from './en-US/department';
import tipsAllocation from './en-US/tipsAllocation';
import giftCardReport from './en-US/giftcardReport';
import filter from './en-US/filter';
import home from './en-US/home';
import breakManagement from './en-US/breakManagement';
import printerLogs from './en-US/printerLogs';
import common from './en-US/common';
import reportsSettings from './en-US/reportsSettings';
import promotionManagement from './en-US/promotionManagement';
import menuSettings from './en-US/menuSettings';
import menuBuilder from './en-US/menuBuilder';
import onlineOrderingSettings from './en-US/onlineOrderingSettings';
import menuDecorate from './en-US/menuDecorate';
import salesCategory from './en-US/salesCategory';
import globalPreferenceSettings from './en-US/globalPreferenceSettings';
import servicePeriod from './en-US/servicePeriod';
import menuDecorationPreview from './en-US/menuDecorationPreview';
import cdsSettings from './en-US/cdsSettings';
import cfdSettings from './en-US/cfdSettings';

export default {
  ...common,
  ...globalHeader,
  ...component,
  ...action,
  ...team,
  ...role,
  ...member,
  ...user,
  ...menuManagement,
  ...status,
  ...devices,
  ...request,
  ...resetPassword,
  ...discount,
  ...permission,
  ...order,
  ...printerMapping,
  ...appConfig,
  ...orderDetail,
  ...printer,
  ...accountSettings,
  ...transaction,
  ...task,
  ...settings,
  ...printerTemplate,
  ...activities,
  ...floorPlan,
  ...dishPreference,
  ...salesReport,
  ...payoutFeeReport,
  ...adyenMonthlyReport,
  ...dynamicRateFeeReport,
  ...adjustment,
  ...rgReport,
  ...fee,
  ...menu,
  ...customizations,
  ...password,
  ...permissionManagement,
  ...workforce,
  ...priceEditor,
  ...dishEdit,
  ...loyalty,
  ...loyaltyMember,
  ...prepaidTransactions,
  ...loyaltySettings,
  ...monthlyStatement,
  ...alternativePayments,
  ...cashStatement,
  ...userActivity,
  ...timeRange,
  ...payout,
  ...quickNotes,
  ...categories,
  ...tipSettings,
  ...serviceSettings,
  ...printoutSettings,
  ...smartOrderingSettings,
  ...department,
  ...tipsAllocation,
  ...giftCardReport,
  ...filter,
  ...home,
  ...waitlistSettings,
  ...breakManagement,
  ...printerLogs,
  ...reservationSettings,
  ...reportsSettings,
  ...promotionManagement,
  ...menuSettings,
  ...menuBuilder,
  ...onlineOrderingSettings,
  ...menuDecorate,
  ...salesCategory,
  ...globalPreferenceSettings,
  ...servicePeriod,
  ...menuDecorationPreview,
  ...cdsSettings,
  ...cfdSettings,
};
