export default {
  'salesCategory.title': 'Sales Category',
  'salesCategory.selectPlaceholder': 'more details',
  'salesCategory.salesCategoryIsBrokenDownInto': 'Sales Category is broken down into',
  'salesCategory.edit.title': 'Edit Sales Category',
  'salesCategory.create.title': 'Create Sales Category',
  'salesCategory.add.dishes': 'Add dishes to the sales categories',

  'salesCategory.field.name': 'Name',
  'salesCategory.field.foreign_name': 'Foreign Name',
  'salesCategory.field.dishes': 'Dishes',
  'salesCategory.field.description': 'Description',
  'salesCategory.field.foreign_description': 'Foreign Description',

  'salesCategory.column.name': 'Sales Category',
  'salesCategory.column.itemName': 'Sales Category > Item Name',
  'salesCategory.column.orderSourceAndServiceType': 'Sales Category > Order Source & Service Type',
  'salesCategory.column.serviceTypeAndOrderSource': 'Sales Category > Service Type & Order Source',
  'salesCategory.column.server': 'Sales Category > Server',
  'salesCategory.column.operation': 'Action',

  'salesCategory.column.sales_category': 'Name',
  'salesCategory.column.item_qty': 'Item Qty',
  'salesCategory.column.net_sales_column': 'Net Sales',
  'salesCategory.column.discount_amount': 'Discount Amount',
  'salesCategory.column.tax_amount': 'Tax Amount',
  'salesCategory.column.gross_sales_column': 'Gross Sales',

  'salesCategory.name.isRequired': 'Name is Required',
  'salesCategory.foreign_name.isRequired': 'Foreign Name is Required',

  'salesCategory.deletion.promptContent': 'Sure to delete the Sales category?',
  'salesCategory.action.editSalesCategory': 'Edit Sales Category',
  'salesCategory.action.setSalesCategory': 'Set for category',
  'salesCategory.methodForAddingDishes': 'Method for Adding Dishes to Sales Categories:',
  'salesCategory.mode.setCategoryForSalesCategory': 'Set the corresponding sales category for each category',
  'salesCategory.mode.setCategoryForDishes': 'Add dishes to the corresponding sales category',
  'salesCategory.modal.tip': 'Are you sure to switch?',
  'salesCategory.tips.01':
    'Any adjustments to the sales category will only  apply to the orders created after the adjustment.So if you modify the definition of a sales category, previous report will not be affected.',
  'salesCategory.tips.02':
    'If you are using the sales category for the first time, you can contact our customer service to backfill the historical data for this month, in order to better use the sales category report.',
  'salesCategory.add.dishes.menu.change.tip': 'Do you want to save changes before switching?',
};
