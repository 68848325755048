export default {
  'menu.customizations.title': 'Customizations',
  'menu.customizations.groups': 'Customization Groups ({count} items)',
  'menu.customizations.options': 'Customization Options ({count} items)',
  'menu.customizations.dishPreferences': '3rd Level items({count} items)',
  'menu.customizations.group.required': 'Required',
  'menu.customizations.group.maxSelections': 'Up to {maxCount} selections',
  'menu.customizations.action.add': 'Add a customization group',

  'menu.customizations.edit.title': 'Edit Customizations',
  'menu.customizations.add.title': 'Add Customizations',
  'menu.customizations.section.details': 'Details',
  'menu.customizations.section.options': 'Options',
  'menu.customizations.column.name': 'Group Name',
  'menu.customizations.column.foreignName': 'Group Foreign Name',
  'menu.customizations.column.code': 'Custom Code',
  'menu.customizations.column.required': 'Required',
  'menu.customizations.column.minSelections': 'Minimum Selections',
  'menu.customizations.errors.minSelectionsLessOrEqualThanMaxQuantities':
    'Minimum option value must be smaller or equal to maximum quantity value.',
  'menu.customizations.errors.minSelectionsLessOrEqualThanMinQuantities':
    'Minimum option value must be smaller or equal to minimum quantity value.',
  'menu.customizations.column.minQuantities': 'Minimum Quantities',
  'menu.customizations.column.maxSelections': 'Maximum Selections',
  'menu.customizations.errors.maxSelectionsGreatOrEqualThanMinSelections':
    'Maximum option cannot be smaller than minimum option.',
  'menu.customizations.column.maxQuantities': 'Maximum Quantities',
  'menu.customizations.errors.maxQuantitiesGreatOrEqualThanMinQuantities':
    'Maximum quantity cannot be smaller than minimum quantity.',
  'menu.customizations.errors.maxSelectionsLessOrEqualThanMaxQuantities':
    'Maximum selection cannot be greater than maximum quantity.',
  'menu.customizations.column.maxQuantitiesPerOption': 'Maximum Quantities Per Option',
  'menu.customizations.errors.maxQuantitiesPerOptionLessOrEqualThanMaxQuantities':
    'Maximum quantities per option cannot be greater than maximum quantity.',
  'menu.customizations.option.column.name': 'Option Name',
  'menu.customizations.option.column.foreignName': 'Option Foreign Name',
  'menu.customizations.option.column.price': 'Option Price',
  'menu.customizations.option.column.groupName': 'Customization Group',
  'menu.customizations.option.column.status': 'Status',
  'menu.customizations.option.column.quota': 'Quota',
  'menu.customizations.option.column.code': 'Custom Code',
  'menu.customizations.option.column.isDefault': 'Default Option',
  'menu.customizations.option.column.defaultQuantity': 'Default Quantity',
  'menu.customizations.option.isDefault': '(Default)',
  'menu.customizations.option.defaultQuantity': 'Default quantity:{defaultQuantity}',

  'menu.customizations.column.name.required': 'Group name is required',
  'menu.customizations.column.foreignName.required': 'Group foreign name is required',
  'menu.customizations.option.name.required': 'Option name is required',
  'menu.customizations.option.name.existed': 'Option name is existed',
  'menu.customizations.option.foreign_name.required': 'Option foreign name is required',
  'menu.customizations.option.foreign_name.existed': 'Option foreign name is existed',
  'menu.customizations.column.defaultQuantity.required': 'Default quantity is required',
  'menu.customizations.option.defaultQuantity.exceedMaximum': 'Exceed maximum quantities per option',
  'menu.customizations.option.selected.exceedMaximum': 'Exceed maximum Selections',
  'menu.customizations.option.totalQuantity.exceedMaximum':
    'Currently entered default quantities {selectedQuantity} exceeds maximum quantities {maxSelectedQuantity}',

  'menu.customizations.option.action.add': 'Add more options',
  'menu.customizations.option.action.addPreferences': 'Add preferences',

  'menu.customizations.options.count': '{count} items',

  'menu.customizations.removePrompt': 'Sure to delete this customized property?',

  'menu.customizations.option.column.posName': 'POS Name',
  'menu.customizations.option.column.kitchenName': 'Kitchen Name',
  'menu.customizations.option.column.posName.placeholder': 'A short name shown on the POS menu',
  'menu.customizations.option.column.kitchenName.placeholder': 'A short name shown on kitchen tickets and labels',
  'menu.customizations.option.column.posName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the POS menu',
  'menu.customizations.option.column.kitchenName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the kitchen tickets and labels',
  'menu.customizations.preference.title': 'Preference',
  'menu.customizations.action.choosePreferenceCategories': 'Choose preference categories',
};
