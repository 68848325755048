export default {
  'task.title': 'Tasks',

  'task.column.time': 'Execute Time',
  'task.column.taskId': 'Task Id',
  'task.column.type': 'Type',
  'task.column.status': 'Execute Status',
  'task.column.count': 'Execute Count',
  'task.column.action': 'Actions',

  'task.action.viewTaskData': 'View Task Data',
  'task.action.viewFailureReason': 'View Failure Reason',
  'task.action.retry': 'Retry',

  'task.reason.title': 'Failure Reason',
  'task.data.title': 'Task Data',
};
