export default {
  'breakManagement.title': 'Break & Overtime',
  'breakManagement.tabs.break': 'Break',
  'breakManagement.tabs.overtime': 'Overtime',
  'breakManagement.convertExceedPaidBreakToUnpaid': 'Convert excess time from a paid break to unpaid break',
  'breakManagement.enforceFullTimeUnpaidBreak':
    'Enforce unpaid break by not allowing user to clock in until break time is over',
  'breakManagement.recordOvertime': 'Record overtime and show on the employees’ reports',
  'breakManagement.overtimeThreshold': 'Daily overtime: any hours over the hours entered will count as overtime.',
  'breakManagement.overtimeThreshold.unit': 'hours',
  'breakManagement.breaks.notice': 'Breaks will be considered Unpaid if the restaurant does not set up break types.',

  'breakManagement.column.name': 'Name',
  'breakManagement.column.foreign_name': 'Foreign Name',
  'breakManagement.column.display_name': 'Name',
  'breakManagement.column.type': 'Type',
  'breakManagement.column.duration': 'Duration (mins)',
  'breakManagement.column.active': 'Active',
  'breakManagement.column.action': 'Action',

  'breakManagement.create.title': 'Create Break',
  'breakManagement.edit.title': 'Edit Break',

  'breakManagement.action.create': 'Create New Break',
  'breakManagement.confirmation.deletion': 'Sure to delete the item?',

  'breakManagement.overtime.overtimeRules': `Overtime Rules <span class="helperText">（Strongly recommended to modify overtime rules after the end of current workweek and before the start of next workweek to ensure data accuracy）`,
  'breakManagement.overtime.add.overtimeRules': 'Add Overtime Rules',
  'breakManagement.overtime.edit.overtimeRules': 'Edit Overtime Rules',

  'breakManagement.overtime.dailyHours': 'Daily Hours',
  'breakManagement.overtime.weeklyHours': 'Weekly Hours',
  'breakManagement.overtime.consecutive_days': 'Consecutive Days',
  'breakManagement.overtime.consecutive_days_tips': 'Refers to working consecutive days without a break in a workweek',

  'breakManagement.overtime.daily.overtimeRules': 'Daily Overtime Rules',
  'breakManagement.overtime.weekly.overtimeRules': 'Weekly Overtime Rules',
  'breakManagement.overtime.consecutive_days.overtimeRules': 'Consecutive Days Rules',

  'breakManagement.overtime.workHoursExceed': 'Work Hours Exceed',
  'breakManagement.overtime.overtimeRateFactor': 'Overtime Rate Factor',

  'breakManagement.overtime.pleaseSetAtLeastOneCompleteRule': 'Please set at least one complete rule',
  'breakManagement.overtime.pleaseSetCompleteRule': 'Please set complete rule',
  'breakManagement.overtime.hourlyRuleAlreadyExist': 'Hourly rule already exist',

  'breakManagement.overtime.type.daily': 'Daily Hours',
  'breakManagement.overtime.type.weekly': 'Weekly Hours',
  'breakManagement.overtime.type.consecutive_days': 'Consecutive Days',

  'breakManagement.overtime.column.workHoursExceedAndOvertimeRateFactor':
    'Work Hours Exceed {hours} hours, Overtime Rate Factor {rate}',
  'breakManagement.overtime.column.consecutive_days': 'Consecutive Days {days} days',

  'breakManagement.overtime.column.overtimeHours': '{rate}x',
  'breakManagement.overtime.column.rules': 'Rules',

  'breakManagement.overtime.column.clockOutTips': 'Please check adn confirm the abnormal record',
};
