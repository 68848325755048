export default {
  'timeRange.today': 'Today',
  'timeRange.yesterday': 'Yesterday',
  'timeRange.thisWeek': 'Current Week',
  'timeRange.lastWeek': 'Last Week',
  'timeRange.thisMonth': 'Current Month',
  'timeRange.lastMonth': 'Last Month',
  'timeRange.last7D': '7 Days',
  'timeRange.last30D': '30 Days',
  'timeRange.last90D': '90 Days',
  'timeRange.7d': '7D',
  'timeRange.30d': '30D',
  'timeRange.90d': '90D',
  'timeRange.3m': '3M',
  'timeRange.12m': '12M',
  'timeRange.custom': 'Custom',
};
