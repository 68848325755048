export default {
  'printTemplate.tile': 'Print Template',
  'printTemplate.paperSetting': 'Paper Setting',
  'printTemplate.paperSetting.receipt': 'Receipt',
  'printTemplate.paperSetting.label': 'Label',
  'printTemplate.printLayout': 'Print Layout',
  'printTemplate.printLayout.horizontal': 'Horizontal',
  'printTemplate.printLayout.vertical': 'Vertical',
  'printTemplate.printLayout.table': 'Table',
  'printTemplate.printWidget': 'Print Widget',
  'printTemplate.printWidget.text': 'Text',
  'printTemplate.printWidget.orderField': 'Order Field',
  'printTemplate.printWidget.logo': 'Logo',
  'printTemplate.printWidget.divider': 'Divider',

  'printTemplate.content': 'Print Template Content',

  'printTemplate.setting.padding': 'Padding',
  'printTemplate.setting.padding.left': 'Left',
  'printTemplate.setting.padding.right': 'Right',
  'printTemplate.setting.padding.top': 'Top',
  'printTemplate.setting.padding.bottom': 'Bottom',

  'printTemplate.setting.contentAlign': 'Content Align',
  'printTemplate.setting.contentAlign.horizontal': 'Horizontal Content Align',
  'printTemplate.setting.contentAlign.vertical': 'Vertical Content Align',

  'printTemplate.setting.table': 'Table Configuration',
  'printTemplate.setting.table.addColumn': 'Add Column',
};
