export default {
  'password.requirement.title': 'Password Requirement:',

  'password.requirement.atLeast8Chars': 'Have at least 8 Characters',
  'password.requirement.atLeast1LowercaseLetter': 'Include at least 1 lowercase letter',
  'password.requirement.atLeast1UppercaseLetter': 'Include at least 1 uppercase letter',
  'password.requirement.atLeast1Number': 'Include at least 1 uppercase letter',
  'password.requirement.match': 'Match',

  'password.strength': 'Password strength',
  'password.strength.weak': 'Weak',
  'password.strength.medium': 'Medium',
  'password.strength.strong': 'Strong',
};
