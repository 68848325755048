export default {
  'adjustment.title': 'Adjustment Management',
  'adjustment.discount': 'Discounts',
  'adjustment.fee': 'Fees',

  'adjustment.action.createFee': 'New Fee',
  'adjustment.action.createDiscount': 'New Discount',
  'adjustment.action.createPaymentMethod': 'New Payment Method',
  'adjustment.action.createQuickNotes': 'New Quick Note',
};
