export default {
  'floorPlan.title': 'Floor Plan Management',
  'floorPlan.placeholder.section.name': 'Enter section name',
  'floorPlan.placeholder.section.foreign_name': 'Enter section foreign name',
  'floorPlan.name.isRequired': 'Section Name is required',
  'floorPlan.foreign_name.isRequired': 'Section Foreign Name is required',
  'floorPlan.description':
    'For floor plan that has over {maxCount} tables, try to divide the floor plan into small sections for optimal display.',
  'floorPlan.section.title': 'Section Info',
  'floorPlan.section.field.name': 'Section Name',
  'floorPlan.section.field.foreign_name': 'Section Foreign Name',
  'floorPlan.section.field.status': 'Active',
  'floorPlan.shapes.tables': 'Tables',
  'floorPlan.shapes.objects': 'Objects',
  'floorPlan.noShapes': 'You don’t have anything on your floor plan yet, start by adding some tables/objects',
  'floorPlan.section.notFound.title': 'The section is not found',
  'floorPlan.section.notFound.description': 'Please check if id of the section is correct or retry',

  'floorPlan.action.addSection': 'Set up a new section',
  'floorPlan.action.backToList': 'Go Floor Plan List',
  'floorPlan.action.retry': 'Retry',
  'floorPlan.action.deleteTable': 'Delete table',

  'floorPlan.shape.capacity': 'Seating Capacity',
  'floorPlan.shape.orientation': 'Orientation',
  'floorPlan.shape.width': 'Width',
  'floorPlan.shape.radius': 'Radius',
  'floorPlan.shape.height': 'Height',
  'floorPlan.shape.status': 'Active',
  'floorPlan.shape.name': 'Name',
  'floorPlan.shape.label': 'Label',
  'floorPlan.shape.amount': 'Amount',
  'floorPlan.shape.setAsOpenTab': 'Set as Open Tab',
  'floorPlan.shape.setAsOpenTab.description': 'Can create multiple orders from this table when turned on.',
  'floorPlan.shapes.editTitle': 'Edit ({count}) {shapeName}',

  'floorPlan.section.shape.overlap': 'The distance between tables/objects should be at least {minGap} blocks.',
  'floorPlan.section.shapes.copied': 'Copied! Press ctrl/cmd key along with v key can be pasted.',
  'floorPlan.section.saveSuccess': 'Successfully Saved.',
  'floorPlan.section.shapes.occupied':
    'Sorry, the system does not support editing on occupied tables, please double check.',
  'floorPlan.section.shapes.deleteOccupiedShapeError':
    'Sorry, the system does not support deleting on occupied tables.',
  'floorPlan.section.shapes.deletionContent': 'Are you sure you want to delete {shapesText}?',
  'floorPlan.section.exceedMaxCount': 'Tables amount reach the limit of {maxCount}.',
  'floorPlan.section.existed': 'Section name is occupied.',
  'floorPlan.shape.name.duplicated':
    'Save not success. The system has discovered tables with duplicated names, please correct the errors first.',
  'floorPlan.shape.name.occupied': 'Table name is occupied.',
  'floorPlan.shape.name.isRequired': 'Name is required.',
  'floorPlan.shape.capacity.isRequired': 'Capacity is required.',
  'floorPlan.shape.capacity.min': 'Capacity must greater than 0.',
  'floorPlan.shape.label.isRequired': 'Label is required.',
  'floorPlan.shape.amount.isRequired': 'Amount is required.',
  'floorPlan.shape.amount.min': 'Amount must greater than 0.',
  'floorPlan.shape.width.isRequired': 'Width is required.',
  'floorPlan.shape.width.min': 'Width must greater than 0.',
  'floorPlan.shape.radius.isRequired': 'Radius is required.',
  'floorPlan.shape.radius.min': 'Radius must greater than 0.',
  'floorPlan.shape.height.isRequired': 'Height is required.',
  'floorPlan.shape.height.min': 'Height must greater than 0.',
  'floorPlan.deletion.tip': 'You can only delete inactive sections.',
  'floorPlan.section.deletionPromptContent': 'Are you sure you want to delete this section?',
  'floorPlan.noProperPosition': 'Cannot find a proper position to place shape.',
  'floorPlan.limit': 'Minimum is {min}, maximum is {max}',
  'floorPlan.openTab.prompt':
    'Setting table as open tab means that table will never turn red & multiple orders can be created from this table. Are you sure this table should be set as open tab?',
};
