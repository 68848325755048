export default {
  'alternativePayments.title': 'Alternative Payment Methods',
  'alternativePayments.column.display': 'Display',
  'alternativePayments.column.name': 'Name',
  'alternativePayments.column.foreign_name': 'Foreign Name',

  'alternativePayments.create.title': 'New Alternative Payment Method',
  'alternativePayments.edit.title': 'Edit Alternative Payment Method',

  'alternativePayments.name.required': 'Name is required',
  'alternativePayments.foreign_name.required': 'Foreign Name is required',

  'alternativePayments.name.existed': 'Name is occupied',
  'alternativePayments.foreign_name.existed': 'Foreign name is occupied',
  'alternativePayments.deletePromotion': 'Sure to delete this alternative payment?',
};
