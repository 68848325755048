export default {
  'department.unassignedMembers': 'Unassigned Employees',
  'department.unassigned': 'Unassigned Department',
  'department.column.name': 'Department Name',
  'department.column.foreign_name': 'Department Foreign Name',
  'department.column.description': 'Description',
  'department.action.addMembers': 'Add Employee(s)',
  'department.add.title': 'Add Department',
  'department.edit.title': 'Edit Department',
  'department.section.generalInformation': 'General Information',
  'department.section.members': 'Employees',
  'department.addMembers.copy':
    'Select unassigned employees you would like to assign to this department. To change existing role assignment, please visit the {memberPageLink} page. ',
  'department.add.success': 'Successfully add department',
  'department.edit.success': 'Successfully update department',
  'department.delete.title': 'Are you sure to delete the department?',
  'department.delete.description': 'Employees in this department will be moved to {unassignedDepartmentText}.',
  'department.delete.success': 'Successfully delete department',
};
