export default {
  'workforce.title': 'Employee Reports',
  'workforce.editTimeSheet': 'Edit Time Sheet',
  'workforce.filterByRoles': 'Filter By Roles',
  'workforce.filterByDepartments': 'Filter By Departments',
  'workforce.filterByEmployee': 'Search Employee',
  'workforce.filterByOrderSource': 'Filter By Order Source(s)',
  'workforce.laborSummaryDescription':
    'This page should not be used for payroll because it does not include items like tips and taxes, and may not include all overtime pay.',
  'workforce.filter.date': 'Period',
  'workforce.filter.employee': 'Employee',
  'workforce.filter.serviceType': 'Service Type',
  'workforce.filter.role': 'Role',
  'workforce.filter.orderSource': 'Order Source',
  'workforce.action.add': 'Add Time Sheet',
  'workforce.action.export': 'Export',

  'workforce.column.clock_date': 'Date',
  'workforce.column.first_name': 'Name',
  'workforce.column.employee': 'Employee',
  'workforce.column.role': 'Role',
  'workforce.column.department': 'Department',
  'workforce.column.clock_in': 'Clock In',
  'workforce.column.formatted_clock_in': 'Clock In',
  'workforce.column.clock_out': 'Clock Out',
  'workforce.column.formatted_clock_out': 'Clock Out',
  'workforce.column.regular_duration': 'Regular Hours',
  'workforce.column.over_time_duration': 'Overtime Hours',
  'workforce.column.clock_duration': 'Total Hours Worked',
  'workforce.column.hours_worked': 'Hours Worked',
  'workforce.column.number_of_employees': '# of Employees',
  'workforce.column.hourly_rate': 'Hourly Rate',
  'workforce.column.total_pay': 'Estimated Total Pay',
  'workforce.column.paid_break_duration': 'Paid Break',
  'workforce.column.unpaid_break_duration': 'Unpaid Break',
  'workforce.column.action': 'Action',
  'workforce.column.clockTime': 'Clock In/Clock Out',
  'workforce.column.break': 'Break(s)',
  'workforce.column.total_hours_worked': 'Total Hours Worked',
  'workforce.column.duration': 'Clock in & out Duration',
  'workforce.column.break_start': 'Break Start',
  'workforce.column.break_end': 'Break End',
  'workforce.column.overtime_rate_factor': 'Overtime Hours（{rate}x）',
  'workforce.column.description.regular_duration': `
    <p>
      Total hours worked –  Overtime.
    </p>
    <p style="margin-bottom: 0px">
      Calculation includes paid breaks but not unpaid break.
    </p>
  `,
  'workforce.column.description.over_time_duration':
    'Overtime hours are calculated based on the configured overtime rules and displayed as ”Overtime Rate Factor: Overtime Hours”',
  'workforce.column.description.total_hours_worked': 'Total Hours Worked = Regular hours + Overtime hours',
  'workforce.column.description.paid_break_duration': 'Counted as Regular Hours',
  'workforce.column.description.unpaid_break_duration': 'Not counted as Regular Hours',
  'workforce.column.description.formatted_clock_out': `
    <p>
      Please check and confirm the abnormal record
    </p>
    <div>
      <div style="margin-bottom: 8px">
        <span class="icon-error"></span>
        Clock in&out durition over 24 hours
      </div>
      <div style="margin-bottom: 0px">
        <span class="icon-warn"></span>
        Auto clock_out
      </div>
    </div>
  `,

  'workforce.employee.isRequired': 'Employee is Required',
  'workforce.date.isRequired': 'Date is Required',
  'workforce.clockTime.isRequired': 'Clock Range is Required',
  'workforce.clock_in.isRequired': 'Clock In is Required',
  'workforce.clock_out.isRequired': 'Clock Out is Required',
  'workforce.cancelEdit.confirm': 'Sure to Cancel?',
  'workforce.deletion.promptTitle': 'Time Sheet Delection Prompt',
  'workforce.deletion.promptContent': 'Sure To Delete The Time Sheet?',
  'workforce.clock_in.exceed': 'Clock_in time should before clock out time',
  'workforce.clock_out.exceed': 'Clock_out time should after clock in time',
  'workforce.duration.format': '{hours} hours, {minutes} mins',
  'workforce.editBreakTime': 'Edit Break Time',
  'workforce.updateTimeSheetSuccess': 'Successfully update time sheet',
  'workforce.breakOverlap': 'Breaks overlap',
  'workforce.exceedRange': 'Break(s) should within working time',
  'workforce.breakStartShouldAfterClockIn': 'Break start time should be after clock in time',
  'workforce.breakEndShouldBeforeClockOut': 'Break end time should be before clock out time',
  'workforce.breakHasEmpty': 'Break start time or end time is empty',
  'workforce.breakStart.exceed': 'Break start time should be before end time',
  'workforce.breakEnd.exceed': 'Break end time should be after start time',
  'workforce.action.confirmClockOut': 'Confirm clock out',

  'workforce.column.duration_h': 'Clock in & out Duration (h)',
  'workforce.column.unpaid_break_duration_h': 'Unpaid Break (h)',
  'workforce.column.paid_break_duration_h': 'Paid Break (h)',
  'workforce.column.clock_duration_h': 'Total Hours Worked (h)',
  'workforce.column.over_time_duration_h': 'Overtime Hours (h)',
  'workforce.column.regular_duration_h': 'Regular Hours (h)',
  'workforce.column.total_hours_worked_h': 'Total Hours Worked (h)',
  'workforce.column.overtime_rate_factor_h': 'Overtime Hours (h)（{rate}x）',
  'workforce.column.hours_worked_h': 'Hours Worked (h)',
};
