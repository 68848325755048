export default {
  'rg.report.availableToPayout': 'Available To Payout',

  'rg.report.totalDeferredSales': 'Total Defferred Sales',
  'rg.report.totalRestgroupCharges': 'Total Restaurant Group Charges',
  'rg.report.totalRestgroupOtherCharges': 'Restaurant Group Other Charges',
  'rg.report.feeAndAdjustment': 'Fee & Adjustment',
  'rg.report.availableToPayoutAmount': 'Available To Payout Amount',
  'rg.report.invoice': 'Invoice',
  'rg.report.totalDebt': 'Total Debt',
  'rg.report.filter.restaurant': 'Filter by Restaurants, Multiple',
  'rg.report.date.tip': 'Sales Report and Available Payout can only be searched by day',
  'rg.report.sales.error': 'Sales Report Error',
  'rg.report.payout.error': 'Available Payout Error',

  'rg.accountSettiing.name': 'Restaurant Group Name',

  'rg.report.empty.restaurants': 'No restaurants data in this group',
  'rg.report.section.restaurantSales': 'Restaurant Sales',
  'rg.action.noMenu': 'You have no menu permission, please contact your administrator',
  'rg.action.actionEmpty': 'You have no permission to visit this page, please contact your administrator',

  'report.column.comps_value': 'Comps',
};
