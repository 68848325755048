export default {
  'printer.title': 'Printers',

  'printer.field.name': 'Printer Name',
  'printer.field.modelName': 'Device Model',
  'printer.field.displayName': 'Display Name',
  'printer.field.responsibility': 'Descriptions',
  'printer.field.purpose': 'Duty',
  'printer.field.language': 'Language',
  'printer.field.serviceType': 'Service Type',
  'printer.field.orderSource': 'Order Source',
  'printer.field.triggerToPrint': 'Trigger to Print',
  'printer.field.isOneDishOnePrint': 'Split ticket by dishes',
  'printer.field.splitKitchenTicket': 'Split kitchen ticket',
  'printer.field.splitPackingTicket': 'Split packing ticket',

  'printer.action.enable': 'Enable Printer',
  'printer.action.disable': 'Disable Printer',

  'printer.enablePrinter.promptTitle': 'Device Activation Prompt',
  'printer.enablePrinter.promptContent': 'Are You Sure to Enable This Printer ?',
  'printer.disablePrinter.promptTitle': 'Device Deactivation Prompt',
  'printer.disablePrinter.promptContent': 'Are You Sure to Disable This Printer ?',
};
