export default {
  'categories.title': 'Categories',
  'categories.edit.copy': 'Click on any category to edit',
  'categories.action.add': 'Create New Category',
  'categories.edit.title': 'Edit Category',

  'categories.section.generalInfo': 'General Information',
  'categories.column.name': 'Name',
  'categories.column.foreign_name': 'Foreign Name',
  'categories.column.tax_code': 'Tax Code',
  'categories.column.sales_category': 'Sales Category',
  'categories.column.description': 'Description',
  'categories.column.add': 'Add',
  'categories.column.category_name.required': 'Sales category is required',
  'categories.column.has_limited_availability': 'Has Limited Availability',
  'categories.column.has_limited_availability.tooltip':
    'When this option is on, the category is always available. Otherwise the category is available only during its associated category hours',
  'categories.column.name.required': 'Name is required',
  'categories.column.foreign_name.required': 'Foreign name is required',

  'categories.column.posName': 'POS Name',
  'categories.column.kitchenName': 'Kitchen Name',
  'categories.column.posName.placeholder': 'A short name shown on the POS menu',
  'categories.column.kitchenName.placeholder': 'A short name shown on kitchen tickets and labels',
  'categories.column.posName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the POS menu',
  'categories.column.kitchenName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the kitchen tickets and labels',

  'categories.column.is_point': 'Is Point Category',
  'categories.column.is_point.tooltip':
    'When this option is enabled, all meals under this category need to set point price',

  'categories.pointCategory.postfix': 'points',
};
