export default {
  'common.time.minutes': 'Minutes',
  'common.monday': 'Mon',
  'common.tuesday': 'Tue',
  'common.wednesday': 'Wed',
  'common.thursday': 'Thu',
  'common.friday': 'Fri',
  'common.saturday': 'Sat',
  'common.sunday': 'Sun',
  'common.sunday.fullFormat': 'Sunday',
  'common.monday.fullFormat': 'Monday',
  'common.all': 'All',
  'common.filter': 'Filter',
  'common.am': 'am',
  'common.pm': 'pm',
  'common.here': 'here',
  'pageConstruction.subtitle': 'Sorry, the page you visiting is constructing.',
  'page404.subtitle': 'Sorry, the page you visiting is not exist',
  'page403.subtitle': 'Sorry, you are not authorized to access this page.',
  'smart-ordering-disabled':
    'The QR code ordering is temporarily unavailable. Please reach out to our local team to open this feature.',
  'content403.subtitle': 'Sorry, you are not authorized to access this section.',
  'load-menu-failed': 'Sorry, load accessible menus Failed, please retry',
  'fetching-data': 'Loading data...',
  'saving-data': 'Saving data...',
  'loading-image': 'Loading image...',
  'access-declined':
    'No operation permission, if you want to be allowed to perform this operation, please contact the restaurant owner.',
  'operation-self-declined': 'You can not perform this operation on yourself',
  'reload-permissionCodes': 'Reload permission codes',
  'pagination.summary': '{start}-{end} of {total} items',
  'pageError.subtitle': "We're sorry - Something has gone wrong. Our team has been notified.",
  'notice.constructing': 'This function is constructing',
  'save-prompt-content-on-leave': 'Do you want to save changes before exiting?',
  'logout-prompt': 'Sure to logout ?',
  'toggle-on': 'Yes',
  'toggle-off': 'No',
  'restaurant-unactivated': 'You can not access this page until the restaurant is activated.',
  required: 'Required',
  existed: 'Existed',
  optional: 'Optional',
  requiredSelection: 'Required',
  paid: 'Paid',
  unpaid: 'Unpaid',
  'reload-preference': 'Load preference failed, click to retry',
  'common.field.startTime': 'Start time',
  'common.field.endTime': 'End time',
  'common.field.available': 'Enable',
  'common.field.action': 'Action',
  'common.field.date': 'Date',
  'common.originalSize': 'Original Size: ',
  'common.max': 'Max',
  'common.default': 'Default',
  'common.retryOnFailed': 'Load failed, click to retry',
  'reload-image': 'Load image failed, click to reload',
  'commom.previous': 'Previous',
  'commom.next': 'Next',
  'common.deleted': 'Deleted',
  'common.days': 'days',
  'common.hours': 'hours',
  'common.noData': 'No data',
  'common.expandAll': 'Expand All',
  'common.collapseAll': 'Collapse All',
  'common.pleaseEnter': 'Please Enter',
  'common.y': 'Y',
  'common.n': 'N',
  'common.from.less.than.to': 'From time must be less than To time',
  'common.to.more.than.from': 'To time must be more than From time.',
  'common.searchFor': 'Search for',
  'common.searchResult': '({count} matched items)',
  'common.required': 'required',
  'common.time.overlap': 'You have overlapping opening hours, please consider combining them.',
  'common.multiple': 'Multiple',
  'common.single': 'Single',
  'common.reload-message.default': 'Data loading failure, click to retry',
  'common.to.from.cannot.equal': 'The start time and end time cannot be the same',
};
