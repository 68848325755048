export default {
  'component.user.logout': 'Logout',
  'component.user.preferences': 'Preferences',
  'component.user.unactivated': 'Unactivated',
  'component.auth.title': 'Basic Authorization',
  'component.auth.username': 'Username',
  'component.auth.password': 'Password',
  'component.phoneInput.invalid': 'Invalid Phone Number',
  'component.passwordInput.title': 'Please Enter Password to Verify Your Account',
  'component.passwordInput.placeholder': 'Enter Password',
  'component.action.acknowledge': 'I Acknowledge',
  'component.action.colorpicker': 'Pick dish color',
  'component.employee.multiple': 'Filter by Employee, Multiple',
  'component.employee.single': 'Filter by Employee',
  'component.restaurant.filter': 'Filter by name/foreign_name',
  'component.multiple_order_source.filter': 'Filter by Order Source, Multiple',
  'component.platforms.filter': 'Filter by Platforms, Multiple',
  'component.multiple_service_type.filter': 'Filter by Service Type, Multiple',
  'component.loadRestaurantGroupFailed': 'Load restaurant list failed',
  'component.advancedSearch.title': 'Advanced Search',
  'component.advancedSearch.keyword': 'Keyword',
  'component.advancedSearch.type': 'Type',
  'component.advancedSearch.keyword.placeholder': 'Search by {fieldName}',
  'component.qrLinked': 'QR Linked',
  'component.reset_login_password': 'Reset login password',
};
