export default {
  'user.login.email': 'Email',
  'user.login.password': 'Password',
  'user.login.login': 'Sign In',
  'user.email.required': 'Please enter your email!',
  'user.email.wrong-format': 'The email address is in the wrong format!',
  'user.password.required': 'Please enter your password!',
  'user.email.placeholder': 'Email',
  'user.login.success': 'Login Success',
  'user.login.email-invalid': 'Unnormal email',
  'user.forget-password': 'Forget Password?',
  'user.activate.title': 'Restaurant Activation',
  'user.activate': 'Activate',
  'user.activate.success': 'Successfully Activate Restaurant',
  'user.gotoLogin': 'Go to Login',

  'user.login.inactive': 'The restaurant has not been activated yet',

  'user.mobile.required': 'Please enter mobile!',
  'user.mobile.wrong-format': 'The mobile is in the wrong format!',

  'user.login.identity': 'Login identity',
};
