export default {
  'monthlyStatement.title': 'Download Monthly Statement',
  'monthlyStatement.period': 'Statement Period',
  'monthlyStatement.service': 'Service Type',
  'monthlyStatement.column.month': 'Month',
  'monthlyStatement.column.action': 'View or Download',
  'monthlyStatement.generatingPDF': 'Generating PDF',
  'monthlyStatement.relatedContracts': 'Restaurant related contracts: ',
  'monthlyStatement.detecting': 'Detecting...',
  'monthlyStatement.reportUngenerated':
    'The monthly statement for the selected month has not been generated yet, please contact customer service.',
  'monthlyStatement.generated': 'Generated',
  'monthlyStatement.ungenerated': 'Ungenerated',
  'monthlyStatement4ClosedRestaurants.title': 'Download Monthly Statement For Closed Restaurants',
  'monthlyStatement4ClosedRestaurants.restaurantId': 'Restaurant ID',
  'monthlyStatement4ClosedRestaurants.restaurantId.placeholder': 'Restaurant id, press enter',
};
