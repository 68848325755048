export default {
  'resetPassword.title': 'Reset Password',
  'resetPassword.step.validateEmail': 'Validate Email',
  'resetPassword.step.resetPassword': 'Reset Password',
  'resetPassword.step.done': 'Done',
  'resetPassword.field.email': 'Email',
  'resetPassword.field.modifyEmail': 'Change email',
  'resetPassword.field.phoneNumber': 'Phone Number',
  'resetPassword.field.validateEmail': 'Validate',
  'resetPassword.field.password': 'New Password',
  'resetPassword.field.confirmationPassword': 'Confirmation Password',
  'resetPassword.field.code': 'Verify Code',
  'resetPassword.means.email': 'Verify by email',
  'resetPassword.means.sms': 'Verify by sms',
  'resetPassword.sendVerifyCode': 'Send verify code',
  'resetPassword.resendVerifyCode': 'Resend verify code',
  'resetPassword.countdown': '{count} seconds',
  'resetPassword.reset': 'Reset',
  'resetPassword.chooseVerifyMethod': 'Choose Verification Method',

  'resetPassword.sentVerifyCodeToEmail': 'Already send verify code to your email {email}',
  'resetPassword.sentVerifyCodeToPhone': 'Already send verify code to your phone',
  'resetPassword.field.email.required': 'Email is required',
  'resetPassword.field.password.required': 'Password is Required',
  'resetPassword.field.email.wrong-format': 'The email address is in the wrong format!',
  'resetPassword.field.confirmationPassword.required': 'Confirm password is required',
  'resetPassword.field.password.not-match': 'Passwords entered twice are inconsistent',
  'resetPassword.field.code.required': 'Verify code is required',
  'resetPassword.field.mobile.required': 'Mobile is required',
  'resetPassword.field.pin.empty': 'Verify code is required',
  'resetPassword.field.pin.lengthNotMatch': 'Verify Code Must Be Exactly {len} Numbers',

  'resetPassword.success': 'Successfully reset password',
  'resetPassword.gotoLogin': 'Goto Login',
};
