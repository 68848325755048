export default {
  'printSetting.title': 'Printer Mapping',
  'printSetting.noPrinters': 'No Printers',
  'printSetting.action.selectAll': 'Select All',
  'printSetting.action.revertAll': 'Deselect All',
  'printSetting.saveModal.title': 'Are you sure you want to save the following changes?',
  'printSetting.noDishes': 'All items are assigned to a printer',

  'printSetting.saveSuccess': 'success',
  'printSetting.saveFailed': 'failed',

  'printSetting.switchModal.title': 'Select printers to assign selected items',

  'printSetting.switch.title': 'Switch the following item(s) to',

  'printSetting.unAssignedDishes.title': 'become unassigned:',
};
