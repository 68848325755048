export default {
  'reportsSettings.sales.title': 'Reports Settings',
  'reportsSettings.general': 'General',
  'reportsSettings.service_period': 'Service Period',
  'reportsSettings.general.deferred_sales_enable':
    'Receivable Amount does not include the “Deferred Sales”（Deferred Sales Amount is displayed separately）.',
  'reportsSettings.service_period.add.title': `Add your “Service Period”`,
  'reportsSettings.service_period.add.column.name': 'Name',
  'reportsSettings.service_period.name.placeholder': `e.g. 'Lunch'`,
  'reportsSettings.service_period.add.column.foreign_name': 'Foreign Name',
  'reportsSettings.service_period.add.column.description': 'Description',
  'reportsSettings.service_period.define.title': `Define your “Service Period”`,
  'reportsSettings.service_period.define.column.schedule_name': 'Schedule Name',
  'reportsSettings.service_period.define.column.weekly_schedule': 'Weekly Schedule',
  'reportsSettings.service_period.define.column.service': 'Service',
  'reportsSettings.service_period.define.column.available': 'Available',
  'reportsSettings.service_period.define.column.hours': 'Hours',
  'reportsSettings.service_period.define.column.hours.tips':
    'The time period includes the start time but excludes the end time.',
  'reportsSettings.service_period.noScheduleName': 'no schedule name',
  'reportsSettings.service_period.noHoursSet': 'no hours set',
  'reportsSettings.service_period.schedul_name.placeholder': `e.g. 'Mon-Thurs', 'Sunday', or 'Holidays'`,
  'reportsSettings.service_period.confirm.delete': `Are you sure you want to delete it ? `,
  'reportsSettings.service_period.confirm.rules.one': `The time period coincides with the closeout report cutoff time`,
  'reportsSettings.service_period.confirm.rules.two': `{plan1} and {plan2} time periods overlap`,
  'reportsSettings.service_period.duplication': `There is duplication`,
  'reportsSettings.service_period.add.deleteTip': `The schedule below is currently using it and can't be  deleted !`,
  'reportsSettings.service_period.overnightHours': `overnight hours`,
  'reportsSettings.service_period.valid.tips': `You have modified the cut-off time. Please recheck your service time and save the changes.`,
  'reportsSettings.service_period.closeoutReportCutoffTime': 'Closeout Report Cutoff Time: （{cutoffTime}）',

  'reportsSettings.sales_section.add.title': `Add your “Sales Section”`,
  'reportsSettings.sales_section.name.placeholder': `e.g. 'Party Room,Bar'`,
  'reportsSettings.sales_section.define.title': `Define Your “Sales Section”`,
  'reportsSettings.sales_section.define.column.section_name': 'Section',
  'reportsSettings.sales_section.define.column.table': 'Table',
  'reportsSettings.sales_section.define.column.sales_section': 'Sales Section',
};
