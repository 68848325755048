export default {
  'home.get-demo.bitton': 'Get a demo',
  'home.pay-video.bitton': 'Play Video',
  'home.submit.button': 'Submit',
  'home.chowbuspos.title0': 'The',
  'home.chowbuspos.title1': ' ',
  'home.chowbuspos.title2': 'All-In-One',
  'home.chowbuspos.title3': ' Restaurant POS System ',
  'home.chowbuspos.desc':
    'Chowbus POS: the next generation POS that can save you $8,000/mo in labor costs and grow your business 25%',
  'home.ecosystem.title': 'The whole ecosystem to empower restaurants',
  'home.link.more': 'Learn more',
  'home.rightporduct.spec.needs.title': 'The right product for your specific needs',
  'home.whychoosechowbus.title': 'Why Choose Chowbus?',
  'home.get-demo.form.title': 'Schedule Your Free Demo',
  'home.get-demo.form.subtitle': `We’ll reach out within 24 hours to schedule your demo`,
  'home.ourclients.title': `Trusted by restaurants of all sizes and
   cuisines across the nation`,

  'email.landingpage.get-demo.bitton': 'TRY FOR FREE',
  'rutech.robots.visit.button': 'Visit RuTech Website',
  'pricing.best.value.desc': 'Best Value',
  'pricing.include.basic.package': 'Include all Basic Package',
  'pricing.include.basic.package.sub1': 'Cloud-based Point of Sale',
  'pricing.include.basic.package.sub2': 'Customer Support',

  'blog.readmore.button': 'Read More',
  'home.chowbuspos.ecosystem.banner.title': 'Grow your business with Chowbus POS',
  'home.chowbuspos.ecosystem.banner.points_of_sale': 'Point of Sale',
  'home.chowbuspos.ecosystem.banner.pos_handheld': 'POS Handheld',
  'home.chowbuspos.ecosystem.banner.tablet_ordering': 'Tablet Ordering',
  'home.chowbuspos.ecosystem.banner.qrcode_ordering': 'Qr Code Ordering',
  'home.chowbuspos.ecosystem.banner.kiosk': 'Kiosk',
  'home.chowbuspos.ecosystem.banner.loyalty_restaurant': 'Restaurant Loyalty',
  'home.chowbuspos.ecosystem.banner.online_ordering': 'Online Ordering',
  'home.chowbuspos.ecosystem.banner.third_party_integration': 'Third Party Integration',
  'home.chowbuspos.ecosystem.banner.wait_list': 'Waitlist',

  'home.referral.form.title': 'Referral Information',
  'home.referral.form.subtitle.0': `What’s YOUR Restaurant `,
  'home.referral.form.subtitle.1': `Full Name and Address*`,

  'home.referral.form.restaurant_origin': `Your Full Name and Contact Information`,
  'home.referral.form.restaurant.your.fullName': `Full Name*`,
  'home.referral.form.restaurant.your.contact': `Contact Information*`,
  'home.referral.form.restaurant_full_name': `Restaurant Full Name*`,
  'home.referral.form.restaurant_address': `Street, city, state, zip code*`,
  'home.referral.form.referring_restaurant_business_label': `What’s the business name of the restaurant you’re referring?`,
  'home.referral.form.referring_restaurant_business_name': `Business Name*`,
  'home.referral.form.referring_restaurant_address_label': `What’s the address of the restaurant you’re referring?`,
  'home.referral.form.referring_restaurant_address': `Street, city, state, zip code*`,
  'home.referral.form.referring_restaurant_maker_label': `What's the name of the decision-maker you're referring?`,
  'home.referral.form.referring_restaurant_maker_first_name': `First Name*`,
  'home.referral.form.referring_restaurant_maker_last_name': `Last Name*`,
  'home.referral.form.referring_restaurant_email_label': `What's the business email of that decision-maker you’re referring?`,
  'home.referral.form.referring_restaurant_email': `example@example.com*`,
  'home.referral.form.referring_restaurant_mobile_label': `What's the business cell phone number of the decision-maker you’re referring?`,
  'home.referral.form.referring_restaurant_mobile': `Cell Phone*`,
  'home.referral.form.referring_restaurant_chowbus_sales_label': `If you’ve worked with a specific Sales Representative or Account Manager at Chowbus, what is their first name and last name?`,
  'home.referral.form.referring_restaurant_chowbus_sales_first_name': `First Name`,
  'home.referral.form.referring_restaurant_chowbus_sales_last_name': `Last Name`,
  'home.referral.form.referring_restaurant_privacy_confirm_text': `By submitting this referral, you confirm that you have the consent of the recipient for the sending and receipt of communications (including emails and telephone calls) to the above contact information in connection with this referral program.*`,

  'home.referral.form.referring_restaurant_email_1': `Email*`,
  'home.referral.form.referring_restaurant_business_name_1': `Restaurant Name*`,
  'home.referral.form.restaurant_zip_1': `Zip Code*`,
  'home.referral.form.hearaboutchowbus': `How did you hear about Chowbus?`,

  'home.pricingpage.get-demo.bitton': 'Book Your Chowbus POS Demo',
  // 'home.book.pos.demo.button': `Book Your Chowbus POS Demo`,
  'home.book.pos.demo.button': `Get a Free Chowbus Demo`,
  'top.products.business.book.pos.demo.button': `Contact Us Now`,
  'get-a-demo-from-privacy': `By requesting a demo, you agree to receive automated text messages from Chowbus`,
  'top.products.business.book.pos.demo.title': `Schedule Your Free Demo`,

  'home.why.chowbus.pos.showmorefeature.button': `Show more features`,
  'home.why.chowbus.pos.showlessfeature.button': `Show less features`,

  'home.blog.readmore.button': `Read More`,
  'home.blog.viewmore.button': `View More`,
  'home.blog.othersarticle': 'Other Articles',
  'home.blog.back': 'Back',
  'home.blog.backbloghome': 'Back to Blog Home',

  'home.chowbuspos.seechowbusworks': 'See How Chowbus Works',
  'home.chowbuspos.slogan': 'The All-In-One Restaurant POS System',
  'home.chowbuspos.trusted_by_top_restaurants': 'Trusted by top restaurants in the US',
  'home.chowbuspos.why_choose_chowbus': 'Why Restaurants Choose Chowbus',

  'home.book.pos.referral.button': `Earn Your $500 Bonus Today`,

  'home.referral.form.referral_person_name': `Your Name*`,
  'home.referral.form.referral_restaurant_name': `Your Restaurant Name*`,
  'home.referral.form.referral_mobile': `Your Phone Number*`,
  'home.referral.form.referral_restaurant_sale': `Chowbus Sales Manager Name(if any)`,
  'home.referral.form.recommend_restaurant_name': `Recommended Restaurant Name*`,
  'home.referral.form.recommend_restaurant_location': `Recommended Restaurant Location (City, State)*`,
  'home.referral.form.recommend_restaurant_contract_person_name': `Recommended Restaurant Contact Person*`,
  'home.referral.form.recommend_restaurant_contract_person_mobile': `Recommended Restaurant Contact Mode (Phone Number)*`,
  'home.referral.form.field.required': 'required',
};
