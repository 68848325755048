export default {
  'payout.title': 'Transaction Fee Report',
  'payout.summary.totalSubmittedCharges': 'Total Submitted Charges',
  'payout.summary.totalSettledCharges.description':
    'Settled charges may not equal to submitted charges given that some charges are settled later and refunds are processed after settlement.',
  'payout.summary.totalSettledCharges': 'Total Settled Charges',
  'payout.summary.totalTransaction': 'Total Transaction',
  'payout.summary.totalTransactionsSettled': 'Total Settled Transactions',
  'payout.summary.totalFees': 'Total Transaction Fees',
  'payout.section.settledCharges.title': 'Settled Charges',
  'payout.section.totalChanges.title': 'Total Charges',
  'payout.section.settledCharges.summary': 'Total Charges',
  'payout.section.transactionsSettled.title': 'Settled Transactions',
  'payout.section.transactionsSettled.summary': 'Total Transactions',
  'payout.section.feeTypes.title': 'Fee Types',
  'payout.section.feeTypes.totalFees': 'Total Fees',
  'payout.section.feeTypes.networkFees': 'Network Fees',
  'payout.section.transactionFee': 'Transaction Fee',
  'payout.section.payoutByDate': 'Payout by Date',
  'payout.section.payoutAdjustment': 'Payout Adjustment',
  'payout.section.payoutTransaction': 'Rebate',
  'payout.column.date': 'Order Date',
  'payout.column.payout_range': 'Payout Range',
  'payout.column.payout_date': 'Payout Date',
  'payout.column.payment_count': 'Transaction Submitted',
  'payout.column.payment_amount': 'Total Amount By Card',
  'payout.column.refund': 'Chargesback / Reversals',
  'payout.column.adjustment': 'Adjustment',
  'payout.column.adjustment_tip': 'Adjustment Tips',
  'payout.column.transaction_fee': 'Transaction Fees',
  'payout.column.transaction_fee.description': 'This Fee only works for Fix Rate Model.',
  'payout.column.amount': 'Net Payout',
  'payout.column.state': 'Payout Status',
  'payout.transaction.order_date': 'Transaction Date',
  'payout.transaction.charge_date': 'Charge Date',
  'payout.transaction.network_and_gateway_fees': 'Network & Gateway Fees',
  'payout.transaction.chowbus_processing_fee': 'Chowbus Processing Fee',
  'payout.transaction.chowbus_processing_fee.tooltip':
    'Chowbus Processing Fee is computed as the credit card processing fees charged by Chowbus minus any processing rebate.',
  'payout.transaction.transaction_fee': 'Transaction Fee',
  'payout.adjustment.chargeDate': 'Charge Date',
  'payout.adjustment.date': 'Date',
  'payout.adjustment.itemName': 'Item Name',
  'payout.adjustment.amount': 'Amount',
  'payout.adjustment.notes': 'Notes',
};
