export default {
  'appConfig.title': 'App Configuration',

  'appConfig.field.name': 'Name',
  'appConfig.field.keys': 'Keys',
  'appConfig.field.fieldName': 'Field Name',
  'appConfig.field.fieldValue': 'Field Value',

  'appConfig.action.addField': 'Add Field',

  'appConfig.name.isRequired': 'Name is Required',
  'appConfig.fieldName.isRequired': 'Field Name is Required',
  'appConfig.fieldValue.isRequired': 'Field Value is Required',

  'appConfig.deletion.promptTitle': 'App Config Deletion Prompt',
  'appConfig.deletion.promptContent': 'Are You Sure to Delete This App Config',

  'appConfig.update.success': 'Successfully updated app config',
};
