export default {
  'menu.availability.title': 'Availability',

  'menu.availability.placeholder.serviceType': 'Please select menu',
  'menu.availability.placeholder.category': 'Please select sub-menu/category, multiple',
  'menu.availability.placeholder.name': 'Filter on dish name',

  'menu.availability.column.image': 'Image',
  'menu.availability.column.name': 'Dish Name',
  'menu.availability.column.foreign_name': 'Foreign Name',
  'menu.availability.column.category': 'Associated Category',
  'menu.availability.column.status': 'Status',
  'menu.availability.column.createdAt': 'Date Added',
  'menu.availability.column.quota': 'Quota',

  'menu.availability.selectedItems': 'Selected {count} items',

  'menu.availability.column.status.soldOut': 'SOLD OUT',
  'menu.availability.column.status.available': 'Available',

  'menu.availability.soldOutItems.title': 'SOLD OUT DISHES  {count} items',
  'menu.availability.availableItems.title': 'AVAILABLE DISHES  {count} items',

  'menu.availability.action.markAsAvailable': 'Mark as Available',
  'menu.availability.action.markAsSoldOut': 'Mark as Sold Out',
  'menu.availability.action.editQuota': 'Edit Quota',
  'menu.availability.timeToBeAvailable': 'Available again {timeDesc}',

  'menu.availability.markAsAvailable.title':
    'Sure to change this dish(es) status to available. This dish(es) will be made available on your menu immediately',
  'menu.availability.markAsAvailable.success': 'Mark selected dish(es) as available successfully',
  'menu.availability.markAsAvailable.failed': 'Mark selected dish(es) as available unsuccessfully',
  'menu.availability.markAsSoldOut.success': 'Mark selected dish(es) as sold out successfully',
  'menu.availability.markAsSoldOut.failed': 'Mark selected dish(es) as sold out unsuccessfully',
  'menu.availability.quotaEditSuccess': 'Update quota successfully',

  'menu.availability.placeholder.searchCustomizations': 'Search Customizations',
  'menu.availability.soldOutOptions.title': 'Sold Out Options',

  'menu.availability.customizations.mixedOptions': 'please select options with the same status',

  'menu.availability.editDishQuota.title': 'Edit Dish Quota',
  'menu.availability.bulkEditDishQuota.title': 'Bulk Edit Dish Quota',
  'menu.availability.customizations.editDishQuota.title': 'Edit Customization Option Quota',

  'menu.transaction.report.title': 'This report only works for cost plus',
};
