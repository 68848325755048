export default {
  'priceEditor.title': 'Dishes',

  'priceEditor.column.category': 'Category',
  'priceEditor.column.name': 'Name',
  'priceEditor.column.foreign_name': 'Foreign Name',
  'priceEditor.column.price': 'Base Price',
  'priceEditor.column.member_price': 'Member Price',
  'priceEditor.column.original_price': 'Original Price',
  'priceEditor.column.new_price': 'New Price',
  'priceEditor.column.new_member_price': 'New Member Price',
  'priceEditor.column.updated_at': 'Time Updated',
  'priceEditor.column.note': 'Note',
  'priceEditor.column.price.tooltip':
    'Chowbus limits the number of price updates per week. Please reach out to your Account Manager if you have trouble updating pricing.',

  'priceEditor.drawer.title': 'Pricing Variations',
  'priceEditor.limit.underPromotion': 'Item is currently under promotion',
  'priceEditor.limit.reachLimit': "You've reached the maximum number of price updates during current period.",
  'priceEditor.limit.blocked': 'Currently price updating is forbidden',
  'priceEditor.limit.setupLimitationFirst': 'Currently price updating is forbidden, please setup limitation first',

  'priceEditor.price.edited': 'Edited',
  'priceEditor.pending': 'Price is under review',
  'priceEditor.isOpenPriced': 'Open price',
  'priceEditor.weightUnitDesc': 'Per {unit}',
  'priceEditor.drawer.price.required': 'Price is required',
  'priceEditor.updatePrompt': 'Would you like to save the edits?',
  'priceEditor.updateSuccess.tip': '{count} dishes have been updated successfully',
  'priceEditor.drawer.pointprice.required': 'Point Price is required',
  'priceEditor.pointprice.update.error.tip': 'Point Price is required',

  'priceEditor.noChanged': 'Nothing changed',
  'priceEditor.updatedThisWeek': 'Updated this week',
  'priceEditor.itemCount': '{count} items',

  'priceEdit.action.addDish': 'Create New',
  'priceEdit.action.copyDish': 'Copy a Dish',
  'priceEdit.action.updateMultiple': 'Update Multiple',
  'priceEdit.action.updatePrice': 'Regular Pricing',
  'priceEdit.action.updateMemberPrice': 'Member Pricing',
  'priceEdit.action.showMemberPrice': 'Show Member Pricing',
  'priceEdit.action.hideMemberPrice': 'Hide Member Pricing',
  'priceEdit.action.showPointPrice': 'Show Point Pricing',
  'priceEdit.action.hidePointPrice': 'Hide Point Pricing',

  'priceEdit.noRecord': 'No matched record',

  'batchPriceEdit.title': 'Update Prices',
  'batchMemberPriceEdit.title': 'Update Member Prices',
  'batchPriceEdit.typeOfChange': 'Type of Change',
  'batchPriceEdit.increase_percentage': 'Increase %',
  'batchPriceEdit.decrease_percentage': 'Decrease %',
  'batchPriceEdit.increase_dollar': 'Increase $',
  'batchPriceEdit.decrease_dollar': 'Decrease $',
  'batchPriceEdit.operation_guidance':
    'Select dishes within the menu to be updated. Click “Preview” to see new pricing and/or “Apply” to save the price updates.',
  'batchMemberPriceEdit.operation_guidance':
    'Select dishes within the menu to be updated. Click “Preview” to see new member price and/or “Apply” to save the member price updates.',
  'batchPriceEdit.guidance': 'This will {actionType} the price of {dishCount} dishes selected by {amount}.',
  'batchMemberPriceEdit.guidance':
    'This will {actionType} the member price of {dishCount} dishes selected by {amount}.',
  'batchPriceEdit.selectedSummary': '{dishCount} Items Selected',
  'batchPriceEdit.amount': 'Amount',
  'batchPriceEdit.action.preview': 'Preview',
  'batchPriceEdit.action.applyChange': 'Apply to {dishCount} Dishes',
  'batchPriceEdit.search.placeholder': 'Search Dish',
  'batchPriceEdit.number.error': '[error] Please enter a number lower than {limit}',
  'bulkTaxExempt.title': 'Tax Settings',
  'bulkTaxExempt.guidance': 'Select dishes within the menu to be updated. Click “Apply” to save changes.',
  'bulkTaxExempt.description': 'Mark selected dish as non-taxable',
  'bulkDiscountExempt.title': 'Discount Settings',
  'bulkDiscountExempt.description': 'Exclude selected dish from order discount calculation',
  discountExemptTips: '*Already excluded from discount calculation',
  taxExemptTips: '*Already marked as non-taxable',

  'dishes.delete.title': 'Delete Dish',
  'dishes.delete.copy':
    'This action will <i>permanently</i> delete this dish from all attached menu. If you simply want to hide the dish on a menu, please use Menu Setup under Menu Management.',
  'dishes.delete.prompt': 'Sure to delete this item?',
  'dishes.delete.confirm': 'Confirm Delete',
  'dishes.delete.succeed': 'Successfully delete {count} dishes',

  'priceEditor.column.point_price': 'Point Price',
};
