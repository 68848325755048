export default {
  'cashStatement.title': 'Cash Drawer Reports',
  'cashStatement.copy':
    'Cash drawer reports provides you the summary of the cash drawer activities at your restaurant.',
  'cashStatement.timeRange.title': 'Time Range',
  'cashStatement.device.title': 'Device',
  'cashStatement.timeRange.today': 'Today',
  'cashStatement.timeRange.yesterday': 'Yesterday',
  'cashStatement.timeRange.thisWeek': 'Current Week',
  'cashStatement.timeRange.lastWeek': 'Last Week',
  'cashStatement.timeRange.thisMonth': 'Current Month',
  'cashStatement.timeRange.lastMonth': 'Last Month',
  'cashStatement.section.summary': 'Cash Drawer Summary',
  'cashStatement.section.activity': 'Cash Drawer Activities',
  'cashStatement.column.session_start_time': 'Session Start Time',
  'cashStatement.column.session_end_time': 'Session End Time',
  'cashStatement.column.session_id': 'Session ID',
  'cashStatement.column.device_name': 'Device Name',
  'cashStatement.column.start_balance': 'Starting Balance',
  'cashStatement.column.cash_sales': 'Cash Sales',
  'cashStatement.column.cash_tips': 'Cash Tips',
  'cashStatement.column.miscellaneous_cash': 'Miscellaneous Cash',
  'cashStatement.column.other_cash_in': 'Other Cash In',
  'cashStatement.column.cash_refund': 'Cash Refund',
  'cashStatement.column.safe_drop': 'Safe Drop',
  'cashStatement.column.cash_in': 'Cash In',
  'cashStatement.column.cash_out': 'Cash Out',
  'cashStatement.column.employee_reimbursement': 'Employee Reimbursement',
  'cashStatement.column.tips_payout': 'Tips Payout',
  'cashStatement.column.other_cash_out': 'Other Cash Out',
  'cashStatement.column.estimated_ending_balance': 'Estimated Ending Balance',
  'cashStatement.column.total_cash_in': 'Total Cash In',
  'cashStatement.column.total_cash_out': 'Total Cash Out',
  'cashStatement.column.total_cash_in.tooltip':
    'Total Cash In includes Cash Sales, Cash Tips, Miscellaneous Cash, and Other Cash In',
  'cashStatement.column.total_cash_out.tooltip':
    'Total Cash Out includes Cash Refund, Safe Drop, Employee Reimbursement, Tips Payout, and Other Cash Out.',
  'cashStatement.column.time': 'Time',
  'cashStatement.column.action': 'Action',
  'cashStatement.column.amount': 'Amount',
  'cashStatement.column.employee_name': 'Staff',
  'cashStatement.column.reason': 'Reason',
};
