export default {
  'settings.onlinePlatforms': 'Online platforms',
  'settings.update.success': 'Successfully Update',
  'settings.platforms': 'Online Platforms',
  'setting.column.restaurant': 'Restaurant',
  'setting.column.fieldName': 'Field Name',
  'setting.column.fieldType': 'Field Type',
  'setting.column.fieldValue': 'Field Value',
  'setting.column.fieldName.placeholder': 'Field Name Path，separate by “/”',
  'setting.column.restaurant.required': 'Restaurant is required',
  'setting.column.fieldName.required': 'Field name is required',
  'setting.column.fieldType.required': 'Field type is required',
  'setting.column.fieldValue.required': 'Field value is required',
  'setting.update.success': 'Batch update preferences successfully',
  'setting.LoyaltyQRCode.action.downloadStand': 'Download Stand QR Code',
  'setting.LoyaltyQRCode.action.downloadPoster': 'Download Poster QR Code',
  'setting.Deliverect.title': 'Setup 3rd Party Delivery Platform',
  'setting.Deliverect.description': `
    <p>
      We partner with Deliverect which provides an all-in-one integration solution with 3rd party online platforms.
    </p>
    <p>
      To receive the 3rd party online orders on your Chowbus POS, please use the <i class="bold">Deliverect</i> Portal below to set up the menu, edit prices, and manage business hours for different platforms. However, you still need to use Chowbus POS dashboard to create dishes and our system will sync this information with Deliverect.
    </p>
  `,

  'setting.onlineOrderingWebsite.title': 'Setup Online Ordering Website',
  'setting.onlineOrderingWebsite.scheduledOrder.switch.label': 'Enable Scheduled Orders',

  'setting.onlineOrderingWebsite.enableCustomizationLimitProperties': 'Apply customization limit properties',
  'setting.onlineOrderingWebsite.enableCustomizationLimitProperties.description':
    'Customization will be validated and limited by maximum selections, maximum quantities, and maximum quantities per option. ',

  'setting.onlineOrderingWebsite.enableShowDishRatings': 'Display dish ratings',
  'setting.onlineOrderingWebsite.enableShowGeneratedCategories': 'Display Chowbus generated categories',
  'setting.onlineOrderingWebsite.enableShowGeneratedCategories.description': 'Including New and Popular',
  'setting.onlineOrderingWebsite.enableDisplayDishForeignName': 'Display dish foreign names',
  'setting.onlineOrderingWebsite.enableDisplaySoldOutDishes': 'Display sold out dishes and customizations',
  'setting.onlineOrderingWebsite.enableSortDishesDynamicallyBySales': 'Sort dishes dynamically by sales',

  'setting.deliveryConfig.title': 'Self Delivery',
  'setting.deliveryConfig.description':
    'The following platforms use self-delivery. Once selected, you can assign your own drivers for orders from these platforms on the POS.',
  'setting.deliveryConfig.assignDriver.switch.label':
    "When manually finishing an order, trigger a pop-up to assign a driver upon clicking 'Finish'.",

  'setting.shoppingCart.send.sendToKitchen.operation': 'Send to kitchen once click on the Pay button',
  'setting.shoppingCart.send.sendToKitchen.operation.description':
    '（Set based on the service types. Once turned off,  system will support pay first, when the order is fully paid, it will automatically send it to the kitchen.）',
  'setting.shoppingCart.send.confirm.tips':
    'Are you sure to turn off Send to kitchen once click on the Pay button for {serviceType}?',
  'setting.shoppingCart.send.confirm.description':
    'Once turned off,  system will support pay first, when the order is fully paid, it will automatically send it to the kitchen, or when the order is partially paid, you can manually send to kitchen in the shopping cart.',
};
