export default {
  'discount.title': 'Discounts',

  'discount.field.name': 'Name',
  'discount.field.type': 'Type',
  'discount.field.foreign_name': 'Foreign Name',
  'discount.field.discount_type': 'Discount Type',
  'discount.field.discount': 'Discount',
  'discount.field.creator': 'Creator',
  'discount.field.note': 'Note',
  'discount.field.associatedRoles': 'Associated Roles',
  'discount.field.operation': 'Action',

  'discount.name.isRequired': 'Name is Required',
  'discount.type.isRequired': 'Type is Required',
  'discount.foreign_name.isRequired': 'Foreign Name is Required',
  'discount.discount_type.isRequired': 'Discount Type is Required',
  'discount.discount.isRequired': 'Discount is Required',

  'discount.edit.title': 'Edit Discount',
  'discount.create.title': 'Create Discount',

  'discount.deletion.promptContent': 'Sure to delete the discount?',
};
