export default {
  'waitlistSettings.title': 'Waitlist',
  'waitlistSettings.section.enable.google.waitlist': 'Google waitlist',
  'waitlistSettings.section.enable.google.waitlist.desc': 'Will take effect from 5am to 7am the next day',
  'waitlistSettings.section.businesshour': 'Business hours for online waitlist',
  'waitlistSettings.section.tablequeue': 'Table setting',
  'waitlistSettings.section.businesshour.lunch': 'Lunch Time',
  'waitlistSettings.section.businesshour.dinner': 'Dinner Time',
  'waitlistSettings.section.businesshour.lunch.error': 'please set launch time range for waitlist',
  'waitlistSettings.section.businesshour.dinner.error': 'please set dinner time range for waitlist',
  'waitlistSettings.section.businesshour.all.error': 'please set launch/dinner time range for waitlist',
  'waitlistSettings.section.businesshour.all.overlap.error': 'The lunch/dinner time ranges are overlapped',
  'waitlistSettings.section.tableQueue.action.add': 'Add new',
  'waitlistSettings.section.tableQueue.field.capacity': 'Table size',
  'waitlistSettings.section.tableQueue.field.tablename': 'Queue name',
  'waitlistSettings.section.tableQueue.field.tableforeignName': 'Queue foreign name',
  'waitlistSettings.section.tableQueue.field.queuePrefix': 'Queue Prefix',
  'waitlistSettings.section.tableQueue.field.queue.estimateTime': 'Est.dining time',
  'waitlistSettings.section.tableQueue.field.queue.estimateTime.suffix': 'mins',
  'waitlistSettings.section.tableQueue.field.note': 'Notes',
  'waitlistSettings.section.tableQueue.field.note.foreign': 'Notes - foreign',
  'waitlistSettings.section.tableQueue.field.note.foreign.limit': 'Up to 65 characters',
  'waitlistSettings.section.tableQueue.field.note.limit': 'Up to 150 characters',

  'waitlistSettings.section.tableQueue.field.tablename.required': 'Table name is required',
  'waitlistSettings.section.tableQueue.field.tableforeignName.required': 'Table foreign name is required',
  'waitlistSettings.section.tableQueue.field.queuePrefix.required': 'Queue Prefix is required',
  'waitlistSettings.section.tableQueue.field.queue.estimateTime.required': 'Est.dining time is required',
  'waitlistSettings.section.tableQueue.field.capacity.min.required': 'Minimum Seating Capacity is required',
  'waitlistSettings.section.tableQueue.field.capacity.max.required': 'Maximum Seating Capacity is required',
  'waitlistSettings.section.tableQueue.field.capacity.range.error':
    'Minimum Seating Capacity must less than Maximum Seating Capacity',

  'waitlistSettings.section.tableQueue.field.note.placeholder': 'Please entry the notes',
  'waitlistSettings.section.tableQueue.field.note.foreign.placeholder': 'Please entry the foreign notes',
  'waitlistSettings.section.tableQueue.table.name.duplicated.error': 'There are duplicate table names:{tableNames}',
  'waitlistSettings.section.tableQueue.table.foreignName.duplicated.error':
    'There are duplicate table foreign names:{tableForeignNames}',
  'waitlistSettings.section.tableQueue.prefix.duplicated.error': 'There are duplicate queue prefix:{duplicatePrefix}',
  'waitlistSettings.section.tableQueue.table.capacity.overlap.error':
    'There are overlap table capacity:{overlapCapacity}',

  'waitlistSettings.section.table.seating': 'Restaurant Seat preference',
  'waitlistSettings.section.table.seating.notneed.selectTable':
    'Only mark customer as seated (default, no need to select a table)',
  'waitlistSettings.section.table.seating.need.selectTable':
    'Select  table and seat customer (only record the table number, won’t open the table)',

  'waitlistSettings.enable.google.error': 'Please set the table type that allows online queuing first',
  'waitlistSettings.enable.google.error.igot.button': 'Got it',
};
