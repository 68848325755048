export default {
  'quickNotes.title': 'Quick Notes',
  'quickNotes.column.display': 'Display',
  'quickNotes.column.name': 'Name',
  'quickNotes.column.foreign_name': 'Foreign Name',

  'quickNotes.create.title': 'New Quick Note',
  'quickNotes.edit.title': 'Edit Quick Note',

  'quickNotes.name.required': 'Name is required',
  'quickNotes.foreign_name.required': 'Foreign Name is required',

  'quickNotes.deletePromotion': 'Sure to delete this quick notes?',
};
