export default {
  'tipsAllocation.title': 'Tips Management',
  'tipsAllocation.setting': 'Tips Setting',
  'tipsAllocation.policies': 'Policies',
  'tipsAllocation.column.name': 'Policy Name',
  'tipsAllocation.column.service_type': 'Service Type',
  'tipsAllocation.column.order_sources': 'Order Source(s)',
  'tipsAllocation.column.payment_types': 'Payment Method(s)',
  'tipsAllocation.column.tips_withholding': 'Tips Withholding',
  'tipsAllocation.column.active': 'Active',
  'tipsAllocation.description1':
    'To decrease calculation inaccuracies, you can only have one active policy per service type.',
  'tipsAllocation.description2':
    'You may need to create floating staff and floating roles to handle special restaurant scenarios where there are mixed tipping rules.',
  'tipsAllocation.multipleTippingRules': 'Multiple tipping rules.',
  'tipsAllocation.seeExample': 'See example',
  'tipsAllocation.mixedRule': 'Mix of private and shared tips.',
  'tipsAllocation.suggestion1': 'You would do this the same way you create Staff & Roles under "Roles & Members"',
  'tipsAllocation.description3':
    'If you Enable House Cash, calculations will be printed on the In-House Daily Closeout Report under "Employee Account" section.',
  'tipsAllocation.description4':
    'If you have an active Dine In tip policy, calculations will be printed on the In-House Daily Closeout Report under "Tips Pool" section.',
  'tipsAllocation.description5': 'Tips Pool section of the Closeout Report reflects 2 aspects of Tips Allocation:',
  'tipsAllocation.rule.basis1':
    'All active tip policies based on the role of the staff printing out the Closeout Report',
  'tipsAllocation.rule.basis2': 'Whether tips are combined by role',
  'tipsAllocation.multipleTippingRules.example': `
    <div class="bold title">Multiple tipping rules</div>
    <p class="bold">
      Scenario: Restaurant has different tipping rules depending on whether it is a regular table or hibachi table. Hibachi chefs do not open orders, only servers do
    </p>
    <p class="bold">
      Our POS can keep track of tips by table type when you create and use a <span className="bold">floating employee.</span>
    </p>
    <p>
      <span class="bold">Floating employee:</span> Orders where tips are for hibachi tables will be associated to a floating employee. This way we can identify which tips are for regular table types, and which tips are for hibachi tables.
    </p>
    <p>
      Make sure you create rules associated with these floating roles in your Tip Policies to allocate tips, and our system will handle all the calculations.
    </p>
  `,
  'tipsAllocation.mixedTipsRule.example': `
    <div class="bold title">Mix of private and shared tips</div>
    <p class="bold">
      Scenario: Servers maintain individual tips for tables that they handle. When there are special events at the restaurant, multiple servers work together and will share tips with each other after tipping out.
    </p>
    <p class="bold">
      Our POS can keep track which tips are private and which tips are shared when you create a <span className="bold">floating employee</span> to handle orders from special events.
    </p>
    <p>
      <span class="bold">Floating employee:</span> Create a floating employee to associate with special events where tips are shared. This way servers still maintain their private tips but also get a portion of the shared tips.
    </p>
    <p>
      Make sure you create rules associated with these floating roles in your Tip Policies to allocate tips, and our system will handle all the calculations.
    </p>
  `,
  'tipsAllocation.combinedByRole.example': `
    <div class="bold title">Close Out Report</div>
    <p class="bold">
      For example: If Tip Policies are configured to be combined by role, and Server A prints out the In House closeout report, then the Tips Pool section will reflect all tipping policies for servers that are considered In House.
    </p>
  `,
  'tipsAllocation.copy.rules': 'Tip Policy Rules',
  'tipsAllocation.rules.description': `
    <p>
      Tips are calculated based on <span class="bold">percentage</span> for the time being. We are working to add more variations of tips allocation.
    </p>
    <p>
      <span class="bold">Contributors:</span> roles that will add to the tip pool.
    </p>
    <p>
      <span class="bold">Recipients:</span> roles that will receive from to the tip pool.
    </p>
  `,
  'tipsAllocation.contributor.description': 'roles that will add to the tip pool',
  'tipsAllocation.recipients.description': 'roles that will receive from to the tip pool',
  'tipsAllocation.copy.willTipOut': 'will tip out',
  'tipsAllocation.copy.to': 'to',
  'tipsAllocation.rule.contributor': 'Contributor',
  'tipsAllocation.rule.percentage': '% of distribution',
  'tipsAllocation.rule.tips': 'Tips',
  'tipsAllocation.rule.categories': 'Categories',
  'tipsAllocation.rule.recipients': 'Recipients',
  'tipsAllocation.add.title': 'Create Policy',
  'tipsAllocation.edit.title': 'Edit Policy',
  'tipsAllocation.copy.withhold': 'Percentage to withhold to cover credit card processing fee',
  'tipsAllocation.withhold.description':
    "The fee will only be taken from contributor's tips, after distribution to the recipients.",
  'tipsAllocation.copy.isIndividual':
    'Are contributors sharing their tips together by role or maintaining individual tips?',
  'tipsAllocation.allocation.combine': 'Combine',
  'tipsAllocation.allocation.individual': 'Individual',
  'tipsAllocation.allocation.description': `
    Tip pool is based on all contributing staff of the <i>same</i> role, who worked on the <i>same</i> day.
  `,

  'tipsAllocation.setting.enable': 'Tips Allocation using POS',
  'tipsAllocation.setting.calculationMethod': 'Calculation Method',
  'tipsAllocation.setting.combineOrIndividualTips': 'Combine or Individual Tips',
  'tipsAllocation.setting.withholding': 'Withholding',
  'tipsAllocation.setting.withholdingTiming': 'Withholding Timing',
  'tipsAllocation.withholdingTiming.beforeTipOut': 'Before tip out',
  'tipsAllocation.withholdingTiming.afterTipOut': 'After tip out',
  'tipsAllocation.setting.description':
    'Configure tip settings before creating tip policies. These settings will apply to all active tip policies.',
  'tipsAllocation.setting.enable.label': 'Will POS handle your tipping allocation and calculations?',
  'tipsAllocation.setting.combineOrIndividualTips.label':
    'Are contributors sharing their tips together by role or maintaining individual tips?',
  'tipsAllocation.setting.calculationMethod.label': 'How are tips calculated?',
  'tipsAllocation.calculationBase.sales': 'Percentage of Sales',
  'tipsAllocation.calculationBase.tipping': 'Percentage of Tips',
  'tipsAllocation.setting.withhold.label': 'Percentage to withhold to cover credit card processing fee',
  'tipsAllocation.setting.withholdTiming.label': 'Tips Withholding Timing',
  'tipsAllocation.setting.enableHouseCash': 'Enable House Cash',
  'tipsAllocation.enableHouseCash.description': `House Cash = <span class="bold">Cash Sales + Cash Tips - (cash tips + card tips + other tips) + Card Tips Withholding</span>`,
  'tipsAllocation.withholdingTiming.beforeTipOut.description':
    'Before tip out (taken before distributing to the recipients)',
  'tipsAllocation.withholdingTiming.afterTipOut.description':
    'After tip out (taken after distributing to the recipients)',
  'tipsAllocation.cashTips': 'Cash Tips',
  'tipsAllocation.cardTips': 'Credit Card Tips',
  'tipsAllocation.cashAndCardTips': 'Cash & Credit Card Tips',
  'tipsAllocation.action.add': 'Create Policy',
  'tipsAllocation.createSuccess': 'Successfully created policy',
  'tipsAllocation.updateSuccess': 'Successfully updated policy',
  'tipsAllocation.deleteSuccess': 'Successfully deleted policy',
  'tipsAllocation.updateSettingsSuccess': 'Successfully updated settings',
  'tipsAllocation.requiredTipAllocationEnabled': 'Please turn on ‘Tips Allocation using POS’ before creating policy',
  'tipsAllocation.activatePrompt': 'Sure to activate this policy?',
  'tipsAllocation.deactivatePrompt': 'Sure to deactivate this policy?',
  'tipsAllocation.deletePrompt': 'Sure to delete this policy?',

  'tipsAllocation.tipSheet.title': 'Tip Sheet Detail',
  'tipsAllocation.tipSheet.section.basicInfo': 'Basic Information',
  'tipsAllocation.tipSheet.section.tipPool': 'Tip Pool',
  'tipsAllocation.tipSheet.section.tipOutDetail': 'Tip Out Detail',
  'tipsAllocation.tipSheet.section.tipsWithholding': 'Tips Withholding',
  'tipsAllocation.tipSheet.section.activity': 'Activity',
  'tipsAllocation.column.date': 'Date',
  'tipsAllocation.column.contributors': 'Contributors',
  'tipsAllocation.column.recipients': 'Recipients',
  'tipsAllocation.column.total_tips': 'Tips',
  'tipsAllocation.column.tips_withhold': 'Withheld',
  'tipsAllocation.column.tip_out': 'Tip Out',
  'tipsAllocation.column.take_home_tips': 'Take Home Tips',
  'tipsAllocation.column.recipient_name': 'Recipient',
  'tipsAllocation.column.recipient_tips': 'Tips Received',
  'tipsAllocation.column.note': 'Notes',
  'tipsAllocation.column.status': 'Status',
  'tipsAllocation.column.tipsWithholdingAfterTipOut': 'Tips Withholding After Tip Out',
  'tipsAllocation.column.tipsWithholdingBeforeTipOut': 'Tips Withholding Before Tip Out',
  'tipsAllocation.tipSheet.totalTipOut': 'Total Tip Out',
  'tipsAllocation.tipSheet.tipsRemaining': 'Tips Post-Tip Out & Withholding',
  'tipsAllocation.tipSheet.tippingSource': 'Tipping Source',
  'tipsAllocation.tipSheet.tippingItems': 'Tipping Items',
  'tipSheet.status.paid': 'Paid',
  'tipSheet.status.unpaid': 'Unpaid',
};
