export default {
  'menu-manage.availability.title': 'Availability',

  'menu-manage.isFreeOnDishPreferenceWithNoOption.switch.label':
    'When 【NO】 is selected, all options with prices will not be charged (i.e., the price will become 0).',

  'menu-manage.tab.dishes': 'Dishes',
  'menu-manage.tab.customizations': 'Customizations',

  'menu-manage.action.add-category': 'Add a Category',
  'menu-manage.action.add-dish': 'Add Dish',

  'menu-manage.menu.title': 'Menu Setup',
  'menu-manage.menu.sort': 'Drag the items to change the order of your menu',
  'menu-manage.menu.menu': 'MENU',

  'menu-manage.reload-meals': 'Load Failed！ Click Here to retry',
  'menu-manage.loading-customizations': 'Loading',

  'menu-manage.list-title.categories': 'Categories',
  'menu-manage.list-title.dish-items': 'Dish Items',
  'menu-manage.list-title.customizations': 'Customizations',
  'menu-manage.list-title.colorpicker': 'Color Picker',

  'menu-manage.price-switch': 'Open price',
  'menu-manage.price': 'Price',
  'menu-manage.dish-button-color': 'Button Color',

  'menu-manage.dish-preference.title': 'Dish Preferences',
  'menu-manage.dish-preference.associatedCustomizedOption': 'Associated {count} customized options',
};
