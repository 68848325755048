export default {
  'userActivity.title': 'User Activity',
  'userActivity.query.placeholder': 'Search Order#',
  'userActivity.userSelect.placeholder': 'Operation type',

  'userActivity.column.created_at': 'Time Created',
  'userActivity.column.activity_name': 'User Activity',
  'userActivity.column.void': 'Void',
  'userActivity.column.order_number': 'Order #',
  'userActivity.column.operator_name': 'Operator',
  'userActivity.column.approver_name': 'Authorized By',
  'userActivity.column.items': 'Items',
  'userActivity.column.price': 'Value',
  'userActivity.column.reason': 'Reason',
  'userActivity.column.notes': 'Note',
  'userActivity.column.device_serial_number': 'Device SN',
  'userActivity.column.activity_content': 'Activity Content',
  'userActivity.column.operation_mode': 'Operation Mode',

  'userActivity.operationMode.online': 'Online',
  'userActivity.operationMode.offline': 'Offline',

  'userActivity.scheduleSendDish': 'scheduled send, {scheduled_duration}min(s)',
  'userActivity.activityType.send_dish_item': 'Send kitchen',
  'userActivity.activityType.add_payment': 'Add payment',
  'userActivity.activityType.apply_order_promotion': 'Apply promotion',
  'userActivity.activityType.finish_order': 'Close the order',
  'userActivity.activityType.void_order': 'Void the order',
  'userActivity.activityType.void_dish_item': 'Void meals',
  'userActivity.activityType.transfer_order': 'Transfer order',
  'userActivity.activityType.merge_order': 'Merge order',
  'userActivity.activityType.note_order': 'Order note',
  'userActivity.activityType.change_order_employee': 'Change employee',
  'userActivity.activityType.update_dish_item_price': 'Update line item price',
  'userActivity.activityType.note_dish_item': 'Line item note',
  'userActivity.activityType.discount_dish_item': 'Discount line items',
  'userActivity.activityType.transfer_dish_item': 'Transfer line items',
  'userActivity.activityType.remove_exempt_dish_item_order_discount': 'Remove exempt order discount of line item',
  'userActivity.activityType.exempt_dish_item_order_discount': 'Exempt order discount of line item',
  'userActivity.activityType.exempt_dish_item_tax': 'Exempt tax of line item',
  'userActivity.activityType.remove_exempt_dish_item_tax': 'Remove exempt tax of line item',
  'userActivity.activityType.delete_dish_item': 'Delete meals',
  'userActivity.activityType.reopen': 'Reopen the order',
  'userActivity.activityType.confirm_order': 'Confirm the order',
  'userActivity.activityType.cancel_order': 'Cancel the order',
  'userActivity.activityType.create_order': 'Create the order',
  'userActivity.activityType.exempt_order_tax': 'Exempt order tax',
  'userActivity.activityType.remove_exempt_order_tax': 'Remove exempt order tax',
  'userActivity.activityType.offline_order_sync': 'Sync offline order',
  'userActivity.activityType.open_cash_box': 'Open cash box',
  'userActivity.activityType.refund': 'Refund',
  'userActivity.activityType.update_tips': 'Update tips',
  'userActivity.activityType.cancel_payment': 'Cancel payment',
  'userActivity.activityType.split_order_by_check': 'Split by check',
  'userActivity.activityType.reprint_order': 'Reprint',
  'userActivity.activityType.print_order': 'Print',
  'userActivity.activityType.delete_order_fee': 'Delete order fee',
  'userActivity.activityType.add_order_fee': 'Add order fee',
  'userActivity.activityType.edit_order_fee': 'Edit order fee',
  'userActivity.activityType.update_order_fees': 'Update all fees of order',
  'userActivity.activityType.update_order_discount': 'Order discounts',
  'userActivity.activityType.change_service_type': 'Change service type',
  'userActivity.activityType.apply_order_point': 'Point exchange',
  'userActivity.activityType.quick_menu_edit': 'Quick edit menu',
  'userActivity.activityType.update_decorated_menu': 'Update Table Pro',
  'userActivity.activityType.void_dish_customization': 'Void Dish Customization',
  'userActivity.activityType.retry_print': 'Retry printing',
  'userActivity.activityType.reset_ticket': 'Reset Ticket',
  'userActivity.activityType.floor_plan_section_editing': 'Floor Plan Section Editing',

  'userActivity.activityGroup.shopping_cart': 'Shopping Cart',
  'userActivity.activityGroup.order': 'Order',
  'userActivity.activityGroup.payment': 'Payment',
  'userActivity.activityGroup.print': 'Print',
  'userActivity.activityGroup.other': 'Other',
};
