export default {
  'printerLogs.column.id': 'Task ID',
  'printerLogs.column.created_at': 'Created At',
  'printerLogs.column.device_name': 'Device Name',
  'printerLogs.column.device_ip': 'Device IP',
  'printerLogs.column.app_version': 'App Version',
  'printerLogs.column.bundle_version': 'Packing batch number',
  'printerLogs.column.printer_name': 'Printer Name',
  'printerLogs.column.printer_ip': 'Printer IP',
  'printerLogs.column.printer_mac': 'Printer MAC',
  'printerLogs.column.printer_mode': 'Printer Mode',
  'printerLogs.column.fireware': 'Fireware',
  'printerLogs.column.disconnect_type': 'Disconnect Type',
  'printerLogs.column.operation_suggestion': 'Operation Suggestion',
  'printerLogs.column.error_description': 'Error Description',
  'printerLogs.column.status': 'Status',
  'printerLogs.column.order_number': 'Order #',
  'printerLogs.column.order_source': 'Order Source',
  'printerLogs.column.cart_number': 'Ticket #',
  'printerLogs.column.ticket_type': 'Ticket Type',
  'printerLogs.column.trigger': 'Trigger',
  'printerLogs.column.trigger_printing_source': 'Trigger Source',
  'printerLogs.column.retry_count': 'Retry Count',
  'printerLogs.column.priority': 'Priority',
  'printerLogs.column.template': 'Template',
  'printerLogs.column.recovery_mode_status': 'Recovery Mode Status',
  'printerLogs.column.external_order_number': 'External Order #',
  'printerLogs.column.external_platform': 'Platform',
  'printerLogs.column.service_type': 'Service Type',
  'printerLogs.column.template_type': 'Template Type',
  'printerLogs.column.rendering_bitmap': 'Rendering in Bitmap',
  'printerLogs.column.duration': 'Duration',
  'printerLogs.column.error_reason': 'Error Reason',
  'printerLogs.column.error_type': 'Error Type',
  'printerLogs.column.error_reason_type': 'Error Reason Type',
  'printerLogs.column.print_in_background': 'Print in Background',
  'printerLogs.column.firmware': 'Fireware Version',

  'printerLogs.title': 'Printer Log Detail',
  'printerLogs.section.basicInfos': 'Basic Infos',
  'printerLogs.section.ticketInfos': 'Ticket Infos',
  'printerLogs.section.traces': 'Task Traces',

  'printerLogs.status.initial': 'Initial',
  'printerLogs.status.printing': 'Printing',
  'printerLogs.status.printSuccess': 'Done',
  'printerLogs.status.printFail': 'Failed',
  'printerLogs.status.expired': 'Expired',

  'printerLogs.ticketType.bill': 'Bill',
  'printerLogs.ticketType.reprintBill': 'Reprint Bill',
  'printerLogs.ticketType.receipt': 'Receipt',
  'printerLogs.ticketType.reprintReceipt': 'Reprint Receipt',
  'printerLogs.ticketType.reprintReceiptCustomerCopy': 'Reprint Receipt Customer Copy',
  'printerLogs.ticketType.reprintReceiptMerchantCopy': 'Reprint Receipt Merchant Copy',
  'printerLogs.ticketType.reprintReceiptRefund': 'Reprint Receipt Refund',
  'printerLogs.ticketType.kitchenTicket': 'Kitchen Ticket',
  'printerLogs.ticketType.reprintKitchenTicket': 'Reprint Kitchen Ticket',
  'printerLogs.ticketType.voidKitchenTicket': 'Void Kitchen Ticket',
  'printerLogs.ticketType.updateKitchenTicket': 'Update Kitchen Ticket',
  'printerLogs.ticketType.rushKitchenTicket': 'Rush Kitchen Ticket',
  'printerLogs.ticketType.packingTicket': 'Packing Ticket',
  'printerLogs.ticketType.reprintPackingTicket': 'Reprint Packing Ticket',
  'printerLogs.ticketType.voidPackingTicket': 'Void Packing Ticket',
  'printerLogs.ticketType.updatePackingTicket': 'Update Packing Ticket',
  'printerLogs.ticketType.closeoutReport': 'Closeout Report',
  'printerLogs.ticketType.cashDrawerReport': 'Cash Drawer Report',
  'printerLogs.ticketType.check': 'Check',
  'printerLogs.ticketType.clockoutSummary': 'Clockout Summary',
  'printerLogs.ticketType.giftCardTransaction': 'Gift Card Transaction',
  'printerLogs.ticketType.runnerTicket': 'Runner Ticket',
  'printerLogs.ticketType.reprintRunnerTicket': 'Reprint Runner Ticket',
  'printerLogs.ticketType.voidRunnerTicket': 'Void Runner Ticket',
  'printerLogs.ticketType.updateRunnerTicket': 'Update Runner Ticket',

  'printerLogs.errorType.printerNotFound': 'Printer Not Found',
  'printerLogs.errorType.printerIsBusy': 'Printer is Busy',
  'printerLogs.errorType.capabilityNotSupported': 'capabilityNotSupported',
  'printerLogs.errorType.paperIsEmpty': 'Lack of paper',
  'printerLogs.errorType.offline': 'Printer offline',
  'printerLogs.errorType.connectError': 'Connect Error',
  'printerLogs.errorType.jobFailed': 'Job Failed',
  'printerLogs.errorType.otherError': 'Other Error',
  'printerLogs.errorType.noPrintersConfigure': 'No printers configured',
  'printerLogs.errorType.printersIsInactive': 'The printers are deactived',
  'printerLogs.errorType.dishesNoPrintersConfigure': 'The dishes are not mapped to any printers',
  'printerLogs.errorType.printersInactiveWithMeals': 'The printers mapped with the dishes are deactivated',
  'printerLogs.errorType.notConfiguration': 'Not find releated configuration',

  'printerLogs.errorReasonType.noInterface': 'Network not turned on',
  'printerLogs.errorReasonType.noWifiAndNoWired': 'No wifi or wired network',
  'printerLogs.errorReasonType.noLocalNetworkPermission': 'No local network permission',
  'printerLogs.errorReasonType.notConfigPrinters': 'No matched printers',
  'printerLogs.errorReasonType.printerInfoError': 'Printer info error',
  'printerLogs.errorReasonType.icmpPingFail': 'ICMP Ping failed',
  'printerLogs.errorReasonType.pingWebFail': 'Ping printer failed',
  'printerLogs.errorReasonType.noExceptionFound': 'No exception found',

  'printerLogs.recoveryModeStatus.unused': 'Not started',
  'printerLogs.recoveryModeStatus.reportFail': 'Failed',
  'printerLogs.recoveryModeStatus.reported': 'Started',
  'printerLogs.recoveryModeStatus.success': 'Succeed',
  'printerLogs.recoveryModeStatus.timeout': 'Timeout',

  'printerLogs.filter.orderNumber': 'Order #',
  'printerLogs.filter.ticketNumber': 'Ticket #',

  'PrinterLogDetail.section.orderInfos': 'Order Infos',
  'PrinterLogDetail.section.taskInfos': 'Task Infos',
  'PrinterLogDetail.section.deviceInfos': 'Device Infos',
  'PrinterLogDetail.section.traces': 'Traces',
  'PrinterLogDetail.section.template': 'Template',
  'PrinterLogDetail.section.operationSuggestion': 'Recommended actions',
};
