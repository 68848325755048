export default {
  'servicePeriod.title': 'Service Period',
  'servicePeriod.title_link': 'Service Period <a>（set up your Service Period）</a>',
  'servicePeriod.column.name': 'Name',
  'servicePeriod.column.orders': 'Orders',
  'servicePeriod.column.net_sales': 'Net Sales',
  'servicePeriod.column.discounts': 'Discounts',
  'servicePeriod.column.refund_amount': 'Refund Amount',
  'servicePeriod.column.gross_sales': 'Gross Sales',
  'servicePeriod.column.tax_amount': 'Tax Amount',
  'servicePeriod.column.total_tips': 'Total Tips',
};
