export default {
  'fee.section.basic': 'Basic Information',
  'fee.section.reportingRules': 'Reporting Rules',
  'fee.section.autoApplyRules': 'Auto Apply Rules',
  'fee.name': 'Name',
  'fee.foreign_name': 'Foreign Name',
  'fee.amount': 'Amount',
  'fee.associatedRoles': 'Associated Roles',
  'fee.taxable': 'Taxable',
  'fee.is_taxable': 'Taxable',
  'fee.is_non_taxable': 'Non-Taxable',
  'fee.type': 'Type',
  'fee.taxable.tooltip': 'Included in the tax calculation',
  'fee.autoApply': 'Auto apply this fee when',
  'fee.autoApplyRule.limit': 'Cannot config auto apply rule when this fee regard as a Call In delivery fee',
  'fee.name.required': 'Name required',
  'fee.foreign_name.required': 'Foreign name is required',
  'fee.amount.required': 'Amount is required',
  'fee.transactionAmountThreshold.required': 'Credit card transaction amount is required',
  'fee.guestAmountThreshold.required': 'Guest amount is required',
  'fee.column.delivery': 'Delivery Fee',
  'fee.column.delivery.tooltip': 'Quick access when creating Call In delivery orders',
  'fee.column.gratuity': 'Gratuity',
  'fee.column.gratuity.tooltip': 'Charged as a gratuity ‘tip’ and be categorized as a tip in reporting',
  'fee.column.autoApply': 'Auto apply',
  'fee.new': 'New Fee',
  'fee.edit': 'Edit Fee',
  'fee.action.edit': 'Edit',
  'fee.action.delete': 'Delete',
  'fee.deletionPrompt': 'Sure to delete this fee?',
  'fee.trigger.all': 'In all conditions',
  'fee.trigger.payByCard': 'For card payment',
  'fee.trigger.cardTransactionAmountLessThan': 'If card payment is less than',
  'fee.trigger.guestAmountEqOrGt': 'If guest number is greater than or equal to',
  'fee.action.addRule': 'Add Rule',
  'fee.orderSource.placeholder': 'Order Source(s)',
  'fee.serviceType.placeholder': 'Service Type',
  'fee.reportingRules.title': 'Configure how this fee is calculated on orders and reports',
  'fee.includeInTipsCalculation': 'Included in tips calculation',
  'fee.includeInTipsCalculation.tooltip': 'This fee will be added in the suggested tips calculation.',
};
