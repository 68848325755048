export default {
  'globalPreferenceSettings.title': 'General Setting',
  'globalPreferenceSettings.section.language': 'Language',

  'globalPreferenceSettings.block.languageSwitch': 'Enable Language Switch',
  'globalPreferenceSettings.languageSwitch.forPOS': 'POS',
  'globalPreferenceSettings.languageSwitch.forKiosk': 'Kiosk',
  'globalPreferenceSettings.languageSwitch.forQRCode': 'QR Code',
  'globalPreferenceSettings.languageSwitch.forTablet': 'Tablet',
  'globalPreferenceSettings.languageSwitch.forOnlineOrdering': 'Online Order',
  'globalPreferenceSettings.languageSwitch.forWaitlist': 'Waitlist',
  'globalPreferenceSettings.languageSwitch.forLoyaltyWeb': 'Loyalty Web',
  'globalPreferenceSettings.languageSwitch.forCFG': 'CFD',

  'globalPreferenceSettings.languageSwitch.update.success': 'Update Language Switch Settings successfully!',
  'globalPreferenceSettings.languageSwitch.update.fail': 'Update Language Switch Settings failed!',
};
