export default {
  'dishEdit.title': 'Edit Dish',
  'dishAdd.title': 'Add Dish',

  'dishEdit.step1': 'Step 1: Name and add a description for your dish',
  'dishEdit.step2': 'Step 2: Upload one photo',
  'dishEdit.step3': 'Step 3: Assign your dish to a category under each menu',
  'dishEdit.step4': 'Step 4: Add pricing for each menu',
  'dishEdit.step4.prerequisite': 'Complete Step 3 to add pricing',
  'dishEdit.step5': 'Step 5: Assign customizations to your dish',
  'dishEdit.step5.prerequisite': 'Complete Step 3 to assign customizations',
  'dishEdit.step2.description': `
    <div>Photo Guidelines</div>
    <ul style="margin: 0;">
      <li>Resolution: recommended is 1920 x 1080 pixels.</li>
      <li>Aspect ratio: 16:9</li>
      <li>Orientation: portrait.</li>
      <li>Minimum width and height: 320 x 320.</li>
      <li>The whole dish should be centered and take up about 3-quarters of the image. Use whitespace at each side of the image, as platforms usually display squared images when showing the whole menu.</li>
    </ul>
  `,

  'dishEdit.step.required': 'Required',
  'dishEdit.field.name': 'Name',
  'dishEdit.field.foreignName': 'Foreign Name',
  'dishEdit.field.posName': 'POS Name',
  'dishEdit.field.kitchenName': 'Kitchen Name',
  'dishEdit.field.kitchenForeignName': 'Kitchen Foreign Name',
  'dishEdit.field.taxCode': 'Tax Code',
  'dishEdit.field.code': 'Custom Code',
  'dishEdit.field.printers': 'Printers',
  'dishEdit.field.openPrice': 'Open Price',
  'dishEdit.field.price': 'Price',
  'dishEdit.field.memberPrice': 'Member Price',
  'dishEdit.field.pointPrice': 'Point Price',
  'dishEdit.field.pointPrice.addOnAfter': 'pts',
  'dishEdit.field.menu': 'Menu',
  'dishEdit.field.category': 'Category',
  'dishEdit.field.discount': 'Discount',
  'dishEdit.field.tax': 'Tax',
  'dishEdit.excludeTax': 'Non-taxable',
  'dishEdit.excludeDiscount': 'Exclude',
  'dishEdit.field.description': 'Description',
  'dishEdit.field.name.required': 'Name is required',
  'dishEdit.field.foreignName.required': 'Foreign name is required',
  'dishEdit.field.categories.required': 'Please pick at least one category in any menu.',
  'dishEdit.field.pointPrice.mustMoreThanZero': `can't be zero`,
  'dishEdit.action.uploadPhoto': 'Upload Photo',
  'dishEdit.image.description': 'The format is {fileTypes}, should be within {fileMaxSize}M',
  'dishEdit.image.tooLargeSize': 'File size needs to be {fileMaxSize}MB or smaller',
  'dishEdit.image.tooSmallSize': 'Image must be have width and height of at least 320 x 320',
  'dishEdit.resolutionAdjusted': 'Resolution has been adjusted',
  'dishEdit.viewOriginalPhoto': 'View original photo',
  'dishEdit.handleImageFailed': 'Handle image failed, please re-operate',
  'dishEdit.wrongFileType': 'File needs to be {fileTypes}',

  'dishEdit.field.price.required': 'Price is required',
  'dishEdit.field.name.placeholder': 'Re-enter the English name if there’s no foreign name',
  'dishEdit.field.description.placeholder': 'Enter the dish description. This will be visible on all Chowbus menus.',
  'dishEdit.field.posName.placeholder': 'A short name shown on the POS menu',
  'dishEdit.field.kitchenName.placeholder': 'A short name shown on kitchen tickets and labels',
  'dishEdit.field.weightUnit.placeholder': 'Unit',

  'dishEdit.action.assignCategories': 'Assign Categories',
  'dishEdit.action.assignCustomizations': 'Assign Customizations',

  'dishEdit.servicePath': '{servicePath} Menu',
  'dishEdit.price.title': 'Price',
  'dishEdit.menuPath': 'Include in {menuPath} Menu',
  'dishEdit.menuCategory': '{menuName} Menu Categories',
  'dishEdit.menuPrice': '{menuName} Menu Prices',
  'dishEdit.menuCustomizations': '{menuName} Menu Customization',
  'dishEdit.menuCustomizations.display.setting.title': 'Customization display',
  'dishEdit.menuCustomizations.display.setting.fullview.option': 'Default view (full page)',
  'dishEdit.menuCustomizations.display.setting.compactview.option': 'Compact view (bottom bar)',

  'dishEdit.category.summary':
    'There are {inHouseMenusCount} In House menus and {chowbusMenusCount} Online menus that you can add this dish to. Choose the category the dish should be associated to in each menu.',
  'dishEdit.customized_properties.summary':
    'These customizations will be applied to all your menus. However, you have can update them individually, as needed.',
  'dishEdit.customized_properties.tips':
    'The * by the customization group indicates it is required that the customer choose at least a customization option when ordering this dish.  If you would like to change this, please visit the Customizations page.',

  'dishEdit.option.required': 'Selection Required',
  'dishEdit.category.required': 'Please select at least one category',

  'dishEdit.successfullyCreated': 'Successfully created dishes',
  'dishEdit.successfullyUpdated': 'Successfully updated dishes',

  'dishEdit.field.posName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the POS menu',
  'dishEdit.field.kitchenName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the kitchen tickets and labels',
  'dishEdit.field.taxCode.tooltip':
    'Tax codes are used to identify products that have specific tax rates so we can calculate the most accurate sales tax for your customers. ',
  'dishEdit.uploadImageInMenuBuilder': 'Please upload your dish photo in the <a>Menu Builder</a>.',
};
