export default {
  'orderDetail.title': 'Order Detail',

  'orderDetail.lineItems.summary': 'Order Details: {count} items',
  'orderDetail.lineItem.quantity': 'Qty',
  'orderDetail.lineItem.description': 'Description',
  'orderDetail.lineItem.price': 'Price',
  'orderDetail.lineItem.notes': 'Dish Notes:',
  'orderDetail.lineItem.discount': 'Dish Discount',
  'orderDetail.lineItem.name': 'Name',
  'orderDetail.lineItem.customizations': 'Customizations',
  'orderDetail.lineItem.thirdLevelItems': '3rd Level Items',
  'orderDetail.platform': 'Platform:',
  'orderDetail.externalOrderNumber': 'External Order #:',
  'orderDetail.cartNumber': 'Ticket #:',
  'orderDetail.orderNumber': 'Order #:',
  'orderDetail.serviceType': 'Service Type:',
  'orderDetail.orderSource': 'Order Source:',
  'orderDetail.tables': 'Table:',
  'orderDetail.employee': 'Server:',
  'orderDetail.driver_name': 'Driver:',
  'orderDetail.userName': 'Customer Name:',
  'orderDetail.phone': 'Phone Number:',
  'orderDetail.delivery_address': 'Delivery Address:',
  'orderDetail.delivery_note': 'Delivery Note:',
  'orderDetail.sub_total': 'Subtotal',
  'orderDetail.order_discount': 'Order Discount',
  'orderDetail.dish_discount': 'Dish Discount',
  'orderDetail.tax': 'Tax',
  'orderDetail.estimatedTax': 'Estimated Tax',
  'orderDetail.tips': 'Tips',
  'orderDetail.staff_tips': 'Service Tips',
  'orderDetail.driver_tips': 'Delivery Tips',
  'orderDetail.bill_total': 'Bill Total',
  'orderDetail.total': 'Order Total',
  'orderDetail.compensate': 'Comp',
  'orderDetail.taxable_fees_collected': 'Taxable Fee Collected:',
  'orderDetail.non_taxable_fees_collected': 'Non-taxable Fee Collected:',
  'orderDetail.customerInfo': 'Customer Info',
  'orderDetail.rewardFlag': ' * Reward dish * ',
  'orderDetail.free': 'Free',
  'orderDetail.taxExempt': 'Tax Exempt',
  'orderDetail.discountExempt': 'Excluded from Order Discount',

  'orderDetail.prepaid': 'PREPAID',
  'orderDetail.charge_at_pickup': 'CHARGE AT PICKUP',

  'orderDetail.action.viewTransactions': 'View Transactions Detail',

  'orderDetail.refund.summary': 'Refund History: {count} items',
  'orderDetail.refund.created_at': 'Created time',
  'orderDetail.refund.cardLast4digit': 'Last 4 digits',
  'orderDetail.refund.staff': 'Staff',
  'orderDetail.refund.amount': 'Amount',
  'orderDetail.refund.summaryTotal': 'Refund Total: {amount}',
  'orderDetail.fee.summary': 'Fee Record: {count} items',
  'orderDetail.refund.refundType': 'Refund Type',
  'orderDetail.refund.refundType.bill': 'Bill',
  'orderDetail.refund.refundType.tips': 'Tips',
  'orderDetail.refund.refundType.full': 'Full',
  'orderDetail.refund.refundType.composite': 'Bill&Tips',
  'orderDetail.refund.paymentType': 'Payment Type',

  'orderDetail.refund.refundType.payment_fee': 'CardSurcharge',
  'orderDetail.refund.refundType.bill&tips': 'Bill&Tips',
  'orderDetail.refund.refundType.bill&payment_fee': 'Bill&CardSurcharge',
  'orderDetail.refund.refundType.tips&payment_fee': 'Tips&CardSurcharge',
  'orderDetail.refund.refundType.bill&tips&payment_fee': 'Bill&Tips&CardSurcharge',

  'orderDetail.section.orderDetails': 'Order Details',
  'orderDetail.section.paymentDetails': 'Payment Details',
  'orderDetail.section.cashbackDetails': 'Cashback Details',

  'orderDetail.cashbackIssued': 'Cashback Issued',

  'orderDetail.text.receipt.title': 'Send by Text',
  'orderDetail.email.receipt.title': 'Send by Email',
  'orderDetail.text.receipt.placeholder': 'Enter mobile number',
  'orderDetail.email.receipt.placeholder': 'Enter Email',
  'orderDetail.receipt.send.success': 'Send Receipt Succefully',
  'orderDetail.receipt.send.failed': 'Send Receipt Failed',

  'orderDetail.checkbill.lineItems.summary': 'Check Order Details: {count} items',
  'orderDetail.checkbill.total': 'Check Total',

  'orderDetail.transaction.title': 'Transaction Detail',

  'orderDetail.payment.summary': 'Payment History',
  'orderDetail.payment.checkNumber': 'Check #',
  'orderDetail.payment.paymentType': 'Payment Type',
  'orderDetail.payment.amount': 'Amount',

  'orderDetail.payment.tips': 'Tips',
  'orderDetail.payment.fees': 'Fees',
  'orderDetail.payment.paymentTotal': 'Payment Total',

  'orderDetail.transaction.summary': 'Transaction Details',

  'orderDetail.operateLoggings': 'Operate logs',
  'orderDetail.operateLoggings.operator_name': 'Operator',
  'orderDetail.operateLoggings.approver_name': 'Approver',
  'orderDetail.operateLoggings.device_serial_number': 'Device',
  'orderDetail.operateLoggings.operation_mode': 'Operation mode',
};
