export default {
  'permissionManagement.title': 'Permission Management',
  'permissionManagement.restaurantList': 'Restaurant List',
  'permissionManagement.rolesAndPermissions': 'Roles And Permissions',
  'permissionManagement.roles': 'Roles',
  'permissionManagement.permissions': 'Permissions',
  'permissionManagement.assign.title': 'Assign Permission',
  'permissionManagement.assign.permission': 'Permission',
  'permissionManagement.assign.restaurants': 'Restaurants',
  'permissionManagement.assign.permission.required': 'Please select permission',
  'permissionManagement.assign.restaurants.required': 'Please choose restaurants',

  'permissionManagement.action.assignPermissionForOwners': 'Assign Permissions For Owners',
  'permissionManagement.action.assign': 'Assign',
  'permissionManagement.action.selectAll': 'Select All',
};
