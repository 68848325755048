export default {
  'adyenMonthlyReport.column.card_brand': 'Type',
  'adyenMonthlyReport.column.rate': 'Rate',
  'adyenMonthlyReport.column.payment_amount': 'Payment Amount',
  'adyenMonthlyReport.column.payment_count': 'Payment Count',
  'adyenMonthlyReport.column.refund_amount': 'Refund Amount',
  'adyenMonthlyReport.column.refund_count': 'Refund Count',
  'adyenMonthlyReport.column.fees': 'Fees Collected',
  'adyenMonthlyReport.column.other_withholding': 'Other Withholdings**',
  'adyenMonthlyReport.column.total': 'Total',
};
