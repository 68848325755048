export default {
  'prepaidTransactions.title': 'Prepaid Cards',
  'prepaidTransactions.add.title': 'Add Member',
  'prepaidTransactions.edit.title': 'Edit Member',
  'prepaidTransactions.required.desc': 'Fields with * are required',
  'prepaidTransactions.title.records': 'Records',

  'prepaidTransactions.column.created_at': 'Time Created',
  'prepaidTransactions.column.name': 'Name',
  'prepaidTransactions.column.phone_number': 'Phone Number',
  'prepaidTransactions.column.order_number': 'Order#',
  'prepaidTransactions.column.card_last_four_digits': 'Card Last 4 Digits',
  'prepaidTransactions.column.purchase_amount': 'Purchase Amount',
  'prepaidTransactions.column.bonus_amount': 'Bonus Amount',
  'prepaidTransactions.column.payment_status': 'Payment Status',
  'prepaidTransactions.payment_status.paid': 'Paid',
  'prepaidTransactions.payment_status.authorized': 'Authorized',
  'prepaidTransactions.payment_status.default': 'Unknown',

  'prepaidTransactions.query.placeholder': 'Search by name or phone number',
};
