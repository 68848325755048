export default {
  'permission.title': 'Permission Setting',
  'permission.create.title': 'Create Permission',

  'permission.permissionTree': 'Permission Tree',
  'permission.permissionDetail': 'Permission Detail',

  'permission.field.parent_id': 'Parent Permission id',
  'permission.field.name': 'Name',
  'permission.field.foreign_name': 'Foreign Name',
  'permission.field.code': 'Permission Code',
  'permission.field.permission_type': 'Permission Type',
  'permission.field.has_email': 'Need Email',
  'permission.field.has_phone': 'Need Phone',
  'permission.field.has_pin': 'Need Pin',
  'permission.field.has_required': 'Granted By Default',
  'permission.field.order_num': 'Menu Sequence Number',
  'permission.field.notes': 'Notes',

  'permission.name.isRequired': 'Name is Required',
  'permission.foreign_name.isRequired': 'Foreign Name is Required',
  'permission.code.isRequired': 'Permission Code is Required',
  'permission.permission_type.isRequired': 'Permission Type is Required',

  'permission.action.addPermission': 'Add Permission',
  'permission.action.addSubPermission': 'Add Sub Permission',
  'permission.action.delete': 'Delete This Permission',

  'permission.delete.promptTitle': 'Permission Deletion Prompt',
  'permission.delete.promptContent': 'Are You Sure to Delete This Permission',
};
