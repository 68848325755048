export default {
  'activities.title': 'User Activities',

  'activities.filter.range': 'Search by range',

  'activities.columns.created_at': 'Created Time',
  'activities.columns.type': 'Type',
  'activities.columns.purpose': 'Purpose',
  'activities.columns.employee': 'Employee',
};
