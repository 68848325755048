export default {
  'smartOrderingSettings.title': 'QR Code Ordering',
  'smartOrderingSettings.setupTables.title': 'Set Up QR Code for Tables',
  'smartOrderingSettings.setupRestaurant.title': 'Set Up QR Code for Restaurant',
  'smartOrderingSettings.setUpQRCodes': 'Set Up QR Code',
  'smartOrderingSettings.setupTables.secret': 'Service Secret Key',
  'smartOrderingSettings.setupTables.desc': 'Select the table you would like to print out QR code for:',
  'smartOrderingSettings.incomingOrders.title': 'Incoming QR Code Orders Setting',
  'smartOrderingSettings.acceptQRCodeOrders': 'Accept incoming QR Code orders',
  'smartOrderingSettings.acceptDineInOrders': 'Accept Dine In orders',
  'smartOrderingSettings.enableSwitchTable': 'Accept switch table',
  'smartOrderingSettings.allowDinersToAddDishesThroughQRCodeToWalkInOrders':
    'Allow diners to add dishes through QR code to Walk In orders',
  'smartOrderingSettings.allowDinersToAddDishesThroughQRCodeToWalkInOrders.description':
    'Taking effect on QRCode Ordering and Tablet Ordering',
  'smartOrderingSettings.addAPrefixInTicketNum': 'Add a prefix in ticket number',
  'smartOrderingSettings.addAPrefixInTicketNum.description': 'Taking effect on QRCode Ordering and Tablet Ordering',
  'smartOrderingSettings.sortDishesDynamicallyBySales': 'Sort dishes dynamically by sales',
  'smartOrderingSettings.payment.desc': 'How do you like to collect payment?',
  'smartOrderingSettings.dineInOrders.fullService.onlinePayment': 'Online after order is placed and in-person',
  'smartOrderingSettings.dineInOrders.quickService.onlinePayment': 'Online when order is placed',
  'smartOrderingSettings.dineInOrders.offlinePayment': 'In-person',
  'smartOrderingSettings.dineInOrders.fullService.onlinePaymentImmediately': 'Online when order is placed',
  'smartOrderingSettings.acceptToGoOrders': 'Accept Take Out orders',
  'smartOrderingSettings.togoOrders.onlinePayment': 'Online when order is placed',
  'smartOrderingSettings.togoOrders.offlinePayment': 'In-person',
  'smartOrderingSettings.paymentSetting.title': 'Payment Setting',
  'smartOrderingSettings.paymentSetting.onlinePayment': 'Accept online payment for Dine In orders',
  'smartOrderingSettings.webPageSetting.title': 'QRCode Webpage Setting',
  'smartOrderingSettings.webPageSetting.displayDishRatings': 'Display dish ratings',
  'smartOrderingSettings.webPageSetting.displayChowbusCategories': 'Display Chowbus generated categories',
  'smartOrderingSettings.webPageSetting.displayForeignNames': 'Display dish foreign names',
  'smartOrderingSettings.webPageSetting.displayChowbusCategories.description': 'Including New and Popular',
  'smartOrderingSettings.webPageSetting.displayDishForeignNames': 'Display dish foreign names',
  'smartOrderingSettings.webPageSetting.displaySoldoutDishesAndCustomizations':
    'Display sold out dishes and customizations',
  'smartOrderingSettings.webPageSetting.askGuestNumber': 'Ask guest number',
  'smartOrderingSettings.webPageSetting.askGuestPhone': `Ask customer’s phone number`,
  'smartOrderingSettings.webPageSetting.requestToVerifyGuestPhone': `Dine in phone number verification`,
  'smartOrderingSettings.webPageSetting.enableOrderLevelNotes': `Allow order notes`,
  'smartOrderingSettings.webPageSetting.enableMealLevelNotes': `Allow item notes`,
  'smartOrderingSettings.webPageSetting.enableMealLevelNotes.description': 'It is only effective for take out orders',

  'smartOrderingSettings.column.section': 'Section',
  'smartOrderingSettings.column.table': 'Table Name',
  'smartOrderingSettings.column.enabled': 'QR Code Ordering Enabled',
  'smartOrderingSettings.action.desc': 'Select the type of QR code you would like to print out:',
  'smartOrderingSettings.action.downloadStand': 'Stand QR Code',
  'smartOrderingSettings.action.downloadSticker': 'Sticker QR Code',
  'smartOrderingSettings.restaurantLevel.action.downloadStand': 'Take Out Stand QR Code',
  'smartOrderingSettings.restaurantLevel.action.downloadSticker': 'Take Out Sticker QR Code',
  'smartOrderingSettings.action.selectAll': 'Select all',
  'smartOrderingSettings.action.clear': 'Clear',
  'smartOrderingSettings.action.generateCheckCode': 'Generate',
  'smartOrderingSettings.action.regenerateCheckCode': 'Regenerate',
  'smartOrderingSettings.takeout': 'Print out QR Code for Take Out',

  'smartOrderingSettings.regenerateCheckCode.prompt':
    'Would you also like to regenerate the key? Regenerating the key will void the existing QR codes and new QR codes need to be reprinted. This is usually useful when the QR code is leaked with a security concern.',
  'smartOrderingSettings.noSecret': 'Please generate service secret key before downloading',

  'smartOrderingSettings.downloading': 'Generating PDF File',
  'smartOrderingSettings.downloadingA5': 'Generating PDF File ({count}/{total})',
  'smartOrderingSettings.cancelDownloading': 'Cancel Downloading',

  'smartOrderingSettings.generate.prompt': 'Only restaurant owner can generate service secret key',
  'smartOrderingSettings.regenerate.prompt': 'Only restaurant owner can regenerate service secret key',

  'smartOrderingSettings.restaurantList': 'Restaurant List',
  'smartOrderingSettings.restaurantList.filter': 'filter by name/foreign name/id',
  'smartOrderingSettings.generateQRCodes': 'Generate QR Codes',

  'smartOrderingSettings.QRCode.poster.size.selction.desc': 'Select the size of QR code you would like to download:',
  'smartOrderingSettings.QRCode.poster.size.Stand': 'Stand',
  'smartOrderingSettings.QRCode.poster.size.Sticker': 'Sticker',
  'smartOrderingSettings.QRCode.poster.size.AcrylicSheets': 'Acrylic Sheets',
  'smartOrderingSettings.QRCode.poster.size.5x8_inch_Stand': '5" x 8"',
  'smartOrderingSettings.QRCode.poster.size.4x6_inch_Sticker': '4" x 6"',
  'smartOrderingSettings.QRCode.poster.size.4x4_inch_Sticker': '4" x 4"',
  'smartOrderingSettings.QRCode.poster.size.250x250_inch_Sticker': '2.5" x 2.5"',

  'smartOrderingSettings.QRCode.poster.size.A5_Stand': 'A5',
  'smartOrderingSettings.QRCode.poster.size.3x3_cm_Sticker': '3.3 x 3.3 cm',
  'smartOrderingSettings.QRCode.poster.size.325x325_inch_Sticker': '3.25" x 3.25"',

  'smartOrderingSettings.QRCode.action.download': 'Download QR Code',
  'smartOrderingSettings.QRCode.action.takout.download': 'Download for Take Out',

  'smartOrderingSettings.tabletOrderingSetting.title': 'Tablet Ordering Setting',
  'smartOrderingSettings.tabletOrderingSetting.unlockMenusByPIN': 'Need servers to unlock menu',
  'smartOrderingSettings.tabletOrderingSetting.unlockMenusByPIN.description':
    "Only for Tablet Ordering with multiple menus,  server's PIN is required when unlocking the menus",
  'smartOrderingSettings.tabletOrderingSetting.unlockMenusByPIN.tips':
    'Enable this: QR code ordering will only support view menus, but can’t place orders any more. Kiosk will support  viewing all menus and placing orders',

  'smartOrderingSettings.tabletOrderingSetting.inputGuestNumber': 'input guest number',
  'smartOrderingSettings.tabletOrderingSetting.inputGuestNumber.description':
    "Required server's PIN when input the number of guests",

  'smartOrderingSettings.tabletOrderingSetting.enableOrderLevelNotes': 'Allow order notes',

  'smartOrderingSettings.tabletOrderingSetting.unlockPIN': 'PIN to unlock menu',
  'smartOrderingSettings.tabletOrderingSetting.unlockPIN.edit': 'Edit',
  'smartOrderingSettings.tabletOrderingSetting.unlockPIN.edit.title': 'Update PIN Code for unlocking menu',
  'smartOrderingSettings.tabletOrderingSetting.unlockPIN.label': 'PIN Code',
  'smartOrderingSettings.tabletOrderingSetting.unlockPIN.isrequired': 'PIN Code is required',
  'smartOrderingSettings.tabletOrderingSetting.unlockPIN.must4digit': 'PIN Code must is 4-digits ',
  'smartOrderingSettings.webPageSetting.enableCustomizationLimitProperties':
    'Apply customizations & preferences limit rules',
  'smartOrderingSettings.webPageSetting.enableCustomizationLimitProperties.description':
    'After enabling, all limit rules of customizations & preferences will be applied',

  'smartOrderingSettings.tabletOrderingSetting.buffetOrderingLimit': 'Buffet ordering limit',
  'smartOrderingSettings.tabletOrderingSetting.shoppingCartLimit': 'Shopping cart limit',
  'smartOrderingSettings.tabletOrderingSetting.buffetOrderingLimit.description':
    'Configurations below only apply to tablets',
  'smartOrderingSettings.tabletOrderingSetting.limitByGuest': 'Limit item purchase quantities by guest',

  'smartOrderingSettings.tabletOrderingSetting.maxItemsOnOrder': 'Max items on order',
  'smartOrderingSettings.tabletOrderingSetting.maxItemsOnOrder.rule': 'please input Max items on order(must input)!',
  'smartOrderingSettings.tabletOrderingSetting.maxItemsOnOrderAddonAfter': 'items/guest',

  'smartOrderingSettings.tabletOrderingSetting.needMaxItemsPerRound': 'Max items per round',
  'smartOrderingSettings.tabletOrderingSetting.needMaxItemsPerRoundAddonAfter': 'items',

  'smartOrderingSettings.tabletOrderingSetting.editLimitRule': 'Edit limit rules',
  'smartOrderingSettings.tabletOrderingSetting.chooseLimitQuantity': 'Limit/Guest',
  'smartOrderingSettings.tabletOrderingSetting.chooseLimitType': 'Limit type',
  'smartOrderingSettings.tabletOrderingSetting.chooseLimitItems': 'Items',
  'smartOrderingSettings.tabletOrderingSetting.chooseLimitCategorys': 'Categories',

  'smartOrderingSettings.tabletOrderingSetting.diningTimeLimit': 'Dining time limit',
  'smartOrderingSettings.tabletOrderingSetting.diningTimeLimit.rule': 'please input Dining time limit(must input)!',
  'smartOrderingSettings.tabletOrderingSetting.diningTimeLimitAddonAfter': 'mins/order',

  'smartOrderingSettings.tabletOrderingSetting.countdownReminder': 'Display countdown reminder',
  'smartOrderingSettings.tabletOrderingSetting.countdownReminder.rule':
    'please input Display countdown reminder(must input)!',
  'smartOrderingSettings.tabletOrderingSetting.countdownReminderAddonAfter': 'mins',

  'smartOrderingSettings.tabletOrderingSetting.submitRoundsInterval': 'Submit rounds interval',
  'smartOrderingSettings.tabletOrderingSetting.submitRoundsInterval.rule':
    'please input Submit rounds interval(must input)!',
  'smartOrderingSettings.tabletOrderingSetting.submitRoundsIntervalAddonAfter': 'mins/submit',

  'smartOrderingSettings.tabletOrderingSetting.maxRoundsPerOrder': 'Max rounds per order',
  'smartOrderingSettings.tabletOrderingSetting.maxRoundsPerOrder.rule':
    'please input Max rounds per order(must input)!',
  'smartOrderingSettings.tabletOrderingSetting.maxRoundsPerOrderAddonAfter': 'submit/order',

  'smartOrderingSettings.tabletOrderingSetting.enableScreenSaver': 'Screen Saver',
  'smartOrderingSettings.tabletOrderingSetting.enableScreenSaver.description': `The tablet stays on the order page if toggle is off`,

  'smartOrderingSettings.tabletOrderingSetting.originalPriceStrikethrough': 'Original Price strikethrough',
  'smartOrderingSettings.tabletOrderingSetting.originalPriceStrikethrough.description': `The original price is not rendered with strike-through style if toggle is off and meal has member price`,
  'smartOrderingSettings.webPageSetting.browsingMenuModeForce': 'Menu Browsing Mode',
  'smartOrderingSettings.webPageSetting.browsingMenuModeForce.description': 'Just Taking effect on QR code ordering',

  'smartOrderingSettings.QRCode.action.menubrowsing.download': 'Download for Menu Browsing',

  'smartOrderingSettings.tabletOrderingSetting.enableShowTotalPriceOnSubmitButton': 'Show total price on Tablet cart',
  'smartOrderingSettings.tabletOrderingSetting.enableShowTotalPriceOnSubmitButton.description': `Showing current Shooping cart total price beside submit button if toggle is on`,
  'smartOrderingSettings.tabletOrderingSetting.showQRCode': 'Show QR code on tablet ',
  'smartOrderingSettings.tabletOrderingSetting.showQRCode.description': `Showing QRCode on tablet if toggle is on`,

  'smartOrderingSettings.webPageSetting.mealImageShowStyle': `Menu display style`,
  'smartOrderingSettings.webPageSetting.mealImageShowStyle.default': 'Default(enlarge 1st dish image automatically)',
  'smartOrderingSettings.webPageSetting.mealImageShowStyle.medium': 'Medium (won’t enlarge 1st dish image)',
  'smartOrderingSettings.webPageSetting.mealImageShowStyle.description': 'Just Taking effect on QR code ordering',

  'smartOrderingSettings.webPageSetting.applyPreferences': 'Apply Preferences',
  'smartOrderingSettings.webPageSetting.applyPreferences.des':
    'To ensure order consistency, QR ordering and Tablet ordering will be linked',

  'smartOrderingSettings.tabs.qrcode-download': 'QRCode Download',
  'smartOrderingSettings.tabs.qrcode-tablet-ordering-setting': 'QRCode / Tablet Ordering Setting',
  'smartOrderingSettings.tabs.tablet-ordering-setting': 'Tablet Ordering Setting',
  'smartOrderingSettings.tabs.kiosk-ordering-setting': 'Kiosk Ordering Setting',
  // 'smartOrderingSettings.tabs.qrcode-ordering-setting': 'QRCode Ordering & Tablet Ordering Setting',
  'smartOrderingSettings.tabs.qrcode-tablet-ordering-setting.qrcode': 'QRCode Ordering',
  'smartOrderingSettings.tabs.qrcode-tablet-ordering-setting.tablet': 'Tablet Ordering Setting',

  'smartOrderingSettings.qrcode-tablet-ordering-setting.qrcode.incomingOrders.title': 'Incoming Orders Setting',
  'smartOrderingSettings.qrcode-tablet-ordering-setting.tablet.incomingOrders.title': 'Incoming Orders Setting',
  'smartOrderingSettings.kiosk-ordering-setting.incomingOrders.title': 'Incoming Orders Setting',

  'smartOrderingSettings.tablet.dineInEnable.description': 'Disable Dine In orders if toggle is off',
  'smartOrderingSettings.kiosk.webPageSetting.title': 'Presentation Setting',
  'smartOrderingSettings.qrcode.webPageSetting.title': 'Presentation Setting',
  'smartOrderingSettings.tablet.webPageSetting.title': 'Presentation Setting',

  'smartOrderingSettings.webPageSetting.payInStore': 'Pay At The Counter',
  'smartOrderingSettings.webPageSetting.payInStore.description':
    'If enable this option, please make sure the printer is configured as "On Successful Transaction"',

  'smartOrderingSettings.tablet.dineIn.error':
    'The online payment when order is placed has been set up, dine-in is not supported for Tablet ordering',
  'smartOrderingSettings.qrcode.dineIn.paymentImmediately.error':
    'The online payment when order is placed is not supported when tablet dine-in is valid',
  'smartOrderingSettings.tabletOrderingSetting.modalAdd': '+Add limit items/categorys',
  'smartOrderingSettings.tabletOrderingSetting.deleteLimitRow': 'delete',
  'smartOrderingSettings.tabletOrderingSetting.editLimitRow': 'edit',
  'smartOrderingSettings.tabletOrderingSetting.limitItemTitle': 'Limit items',
  'smartOrderingSettings.tabletOrderingSetting.addModalTitle': 'select items',
  'smartOrderingSettings.tabletOrderingSetting.setModalTitle': 'set the max purchase quantity',
  'smartOrderingSettings.tabletOrderingSetting.modalCancel': 'cancel',
  'smartOrderingSettings.tabletOrderingSetting.modalBack': 'back',
  'smartOrderingSettings.tabletOrderingSetting.modalNext': 'next',
  'smartOrderingSettings.tabletOrderingSetting.modalSave': 'confirm',
  'smartOrderingSettings.tabletOrderingSetting.customQuantity': 'Custom quantity',
  'smartOrderingSettings.tabletOrderingSetting.itemPer': 'item/per guest',
  'smartOrderingSettings.tabletOrderingSetting.perGuest': 'per guest',
  'smartOrderingSettings.tabletOrderingSetting.chooseMeals': 'please choose meals',
  'smartOrderingSettings.tabletOrderingSetting.qrcodeSetting': 'QR Code Download',
  'smartOrderingSettings.tabletOrderingSetting.qrSetting': 'QR Setting',
  'smartOrderingSettings.tabletOrderingSetting.tabletSetting': 'Tablet Setting',
  'smartOrderingSettings.tabletOrderingSetting.kioskSetting': 'Kiosk Setting',
  'smartOrderingSettings.tabletOrderingSetting.saveChangeBtn': 'Save Change',
  'smartOrderingSettings.tabletOrderingSetting.discardBtn': 'Discard',
  'smartOrderingSettings.tabletOrderingSetting.limitTableProductTitle': 'item(Smart Ordering Contract)',
  'smartOrderingSettings.tabletOrderingSetting.limitTablePerGuestTitle': 'Limit/Guest',
  'smartOrderingSettings.tabletOrderingSetting.limitTableOperationTitle': 'Action',
  'smartOrderingSettings.update.success': 'Updated SmartOrding Settings success',
  'smartOrderingSettings.update.fail': 'Updated SmartOrding Settings failed',
  'smartOrderingSettings.tabletOrderingSetting.displayCountdownReminder.validator':
    'The countdown reminder time must be less than or equal to the dining time!',
  'smartOrderingSettings.tabletOrderingSetting.diningTimeLimit.validator':
    'The dining time must be greater than or equal to the countdown reminder time!',
  'smartOrderingSettings.tabletOrderingSetting.bigTitle': 'Smart Ordering',
  'smartOrderingSettings.tabletOrderingSetting.limit.type.byCategory': 'Limit N per category',
  'smartOrderingSettings.tabletOrderingSetting.limit.type.byCategory.table': 'Limit N per category',
  'smartOrderingSettings.tabletOrderingSetting.limit.type.byCategory.desc':
    'Customers are limited to purchase up to N items of each selected category.',
  'smartOrderingSettings.tabletOrderingSetting.limit.type.byDish': 'Limit N per item',
  'smartOrderingSettings.tabletOrderingSetting.limit.type.byDish.table': 'Limit N per category',
  'smartOrderingSettings.tabletOrderingSetting.limit.type.byDish.desc':
    'Customers are limited to purchase up to N of each selected item.',
  'smartOrderingSettings.tabletOrderingSetting.limit.mustChooseLimitType': 'please choose limit type',
  'smartOrderingSettings.tabletOrderingSetting.limit.mustChooseLimitQuantity': 'please choose limit quantity',
  'smartOrderingSettings.tabletOrderingSetting.limit.mustChooseLimitItem': 'please choose limit items',
  'smartOrderingSettings.tabletOrderingSetting.limit.mustChooseLimitCategory': 'please choose limit categorys',
  'smartOrderingSettings.tabletOrderingSetting.limit.selectAll': 'select all',
  'smartOrderingSettings.tabletOrderingSetting.limit.limitType': 'limit type',
  'smartOrderingSettings.tabletOrderingSetting.limit.limitItemOrCategory': 'limit items/categories',
  'smartOrderingSettings.tabletOrderingSetting.limit.limitQuantity': 'limit quantity',
  'smartOrderingSettings.tabletOrderingSetting.limit.limitItemsPerRound':
    'Limit items per guest are not restricted when submitting the shopping cart.',
  'smartOrderingSettings.tabletOrderingSetting.limit.limitItemsPerRound.tooltip':
    'Example: For item A, limit 1 per guest. Set max items per round as 10. Then item A will not be counted within 10.',
  'smartOrderingSettings.tabletOrderingSetting.limit.tableNoData': 'No limited purchase products selected yet',
  'smartOrderingSettings.tabletOrderingSetting.notSupportTablePro':
    'You have not purchased the <span>Tablet Pro</span> service, please contact Chowbus sales representative to order the service.',
  'smartOrderingSettings.tabletOrderingSetting.showItemCategory': 'Show item categories',
  'smartOrderingSettings.tabletOrderingSetting.showItemCategoryTips':
    'After turning it on, the pages will be grouped by item categories.',
  'smartOrderingSettings.tabletOrderingSetting.showItemCategoryGuide': 'Example of the effect on the Tablet:',
};
