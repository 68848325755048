export default {
  'accountSettings.title': 'Account',

  'accountSettings.field.restaurantName': 'Restaurant Name',
  'accountSettings.field.ownerName': 'Owner Name',
  'accountSettings.field.restaurantAddress': 'Restaurant Address',
  'accountSettings.field.restaurantWifi': 'Network Name:',
  'accountSettings.field.restaurantWifiPassword': 'Password',
  'accountSettings.field.email': 'Registered Email',
  'accountSettings.field.password': 'Login Password',
  'accountSettings.field.settlementTime': 'Settlement Time',
  'accountSettings.field.bankAccountLink': 'Bank Account',
  'accountSettings.field.payoutAccountLink': 'Payout Account Link',
  'accountSettings.field.payoutAccountLink.tooltip':
    'Please use this link to onboard a Stripe payout account. We will payout your card processing volume to the bank account linked to this account.',
  'accountSettings.field.adyenOnboard': 'Onboard Link',
  'accountSettings.field.adyenOnboard.tooltip': 'Please use this link to onboard a Adyen account.',
  'accountSettings.field.invoicingAccountLink': 'Invoicing Account Link',
  'accountSettings.field.invoicingAccountLink.tooltip':
    'You can setup a Stripe invoicing account. We will use your invoicing account to deduct transaction fees. You may link the same or different bank account from the one you use for payout.',
  'accountSettings.account.blank': 'Click Here to Set Account',
  'accountSettings.editWifiInfo': 'Edit Wi-Fi Info',
  'accountSettings.field.closeoutReportCutoffTime': 'Closeout Report Cutoff Time',
  'accountSettings.field.closeoutReportCutoffTime.tooltip':
    'All reports are calculated based on a 24 hours time period. For example, if the cutoff time is set at 2:00:00 am, 1/1/2023’s reports would include data from 1/1/2023 1:59:59 am to 1/2/2023 2:00:00 am.',
  'accountSettings.time': 'Time',
  'accountSettings.startOfTheWeek': 'Start of the week',
  'accountSettings.bindPayoutAccount': 'Bind Payout Account',
  'accountSettings.bindInvoiceAccount': 'Bind Invoice Account',
  'accountSettings.field.adyen.payoutAccountLink': 'Payout Account',
  'accountSettings.field.adyen.payoutAccountLink.tooltip':
    'We will payout your card processing volume to this bank account.',
  'accountSettings.field.adyen.invoicingAccountLink': 'Invoicing Account',
  'accountSettings.field.adyen.invoicingAccountLink.tooltip':
    'We will use your invoicing account to deduct transaction fees. You may link the same or different bank account from the one you use for payout.',
  'accountSettings.field.adyen.bind.failed': 'Bind account failed',
  'accountSettings.field.adyen.bindButton.change': 'Change',
  'accountSettings.field.adyen.bindButton.bind': 'Bind',
  'accountSettings.information.adyen.missing':
    'Some onboarding information is missing, please contact your administrator.',
  'accountSettings.information.adyen.server_error': 'A service error occured, please contact your administrator.',
  'accountSettings.serviceTime': 'Service Time',
  'accountSettings.set.serviceTime':
    'Set your service time <a>Here</a> to easily track your revenue by service periods in your report!',
  'accountSettings.set.serviceTime.tip':
    'You have modified the Cutoff time or Start of the week. Please recheck your service time and save the changes.',
  'accountSettings.set.serviceTime.confirm': 'Go Check',
  'accountSettings.set.serviceTime.cancel': 'Not Now',

  'accountSettings.field.newPaymentCutoff': 'The cut-off time cannot be later than {lastTime}',
  'accountSettings.field.newPaymentCutoff.des':
    'Your cut-off time be at least one hour earlier than our Batch Settlement Time to allow you time to add or adjust tips on orders.',
  'accountSettings.field.batchSettlementTime': 'Batch Settlement Time: {time}',
  'accountSettings.field.batchSettlementTime.des':
    'Batch Settlement Time refers to the time at the end of each business day when all transactions are grouped together and sent to the payment processor to be finalized, ensuring accurate payment processing.',
};
